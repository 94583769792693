import { gql } from '@apollo/client';
import imageFragment from '../imageFragment';

export default gql`
  fragment notificationFragment on Notification {
    id
    body
    type
    link
    targetId
    illustration {
      ...imageFragment
    }
    creationDate
    read
  }

  ${imageFragment}
`;
