import React from 'react';
import {
  IonContent,
  IonButton,
  IonGrid,
  IonCol,
  IonRow,
  IonCard,
  IonIcon,
  IonHeader,
} from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { useSwiper } from 'swiper/react';
import { connect } from 'react-redux';
import { filterCircleOutline } from 'ionicons/icons';

import Categories from '../../../components/category/Categories';
import { RootState } from '../../../models/store';

const CategoriesSlide: React.FC<CategoriesSlideComponent> = ({
  categories,
  selectedCategories,
  setSelectedCategories,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation('translation', { keyPrefix: 'introduction.categories' });
  const swiper = useSwiper();

  const currentLanguage = language.split('-')[0];

  return (
    <>
      <IonGrid className="full-height">
        <IonRow className="header-row ion-align-items-center">
          <IonCol>
            <IonIcon icon={filterCircleOutline} color="primary" />
            <h1 className="b">{t('Select the causes you care about')}</h1>
          </IonCol>
        </IonRow>
        <IonRow className="main-row ion-justify-content-center">
          <IonCol
            sizeXs="12"
            sizeLg="6"
            className="categories-container ion-no-padding"
          >
            <IonGrid className="cards-list">
              <IonRow>
                {(
                  (categories?.list || []).filter(
                    ({ children }: Category) => children?.length
                  ) || []
                ).map((category: Category) => {
                  const isSelected = selectedCategories.filter(
                    ({ id }) => id === category.id
                  ).length;

                  return (
                    <IonCol
                      key={`category-${category.id}`}
                      sizeXs="6"
                      sizeSm="4"
                      sizeMd="4"
                      sizeLg="4"
                      onClick={() =>
                        setSelectedCategories(
                          isSelected
                            ? selectedCategories.filter(
                                ({ id }) => id !== category.id
                              )
                            : [...selectedCategories, category]
                        )
                      }
                    >
                      <IonCard
                        className={`ion-no-margin ion-no-padding ion-margin-horizontal-half ion-margin-bottom pointer ${
                          isSelected ? 'selected' : ''
                        }`}
                      >
                        <img src={`${category?.illustration?.md}`} alt="" />
                        <div className="background">
                          <div className="title ion-padding-half">
                            {category?.[`name_${currentLanguage}`] ??
                              category?.name}
                          </div>
                        </div>
                      </IonCard>
                    </IonCol>
                  );
                })}
              </IonRow>
            </IonGrid>
          </IonCol>
        </IonRow>
        <IonRow className="actions-row">
          <IonCol>
            <IonButton
              shape="round"
              size="large"
              className="ion-margin"
              onClick={() => {
                swiper.slideNext();
              }}
            >
              {t('I have selected my categories')}
            </IonButton>
          </IonCol>
        </IonRow>
      </IonGrid>
    </>
  );
};

const mapState = (state: RootState) => ({
  categories: state.categories.categories,
});

export default connect(mapState)(CategoriesSlide);
