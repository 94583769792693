import { gql } from '@apollo/client';
import categoryFragment from '../category/categoryFragment';
import associationFragment from '../association/associationFragment';
import postFragment from '../post/postFragment';
import connectionFragment from '../community/connectionFragment';

export const searchAnything: any = gql`
  query searchAnything($type: String!, $searchValue: String!) {
    searchAnything(type: $type, searchValue: $searchValue) {
      categories {
        ...categoryFragment
      }
      associations {
        ...associationFragment
      }
      posts {
        ...postFragment
      }
      connections {
        ...connectionFragment
      }
    }
  }

  ${categoryFragment}
  ${associationFragment}
  ${postFragment}
  ${connectionFragment}
`;
