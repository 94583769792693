import React, { useEffect, useState } from 'react';
import {
  IonPage,
  IonicSwiper,
  IonContent,
  IonHeader,
  IonToolbar,
  isPlatform,
  useIonAlert,
} from '@ionic/react';
import { Geolocation } from '@capacitor/geolocation';
import * as Sentry from '@sentry/react';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Keyboard } from 'swiper';
import { useNotification } from 'notification';

import { cleanupCoordinates } from '../../utils/cleanupCoordinates';
import { logEvent, setUserProperty } from '../../services/firebaseApp';

import CategoriesSlide from './Slides/CategoriesSlide';
import LocationSlide from './Slides/LocationSlide';
import NotificationSlide from './Slides/NotificationSlide';
import ThanksSlide from './Slides/ThanksSlide';
import WelcomeSlide from './Slides/WelcomeSlide';

import './Introduction.scss';

import 'swiper/css';
import 'swiper/css/pagination';
import '@ionic/react/css/ionic-swiper.css';
import { useTranslation } from 'react-i18next';

SwiperCore.use([IonicSwiper, Navigation, Pagination]);

const Introduction: React.FC<IntroductionComponent> = ({ setFirstTime }) => {
  const { acceptNotification } = useNotification()!;
  const { t } = useTranslation('translation', { keyPrefix: 'introduction' });
  const [presentHelper] = useIonAlert();
  const [selectedCategories, setSelectedCategories] = useState<Category[]>([]);

  const getCategoryIds = () => selectedCategories.map(({ id }) => id);
  const getCategoryNamesAsString = () =>
    selectedCategories
      .map(({ name }) =>
        name
          .toLowerCase()
          .replaceAll(/[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~ ]/g, '_')
      )
      .join(',');
  const getCategoryNamesAsObject = () =>
    selectedCategories.map(({ name }) => ({
      name: name
        .toLowerCase()
        .replaceAll(/[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~ ]/g, '_'),
    }));

  const detectChange = async (swiper: any) => {
    try {
      switch (swiper.activeIndex) {
        case 1:
          break;
        case 2:
          if (!selectedCategories.length) {
            presentHelper({
              message: t('Please select at least one cause'),
            });
            swiper.slidePrev();
            return;
          }
          localStorage.setItem(
            'favorites_categories',
            JSON.stringify(getCategoryIds())
          );
          setUserProperty('favorites_categories', getCategoryNamesAsString());
          logEvent('favorites_categories', {
            content_type: 'onboarding',
            content_id: 'undefined',
            items: getCategoryNamesAsObject(),
          });
          break;
        case 3:
          const coordinates = await Geolocation.getCurrentPosition();
          if (!coordinates) {
            return;
          }
          const coordinatesObject = cleanupCoordinates(coordinates);
          localStorage.setItem(
            'coordinates',
            JSON.stringify(coordinatesObject)
          );
          setUserProperty('has_coordinates', 'true');
          break;
        case 4:
          await acceptNotification();
          break;
      }
    } catch (e: any) {
      Sentry.captureException(e);
    }
  };

  return (
    <IonPage className="introduction">
      <IonHeader />
      <IonContent fullscreen>
        <Swiper
          modules={[Keyboard]}
          onSlideChange={detectChange}
          keyboard={{ enabled: false }}
          pagination={{ type: 'progressbar', clickable: false }}
        >
          <SwiperSlide className="welcome-slide">
            <WelcomeSlide />
          </SwiperSlide>
          <SwiperSlide className="categories-slide">
            <CategoriesSlide
              selectedCategories={selectedCategories}
              setSelectedCategories={setSelectedCategories}
            />
          </SwiperSlide>
          <SwiperSlide className="location-slide">
            <LocationSlide />
          </SwiperSlide>
          <SwiperSlide className="notification-slide">
            <NotificationSlide />
          </SwiperSlide>
          <SwiperSlide className="thanks-slide">
            <ThanksSlide setFirstTime={setFirstTime} />
          </SwiperSlide>
        </Swiper>
      </IonContent>
    </IonPage>
  );
};

export default Introduction;
