import translation from './translation.json';

import introduction from './introduction.json';
import account from './account.json';
import associations from './associations.json';
import association from './association.json';
import associationForm from './associationForm.json';
import categories from './categories.json';
import countries from './countries.json';
import posts from './posts.json';
import post from './post.json';
import postForm from './postForm.json';
import community from './community.json';
import connection from './connection.json';
import following from './following.json';
import settings from './settings.json';
import donations from './donations.json';
import login from './login.json';
import register from './register.json';
import resetPassword from './resetPassword.json';
import searchResults from './searchResults.json';
import notifications from './notifications.json';
import search from './search.json';

import confirmDonation from './confirmDonation.json';
import donateForm from './donateForm.json';

import tabs from './tabs.json';
import toast from './toast.json';

const en = {
  translation: {
    ...translation,
    introduction,
    associations,
    association,
    associationForm,
    categories,
    countries,
    posts,
    post,
    postForm,
    community,
    connection,
    following,
    notifications,
    settings: {
      ...settings,
      account,
    },
    donations,
    login,
    register,
    resetPassword,
    searchResults,
    confirmDonation,
    donateForm,
    tabs,
    toast,
    search,
  },
};

export default en;
