import { gql } from '@apollo/client';

export const addUserPictureBase64: any = gql`
  mutation addUserPictureBase64($file: String!) {
    addUserPictureBase64(file: $file) {
      success
      message
      locations {
        lg
        md
        sm
      }
    }
  }
`;
