import React from 'react';
import {
  IonContent,
  IonButton,
  IonGrid,
  IonCol,
  IonRow,
  IonCard,
  IonList,
  IonItem,
} from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { useSwiper } from 'swiper/react';
import { connect } from 'react-redux';
import { RootState } from '../../../models/store';

const CountriesSlide: React.FC<CountriesSlideComponent> = ({
  countries,
  selectedCountries,
  setSelectedCountries,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation('translation', { keyPrefix: 'help.countries' });
  const swiper = useSwiper();

  const currentLanguage = language.split('-')[0];

  return (
    <IonContent>
      <IonGrid>
        <IonRow className="content-top">
          <IonCol sizeXs="12" offsetXs="0" sizeMd="6" offsetMd="0">
            <h1>{t('Countries')}</h1>
            <p>
              {t('Select the causes you care about from the list of countries')}
            </p>
            <IonButton
              shape="round"
              className="ion-margin ion-hide-md-down"
              onClick={() => {
                swiper.slideNext();
              }}
            >
              {t('Nex step')}
            </IonButton>
          </IonCol>
          <IonCol
            sizeXs="12"
            offsetXs="0"
            sizeMd="6"
            offsetMd="0"
            className="countries-container ion-no-padding"
          >
            <IonList>
              {(countries?.list || []).map((country: Country) => {
                const isSelected = selectedCountries.filter(
                  ({ code }) => code === country.code
                ).length;

                return (
                  <IonItem
                    key={`country-${country.code}`}
                    onClick={() =>
                      setSelectedCountries(
                        isSelected
                          ? selectedCountries.filter(
                              ({ code }) => code !== country.code
                            )
                          : [...selectedCountries, country]
                      )
                    }
                    className={`${isSelected ? 'selected' : ''}`}
                  >
                    {country?.name}
                  </IonItem>
                );
              })}
            </IonList>
            <IonButton
              shape="round"
              className="ion-margin ion-hide-md-up"
              onClick={() => {
                swiper.slideNext();
              }}
            >
              {t('Nex step')}
            </IonButton>
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonContent>
  );
};

const mapState = (state: RootState) => ({
  countries: state.countries.countries,
});

export default connect(mapState)(CountriesSlide);
