import { gql } from '@apollo/client';
import associationFragment from './associationFragment';

export const getAssociation: any = gql`
  query getAssociation($code: String!) {
    getAssociation(code: $code) {
      ...associationFragment
    }
  }

  ${associationFragment}
`;
