import React from 'react';
import { connect } from 'react-redux';
import {
  IonContent,
  IonPage,
  IonGrid,
  IonRow,
  IonCol,
  IonList,
  IonItem,
} from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import {
  SignInWithAppleButton,
  SignInWithEmailButton,
  SignInWithGoogleButton,
  SignInWithFacebookButton,
} from 'authentication';

import { Dispatch } from '../../models/store';
import { logEvent, setUserId } from '../../services/firebaseApp';
import {
  createUser,
  editUserFavoriteCategories,
  addUserLocation,
} from '../../services/userService';
import { loginLink } from '../../constants/routes';

const Register: React.FC<RegisterComponent> = ({
  setStateUser,
  setStateUserInfo,
  setStateToast,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'register',
  });
  const history = useHistory();

  const gotoLogin = () => history.replace(loginLink);

  const onLoggedIn = async (firebase: any) => {
    const { user: info } = await createUser({
      name: firebase.displayName,
      email: firebase.email,
    });
    if (!info) {
      setStateToast({
        isOpen: true,
        duration: 0,
        type: 'warning',
        message: 'error',
      });
      return;
    }
    await setStateUser({ firebase, status: '' });
    await setStateUserInfo(info);
    setUserId(info?.id);
    logEvent('sign_up', {
      content_type: 'user',
      content_id: info.id,
    });
    const categoryIds = localStorage.getItem('favorites_categories');
    if (categoryIds) {
      await editUserFavoriteCategories({
        categoryIds: JSON.parse(categoryIds),
      });
    }
    const coordinates = localStorage.getItem('coordinates');
    if (coordinates) {
      await addUserLocation({
        coordinates: JSON.parse(coordinates),
      });
    }
  };

  const beforeLogin = async () => await setStateUser({ status: 'creating' });

  const onError = (message: string) => {
    setStateToast({
      isOpen: true,
      duration: 0,
      type: 'warning',
      message,
    });
  };

  return (
    <IonPage className="register">
      <IonContent fullscreen>
        <IonGrid className="full-height ion-justify-content-center ion-text-center">
          <IonRow className="row-grow ion-justify-content-center ion-align-items-end">
            <IonCol size="auto" className="ion-no-padding">
              <img src="/charitable.png" alt="" style={{ width: '100px' }} />
              <h1 className="main-title">Charitable</h1>
            </IonCol>
          </IonRow>
          <IonRow className="ion-justify-content-center ion-align-items-end">
            <IonCol size="auto">
              <h2 className="ion-margin-vertical b">{t('Sign up for free')}</h2>
            </IonCol>
          </IonRow>
          <IonRow className="ion-justify-content-center">
            <IonCol sizeSm="auto" sizeXs="12">
              <IonList lines="none" className="no-background">
                <IonItem type="button">
                  <SignInWithAppleButton
                    isLogin={false}
                    beforeLogin={beforeLogin}
                    onLoggedIn={onLoggedIn}
                    onError={onError}
                    expand="full"
                    fill="outline"
                    buttonText={t('Continue with Apple')}
                    className="ion-margin-bottom-half"
                  />
                </IonItem>
                <IonItem>
                  <SignInWithGoogleButton
                    isLogin={false}
                    beforeLogin={beforeLogin}
                    onLoggedIn={onLoggedIn}
                    onError={onError}
                    expand="full"
                    fill="outline"
                    buttonText={t('Continue with Google')}
                    className="ion-margin-bottom-half"
                  />
                </IonItem>
                <IonItem>
                  <SignInWithFacebookButton
                    isLogin={false}
                    beforeLogin={beforeLogin}
                    onLoggedIn={onLoggedIn}
                    onError={onError}
                    expand="full"
                    fill="outline"
                    buttonText={t('Continue with Facebook')}
                    className="ion-margin-bottom-half"
                  />
                </IonItem>
                <IonItem>
                  <SignInWithEmailButton
                    isLogin={false}
                    beforeLogin={beforeLogin}
                    onLoggedIn={onLoggedIn}
                    onError={onError}
                    expand="full"
                    fill="outline"
                    buttonText={t('Continue with your email')}
                  />
                </IonItem>
              </IonList>
            </IonCol>
          </IonRow>
          <IonRow className="ion-padding-vertical">
            <IonCol>
              <small>
                <span className="pointer u" onClick={gotoLogin}>
                  {t('Sign in')}
                </span>
              </small>
            </IonCol>
          </IonRow>
          <IonRow className="ion-padding-bottom">
            <IonCol>
              <span></span>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

const mapDispatch = (dispatch: Dispatch) => ({
  setStateUser: dispatch.user.setStateUserAsync,
  setStateUserInfo: dispatch.user.setStateUserInfo,
  setStateToast: dispatch.toast.setStateToast,
});

export default connect(null, mapDispatch)(Register);
