import { ApolloClient, ApolloLink, InMemoryCache, split } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createUploadLink } from 'apollo-upload-client';
import { SentryLink } from 'apollo-link-sentry';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import { setContext } from '@apollo/client/link/context';
import { createClient } from 'graphql-ws';
import * as Sentry from '@sentry/react';

const API_URL = process.env.REACT_APP_API;

const uploadLink = createUploadLink({
  uri: `${API_URL}/graphql`,
});

const wsLink = new GraphQLWsLink(
  createClient({
    url: () => {
      return (
        process.env.REACT_APP_WS || 'wss://api.charitable.be/subscriptions'
      );
    },
    lazy: true,
    keepAlive: 10000,
    connectionAckWaitTimeout: 10000,
    connectionParams: () => ({
      authorization: localStorage.getItem('token')
        ? `Bearer ${localStorage.getItem('token')}`
        : '',
      'X-FRONTEND-VERSION': process.env.REACT_APP_VERSION,
    }),
  })
);

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  uploadLink
);

const authLink = setContext(async (_: any, { headers }: any) => {
  return {
    headers: {
      ...headers,
      authorization: localStorage.getItem('token')
        ? `Bearer ${localStorage.getItem('token')}`
        : '',
      'X-FRONTEND-VERSION': process.env.REACT_APP_VERSION,
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) =>
      Sentry.captureMessage(
        `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
          locations
        )}, Path: ${JSON.stringify(path)}`
      )
    );
  }

  if (networkError) {
    Sentry.captureMessage(`[Network error]: ${networkError}`);
  }
});

const retryLink = new RetryLink({
  delay: {
    initial: 300,
    max: 20000,
    jitter: true,
  },
  attempts: {
    max: 3,
    retryIf: (error) => !!error,
  },
});

const client = new ApolloClient({
  link: ApolloLink.from([
    new SentryLink(),
    errorLink,
    retryLink,
    authLink.concat(splitLink),
  ]),
  //ssrMode: typeof window !== 'undefined',
  cache: new InMemoryCache(),
});

export default client;
