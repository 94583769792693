import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { CapacitorStripeProvider } from '@capacitor-community/stripe/dist/esm/react/provider';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { ApolloProvider } from '@apollo/client';

import client from './apollo/client';
import { store } from './models/store';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

import { AuthProvider as AuthentificationProvider } from 'authentication';
import { NotificationProvider } from 'notification';
import ErrorBoundary from './components/ErrorBoundary';

import { I18nextProvider } from 'react-i18next';
import i18n from './language/i18nextConf';

const rootNode = document.getElementById('root');

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY,
  environment: process.env.REACT_APP_ENV,
  release: process.env.REACT_APP_VERSION,
  integrations: [new BrowserTracing()],
  enabled: process.env.REACT_APP_ENV !== 'local',
  beforeSend(event, hint) {
    if (process.env.REACT_APP_ENV === 'qa') {
      Sentry.showReportDialog({ eventId: event.event_id });
    }
    if (process.env.REACT_APP_ENV === 'local') {
      return null;
    }
    return event;
  },

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <AuthentificationProvider
        apiKey={process.env.REACT_APP_API_KEY || ''}
        authDomain={process.env.REACT_APP_AUTH_DOMAIN || ''}
        projectId={process.env.REACT_APP_PROJECT_ID || ''}
        appId={process.env.REACT_APP_API_ID || ''}
        measurementId={process.env.REACT_APP_MEASUREMENT_ID || ''}
      >
        <NotificationProvider
          apiKey={process.env.REACT_APP_API_KEY}
          vapidKey={process.env.REACT_APP_VAPID_KEY}
          authDomain={process.env.REACT_APP_AUTH_DOMAIN}
          projectId={process.env.REACT_APP_PROJECT_ID}
          storageBucket={process.env.REACT_APP_STORAGE_BUCKET}
          appId={process.env.REACT_APP_API_ID}
          measurementId={process.env.REACT_APP_MEASUREMENT_ID}
          messagingSenderId={process.env.REACT_APP_MESSAGING_SENDER_ID}
        >
          {/* <CapacitorStripeProvider
            publishableKey={process.env.REACT_APP_STRIPE_PK || ''}
            fallback={<p className="ion-text-center">Loading...</p>}
          > */}
          <I18nextProvider i18n={i18n}>
            <Provider store={store}>
              <ApolloProvider client={client}>
                <App />
              </ApolloProvider>
            </Provider>
          </I18nextProvider>
          {/* </CapacitorStripeProvider> */}
        </NotificationProvider>
      </AuthentificationProvider>
    </ErrorBoundary>
  </React.StrictMode>,
  rootNode
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
