import React from 'react';
import { IonButton, IonIcon } from '@ionic/react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { logEvent } from '../services/firebaseApp';
import { RootState, Dispatch } from '../models/store';
import Confirm from './Confirm';
import { FaHeart, FaRegHeart } from 'react-icons/fa';

const Like: React.FC<LikeComponent> = ({
  post,
  type,
  color,
  expand,
  size,
  fill,
  shape,
  className,
  user,
  toggleStateLike,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'like',
  });

  const like = (e: MouseEvent | any) => {
    e.preventDefault();
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();

    toggleStateLike(post);

    if (isInLikes()) {
      logEvent('like', {
        content_type: 'remove',
        content_id: post.id,
      });
    } else {
      logEvent('like', {
        content_type: 'add',
        content_id: post.id,
      });
    }
  };

  const isInLikes = () =>
    (user?.likes || []).map(({ id }: Post) => id).includes(post.id);

  return (
    <Confirm
      confirm={(e: any) => isInLikes() && like(e)}
      confirmText={t('Unlike')}
    >
      <IonButton
        className={`type-${type} ${className}`}
        onClick={(e) => !isInLikes() && like(e)}
        color={color || 'primary'}
        size={size || 'default'}
        fill={fill || 'default'}
        shape={shape || undefined}
        expand={expand || undefined}
      >
        {isInLikes() ? (
          <>
            <FaHeart className={type !== 'icon' ? 'ion-margin-end' : ''} />
            {type === 'icon' ? null : t('Unlike')}
          </>
        ) : (
          <>
            <FaRegHeart className={type !== 'icon' ? 'ion-margin-end' : ''} />
            {type === 'icon' ? null : t('Like')}
          </>
        )}
      </IonButton>
    </Confirm>
  );
};

const mapState = (state: RootState) => ({
  user: state.user.user,
});

const mapDispatch = (dispatch: Dispatch) => ({
  toggleStateLike: dispatch.user.toggleStateLike,
});

export default connect(mapState, mapDispatch)(Like);
