import { gql } from '@apollo/client';

export const getUserPayments: any = gql`
  query getUserPayments {
    getUserPayments {
      id
      amount
      currency
      associationId
      creationDate
    }
  }
`;
