import { gql } from '@apollo/client';

export const addUserDevice: any = gql`
  mutation addUserDevice($input: DeviceInput!) {
    addUserDevice(input: $input) {
      id
      deviceId
      deviceToken
      manufacturer
      model
      operatingSystem
      osVersion
      platform
      creationDate
    }
  }
`;
