import React, { useState } from 'react';
import { IonButton } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import draftToHtml from 'draftjs-to-html';

function isJson(str: string) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

const ReadMoreHTML: React.FC<ReadMoreComponent> = ({ className, children }) => {
  const text = children;
  const [isReadMore, setIsReadMore] = useState(true);
  const { t } = useTranslation('translation', {
    keyPrefix: 'readMore',
  });

  const convertRawDataToHTML = (description: string) => {
    return !isJson(description)
      ? description
      : `${draftToHtml(JSON.parse(description)).slice(
          0,
          isReadMore ? 500 : undefined
        )}${isReadMore ? '...' : ''}`;
  };

  const toggleReadMore = () => setIsReadMore(!isReadMore);

  return (
    <div className={`ion-text-break ${className}`}>
      <div
        className="html"
        dangerouslySetInnerHTML={{
          __html: convertRawDataToHTML(text),
        }}
      />
      {text?.length <= 550 ? null : (
        <p>
          <small>
            <a onClick={toggleReadMore}>
              {isReadMore ? t('read more') : t('show less')}
            </a>
          </small>
        </p>
      )}
    </div>
  );
};

export default ReadMoreHTML;
