import React, { useEffect, useState } from 'react';
import { IonAvatar, IonImg, IonSpinner } from '@ionic/react';
import { FiUser } from 'react-icons/fi';
import { connect } from 'react-redux';
import { useAuth } from 'authentication';

import { RootState } from '../models/store';

const ProfilePicture: React.FC<ProfilePictureComponent> = ({
  user,
  slot,
  isNewPicture = false,
  onClick,
  isLoading = false,
  className,
}) => {
  const [pictureUrl, setPictureUrl] = useState<string | null>(null);
  const { user: userFirebase } = useAuth()!;

  useEffect(() => {
    if (!userFirebase || !user?.info) {
      setPictureUrl(null);
      return;
    }
    const provider = (user?.firebase?.providerData || []).find(
      ({ photoURL }: { photoURL: string }) => photoURL
    );
    setPictureUrl(user?.info?.illustration?.sm ?? provider?.photoURL);
  }, [user, userFirebase]);
  const avatarProps = {
    slot,
    className: `${className} ${pictureUrl ? 'with-picture' : ''}`,
  };

  return (
    <IonAvatar
      {...JSON.parse(JSON.stringify(avatarProps))}
      onClick={() => onClick && onClick()}
    >
      {isLoading && (
        <div className="avatar-spinner">
          <IonSpinner color="primary" />
        </div>
      )}
      {pictureUrl ? (
        <IonImg
          src={`${pictureUrl}${isNewPicture ? `?${Date.now()}` : ''}`}
          alt={user?.info?.name.slice(0, 1).toUpperCase()}
        />
      ) : (
        <FiUser size="2em" />
      )}
    </IonAvatar>
  );
};

const mapState = (state: RootState) => ({
  user: state.user.user,
});

export default connect(mapState)(ProfilePicture);
