import gql from 'graphql-tag';
import associationFragment from './associationFragment';

export const editAssociation: any = gql`
  mutation editAssociation($id: ID!, $association: AssociationInput!) {
    editAssociation(id: $id, association: $association) {
      ...associationFragment
    }
  }

  ${associationFragment}
`;
