import { Position } from '@capacitor/geolocation';
import * as Sentry from '@sentry/react';

import { removeTypenames } from '../utils/graphql';
import { cleanupCoordinates } from '../utils/cleanupCoordinates';

import client from '../apollo/client';

import { search } from '../graphql/association/search';
import { getAssociation } from '../graphql/association/getAssociation';
import { getAssociations } from '../graphql/association/getAssociations';
import { addAssociation as addAssociationQuery } from '../graphql/association/addAssociation';
import { addAssociationPicture as addAssociationPictureQuery } from '../graphql/association/addAssociationPicture';
import { addAssociationPictureBase64 as addAssociationPictureBase64Query } from '../graphql/association/addAssociationPictureBase64';
import { editAssociation as editAssociationQuery } from '../graphql/association/editAssociation';
import { deleteAssociation as deleteAssociationQuery } from '../graphql/association/deleteAssociation';
import { publishAssociation as publishAssociationQuery } from '../graphql/association/publishAssociation';
import { starAssociation as starAssociationQuery } from '../graphql/association/starAssociation';

export async function searchAssociations(variables: {
  searchValue: string;
  categoryCode: string;
  coordinates?: Position | null;
}): Promise<{ associations: Association[] }> {
  try {
    const coordinatesObject =
      variables.coordinates && cleanupCoordinates(variables.coordinates);
    const {
      data: { search: associations },
    } = await client.query({
      query: search,
      variables: { ...variables, coordinates: coordinatesObject },
      fetchPolicy: 'no-cache',
    });

    return { associations: removeTypenames(associations) };
  } catch (e: any) {
    Sentry.captureException(e);
    throw new Error();
  }
}

/**
 * @deprecated Use: searchAssociations
 */
export async function getAllAssociations(): Promise<{
  associations: Association[];
}> {
  try {
    const {
      data: { getAssociations: associations },
    } = await client.query({
      query: getAssociations,
      fetchPolicy: 'no-cache',
    });

    return { associations: removeTypenames(associations) };
  } catch (e: any) {
    Sentry.captureException(e);
    throw new Error();
  }
}

export async function getOneAssociation(variables: {
  code: string;
}): Promise<{ association: Association }> {
  try {
    const {
      data: { getAssociation: association },
    } = await client.query({
      query: getAssociation,
      variables,
    });

    return { association: removeTypenames(association) };
  } catch (e: any) {
    Sentry.captureException(e);
    throw new Error();
  }
}

export async function createAssociation(variables: {
  association: any;
}): Promise<{ association: Association }> {
  try {
    const {
      data: { addAssociation: association },
    } = await client.query({
      query: addAssociationQuery,
      variables,
      fetchPolicy: 'no-cache',
    });

    return { association: removeTypenames(association) };
  } catch (e) {
    Sentry.captureException(e);
    throw new Error();
  }
}

export async function updateAssociation(variables: {
  id: string;
  association: any;
}): Promise<{ association: Association }> {
  try {
    const {
      data: { editAssociation: association },
    } = await client.query({
      query: editAssociationQuery,
      variables,
      fetchPolicy: 'no-cache',
    });

    return { association: removeTypenames(association) };
  } catch (e) {
    Sentry.captureException(e);
    throw new Error();
  }
}

export async function removeAssociation(variables: { id: string }) {
  try {
    const {
      data: { deleteAssociation: association },
    } = await client.query({
      query: deleteAssociationQuery,
      variables,
      fetchPolicy: 'no-cache',
    });

    return { association: removeTypenames(association) };
  } catch (e) {
    Sentry.captureException(e);
    throw new Error();
  }
}

export async function editAssociationPictureBase64(variables: {
  id: string;
  type: 'banner' | 'poster' | 'logo' | 'illustration';
  file: string;
}): Promise<{ file: { locations: Image }; association: Association }> {
  try {
    const {
      data: {
        addAssociationPictureBase64: { file, association },
      },
    } = await client.query({
      query: addAssociationPictureBase64Query,
      variables,
      fetchPolicy: 'no-cache',
    });

    return {
      file: removeTypenames(file),
      association: removeTypenames(association),
    };
  } catch (e) {
    Sentry.captureException(e);
    throw new Error();
  }
}

/**
 * @deprecated Use: editAssociationPictureBase64
 */
export async function editAssociationPicture(variables: {
  id: string;
  type: 'banner' | 'poster' | 'logo';
  file: File;
}): Promise<{ file: { locations: Image }; association: Association }> {
  try {
    const {
      data: {
        addAssociationPicture: { file, association },
      },
    } = await client.query({
      query: addAssociationPictureQuery,
      variables,
      fetchPolicy: 'no-cache',
    });

    return {
      file: removeTypenames(file),
      association: removeTypenames(association),
    };
  } catch (e) {
    Sentry.captureException(e);
    throw new Error();
  }
}

export async function publishAssociation(variables: {
  id: string;
}): Promise<Association> {
  try {
    const {
      data: { publishAssociation: association },
    } = await client.query({
      query: publishAssociationQuery,
      variables,
      fetchPolicy: 'no-cache',
    });

    return removeTypenames(association);
  } catch (e) {
    Sentry.captureException(e);
    throw new Error();
  }
}

export async function starAssociation(variables: {
  id: string;
}): Promise<Association> {
  try {
    const {
      data: { starAssociation: association },
    } = await client.query({
      query: starAssociationQuery,
      variables,
      fetchPolicy: 'no-cache',
    });

    return removeTypenames(association);
  } catch (e) {
    Sentry.captureException(e);
    throw new Error();
  }
}
