import { initializeApp } from 'firebase/app';
import {
  getAnalytics,
  isSupported as isSupportedAnalytics,
  logEvent as logEventAnalytics,
} from 'firebase/analytics';
import { FirebaseAnalytics } from '@capacitor-firebase/analytics';

const config = {
  apiKey: process.env.REACT_APP_API_KEY || '',
  authDomain: process.env.REACT_APP_AUTH_DOMAIN || '',
  projectId: process.env.REACT_APP_PROJECT_ID || '',
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET || '',
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID || '',
  appId: process.env.REACT_APP_API_ID || '',
  measurementId: process.env.REACT_APP_MEASUREMENT_ID || '',
};
const firebaseApp = initializeApp(config);
const analytics = getAnalytics(firebaseApp);

const isProduction = () => process.env.NODE_ENV === 'production';

export const setEnabled = (enabled: boolean) => {
  return FirebaseAnalytics && isProduction()
    ? FirebaseAnalytics.setEnabled({ enabled })
    : console.log(
        'Analytics not triggered because not production: setEnabled',
        {
          enabled,
        }
      );
};

export const setUserId = (userId: string) => {
  return FirebaseAnalytics && isProduction()
    ? FirebaseAnalytics?.setUserId({
        userId,
      })
    : console.log('Analytics not triggered because not production: setUserId', {
        userId,
      });
};

export const setUserProperty = (key: string, value: string) => {
  return FirebaseAnalytics && isProduction()
    ? FirebaseAnalytics.setUserProperty({
        key,
        value,
      })
    : console.log(
        'Analytics not triggered because not production: setUserProperty',
        {
          key,
          value,
        }
      );
};

export const setScreenName = (
  screenName: string,
  screenClassOverride: string
) => {
  return FirebaseAnalytics && isProduction()
    ? FirebaseAnalytics.setCurrentScreen({
        screenName,
        screenClassOverride,
      })
    : console.log(
        'Analytics not triggered because not production: setScreenName',
        {
          screenName,
          screenClassOverride,
        }
      );
};

export const logEvent = (
  name: string,
  params: {
    content_type: string;
    content_id: string;
    items?: { name: string }[];
  }
) => {
  return FirebaseAnalytics && isProduction()
    ? FirebaseAnalytics.logEvent({
        name,
        params: {
          content_type: params.content_type,
          content_id: params.content_id,
          items: params.items,
        },
      })
    : console.log('Analytics not triggered because not production: logEvent', {
        name,
        params,
      });
};

/* @deprecated */
export const logPageView = async (data: any) => {
  return (await isSupportedAnalytics()) && isProduction()
    ? logEventAnalytics(analytics, 'screen_view', data)
    : console.log('Analytics not implemented: logEvent:screen_view', data);
};

/* @deprecated */
export const logGoalCompletion = async (name: string) => {
  return (await isSupportedAnalytics()) && isProduction()
    ? logEventAnalytics(analytics, 'goal_completion', { name })
    : console.log('Analytics not implemented: logEvent:goal_completion', {
        name,
      });
};
