import { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';

import {
  createPost,
  updatePost,
  editPostPictureBase64,
} from '../../services/postService';

import { Dispatch, RootState } from '../../models/store';
import { connect } from 'react-redux';
import {
  IonButton,
  IonButtons,
  IonCard,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonImg,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonTextarea,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { showError } from '../../utils/form';
import { postLink, postsLink } from '../../constants/routes';
import { FiArrowLeft } from 'react-icons/fi';
import CropperModal from '../../components/CropperModal';

const aspectRatios = {
  illustration: 8 / 5,
};

const PostForm: React.FC<PostFormComponent> = ({
  postForm,
  associations,
  categories,
  setStatePostForm,
  setStateToast,
}) => {
  const history = useHistory();
  const { t } = useTranslation('translation', {
    keyPrefix: 'postForm',
  });
  const [cropData, setCropData] = useState<CropData | null>(null);

  const {
    formState: { errors },
    control,
    setValue,
    handleSubmit,
  } = useForm();

  const onSubmit = async (formData: any) => {
    try {
      const {
        post: { id },
      } = postForm
        ? await updatePost({
            id: postForm.id,
            post: formData,
          })
        : await createPost({
            post: formData,
          });
      history.push(postsLink);
      setStateToast({
        isOpen: true,
        type: 'success',
        position: 'top',
        message: `Post ${!postForm ? 'added' : 'edited'} successfully`,
      });
    } catch (e) {
      console.log('create / update', e);
    }
  };

  const onFileChange = (e: any, type: 'illustration') => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setCropData({
        url: reader.result as any,
        type,
        aspectRatio: aspectRatios[type],
      });
    };
    reader.readAsDataURL(files[0]);
  };

  const onCropConfirm = async (base64Image: string, cropData: CropData) => {
    const { file, post } = await editPostPictureBase64({
      file: base64Image,
      id: postForm?.id || 'NEW',
      type: cropData.type,
    });
    setStatePostForm({
      ...post,
      [cropData.type]: file.locations,
    });
    setCropData(null);
  };

  const onCropCancel = () => setCropData(null);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={history.goBack}>
              <FiArrowLeft size="1.3em" />
            </IonButton>
          </IonButtons>
          <IonTitle>{t('Add post')}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense" translucent>
          <IonToolbar>
            <IonTitle size="large">{t('Add post')}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <div className="page-content">
          <CropperModal
            cropData={cropData}
            onCropConfirm={onCropConfirm}
            onCropCancel={onCropCancel}
          />
          <IonGrid className="ion-no-padding ion-margin-top-3x-lg-up">
            <IonRow className="full-width ion-no-padding ion-justify-content-center ion-align-items-center ion-margin-bottom">
              <IonCol size="auto">
                {!postForm?.illustration ? (
                  <div className="upload-btn-wrapper">
                    <IonButton fill="outline">{t('Upload a file')}</IonButton>
                    <input
                      type="file"
                      accept="image/png, image/gif, image/jpeg"
                      onChange={(e) => onFileChange(e, 'illustration')}
                    />
                  </div>
                ) : (
                  <IonCard>
                    <IonImg
                      src={`${postForm?.illustration?.md}?${Date.now()}`}
                    />
                  </IonCard>
                )}
              </IonCol>
            </IonRow>
            <IonRow>
              {!postForm ? null : (
                <IonCol>
                  <form className="form" onSubmit={handleSubmit(onSubmit)}>
                    <IonCard>
                      <IonList lines="inset">
                        <IonItem detail={false} className="form-item-input">
                          <IonLabel position="fixed">{t('Title')}</IonLabel>
                          <Controller
                            control={control}
                            name="title"
                            render={({ field: { onChange, value } }) => (
                              <IonInput
                                type="text"
                                value={value}
                                onIonChange={onChange}
                                className="form-item-input"
                                required
                              />
                            )}
                          />
                          {showError(errors, 'title')}
                        </IonItem>

                        <IonItem detail={false} className="form-item-input">
                          <IonLabel position="fixed">
                            {t('Association')}
                          </IonLabel>
                          <Controller
                            control={control}
                            name="associationId"
                            render={({ field: { onChange, value } }) => (
                              <IonSelect value={value} onIonChange={onChange}>
                                {(associations?.list || []).map(
                                  ({ id: name, name: label }, i) => (
                                    <IonSelectOption
                                      value={name}
                                      key={`${name}-${i}`}
                                    >
                                      {label}
                                    </IonSelectOption>
                                  )
                                )}
                              </IonSelect>
                            )}
                          />
                          {showError(errors, 'associationId')}
                        </IonItem>
                        <IonItem detail={false} className="form-item-input">
                          <IonLabel position="fixed">
                            {t('Categories')}
                          </IonLabel>
                          <Controller
                            control={control}
                            name="categoryIds"
                            render={({ field: { onChange, value } }) => (
                              <IonSelect
                                value={value}
                                multiple
                                onIonChange={onChange}
                              >
                                {(categories?.list || []).map(
                                  ({ id: name, name: label }, i) => (
                                    <IonSelectOption
                                      value={name}
                                      key={`${name}-${i}`}
                                    >
                                      {label}
                                    </IonSelectOption>
                                  )
                                )}
                              </IonSelect>
                            )}
                          />
                          {showError(errors, 'categoryIds')}
                        </IonItem>

                        <IonItem detail={false} className="form-item-input">
                          <IonLabel position="floating">
                            {t('Description')}
                          </IonLabel>
                          <Controller
                            control={control}
                            name="content"
                            render={({ field: { onChange, value } }) => (
                              <IonTextarea
                                value={value}
                                onIonChange={onChange}
                                autoGrow
                                required
                              />
                            )}
                          />
                          {showError(errors, 'content')}
                        </IonItem>
                      </IonList>
                    </IonCard>

                    <IonButton
                      type="submit"
                      expand="block"
                      className="ion-margin-vertical"
                    >
                      {t('Confirm')}
                    </IonButton>
                  </form>
                </IonCol>
              )}
            </IonRow>
          </IonGrid>
        </div>
      </IonContent>
    </IonPage>
  );
};

const mapState = (state: RootState) => ({
  postForm: state.posts.postForm,
  categories: state.categories.categories,
  associations: state.associations.associations,
});

const mapDispatch = (dispatch: Dispatch) => ({
  setStatePostForm: dispatch.posts.setStatePostForm,
  setStateToast: dispatch.toast.setStateToast,
});

export default connect(mapState, mapDispatch)(PostForm);
