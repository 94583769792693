import React, { useEffect, useState } from 'react';
import {
  IonButton,
  IonGrid,
  IonRow,
  IonCol,
  IonModal,
  IonCard,
  IonContent,
  IonSpinner,
  IonAvatar,
} from '@ionic/react';
/* import { isPlatform } from '@ionic/react'; */
import { connect } from 'react-redux';
import { Browser } from '@capacitor/browser';
import { useTranslation } from 'react-i18next';
import { FiX } from 'react-icons/fi';
import { useHistory, useLocation } from 'react-router';

/* import { PaymentSheet } from './stripe/PaymentSheet';
import { PaymentFlow } from './stripe/PaymentFlow';
import { ApplePay } from './stripe/ApplePay';
import { GooglePay } from './stripe/GooglePay'; */
import Share from './Share';

import { logEvent } from '../services/firebaseApp';
import { RootState, Dispatch } from '../models/store';

import { dynamicLink, donateLink } from '../constants/routes';
import { getPrices } from '../services/stripeService';
import { createCheckout } from '../services/stripeService';

const DonationModal: React.FC<DonateFormComponent> = ({
  associations,
  donateAssociation,
  setStateToast,
  setStateDonateAssociation,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'donateForm',
  });
  const location = useLocation();
  const history = useHistory();
  const [prices, setPrices] = useState<Price[]>([]);

  useEffect(() => {
    if (!donateAssociation) {
      return;
    }

    logEvent('donate', {
      content_type: 'modal_opened',
      content_id: donateAssociation.code,
    });
    // eslint-disable-next-line
  }, [associations, donateAssociation]);

  useEffect(() => {
    if (!associations.list) {
      return;
    }
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.has('donate')) {
      const association = (associations?.list || []).find(
        ({ code }) => code === queryParams.get('donate')
      );
      setStateDonateAssociation(association);
    } else {
      setStateDonateAssociation(null);
    }

    // eslint-disable-next-line
  }, [associations, donateAssociation, location]);

  const showPrices = async () => {
    if (!donateAssociation) {
      return;
    }
    const { prices } = await getPrices({
      associationId: donateAssociation.id,
    });
    setPrices(prices);
  };

  const goToCheckout = async (priceId: string) => {
    if (!donateAssociation) {
      return;
    }
    const { url } = await createCheckout({
      associationId: donateAssociation.id,
      priceId,
    });

    if (!url) {
      setStateToast({
        isOpen: true,
        message:
          'Cannot proceed to payment at the moment, please try again later',
      });
      return;
    }

    logEvent('donate', {
      content_type: 'internal',
      content_id: donateAssociation?.code,
    });
    Browser.open({
      url,
    });
  };

  const donateExternal = () => {
    logEvent('donate', {
      content_type: 'external',
      content_id: donateAssociation?.code || 'undefined',
    });
    const hasQueryStringInUrl = donateAssociation?.donateUrl.includes('?');
    Browser.open({
      url: `${donateAssociation?.donateUrl}${
        hasQueryStringInUrl ? '&' : '?'
      }utm_source=charitable.be&utm_medium=app`,
    });
  };

  const closeModal = () => {
    const queryParams = new URLSearchParams(location.search);
    queryParams.delete('donate');

    history.replace({
      search: queryParams.toString(),
    });
  };

  return (
    <IonModal
      isOpen={!!donateAssociation}
      breakpoints={[0.1, 0.5, 0.7, 0.9]}
      initialBreakpoint={0.7}
      onWillPresent={showPrices}
      onWillDismiss={() => {
        closeModal();
      }}
      className="content-modal donate-form"
    >
      <IonGrid>
        <IonRow className="ion-justify-content-between">
          <IonCol size="auto">
            <IonButton
              className="type-icon"
              onClick={closeModal}
              color="primary"
              shape="round"
              fill="solid"
            >
              <FiX size="1.3em" />
            </IonButton>
          </IonCol>
          <IonCol size="auto"></IonCol>
        </IonRow>
      </IonGrid>
      {/* {isPlatform('hybrid') ? (
        <>
          <PaymentSheet />
          <PaymentFlow />
          <ApplePay />
          <GooglePay />
          <IonButton expand="block" shape="round" className="ion-margin">
            {`X€`}
          </IonButton>
        </>
      ) : ( */}
      <IonContent fullscreen className="ion-padding-horizontal">
        <IonGrid>
          <IonRow className="ion-justify-content-center ion-margin-top-1x-md-down">
            <IonCol size="auto">
              <IonAvatar className="avatar-lg">
                <img src={donateAssociation?.logo?.sm} alt="" />
              </IonAvatar>
            </IonCol>
          </IonRow>
          <IonRow className="ion-justify-content-center">
            <IonCol size="auto">
              <h2 className="ion-text-center ion-margin-bottom">
                {t('My contribution to ASSOCIATION', {
                  association: donateAssociation?.name,
                })}
              </h2>
            </IonCol>
          </IonRow>
        </IonGrid>
        {!donateAssociation?.usesInternalPayment ? (
          <IonGrid>
            <IonRow className="ion-justify-content-center">
              <IonCol sizeMd="8">
                <p className="ion-text-center">
                  {t(
                    'ASSOCIATION is not yet using the internal payment system which means you will be redirected to their own form.',
                    { association: donateAssociation?.name }
                  )}
                </p>
              </IonCol>
            </IonRow>
            <IonRow className="ion-justify-content-center">
              <IonCol size="auto">
                <IonButton onClick={donateExternal}>
                  {t('Go to donate form')}
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        ) : (
          <IonGrid className="prices ion-no-padding">
            <IonRow>
              {!prices.length ? (
                <IonGrid className="ion-padding">
                  <IonRow className="ion-justify-content-center">
                    <IonCol size="auto">
                      <IonSpinner color="primary" />
                    </IonCol>
                  </IonRow>
                </IonGrid>
              ) : (
                (prices || []).map(({ id, unit_amount, nickname }) => (
                  <IonCol
                    key={`amount-${id}`}
                    sizeXs="12"
                    sizeSm="6"
                    className="ion-padding-half ion-justify-content-center"
                  >
                    <IonCard
                      color="primary"
                      onClick={() => goToCheckout(id)}
                      className="pointer ion-no-margin price"
                    >
                      <h5 color="light" className="ion-padding">{`${
                        unit_amount / 100
                      }€`}</h5>
                      <p color="light" className="ion-padding-horizontal">
                        {nickname}
                      </p>
                    </IonCard>
                  </IonCol>
                ))
              )}
            </IonRow>
            <IonRow>
              <IonCol>
                <hr />
                <small className="ion-margin-bottom">
                  {t('Attestation ASSOCIATION', {
                    association: donateAssociation?.name,
                  })}
                </small>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <hr />
                <p className="ion-text-center ion-margin-bottom">
                  {t(
                    'You will be redirected to our Stripe form to proceed to secure payment'
                  )}
                </p>
              </IonCol>
            </IonRow>
          </IonGrid>
        )}
        <IonGrid className="ion-no-padding">
          <IonRow className="ion-justify-content-center">
            <IonCol size="auto">
              <Share
                type="button"
                size="default"
                fill="clear"
                title={t('Follow me, make a donation to ASSOCIATION', {
                  association: donateAssociation?.name,
                })}
                buttonText={t('Invite connections to donate')}
                url={`${dynamicLink}${donateLink(
                  donateAssociation?.code || '',
                  ''
                )}`}
              />
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
      {/* )} */}
    </IonModal>
  );
};

const mapState = (state: RootState) => ({
  associations: state.associations.associations,
  donateAssociation: state.associations.donateAssociation,
});

const mapDispatch = (dispatch: Dispatch) => ({
  setStateToast: dispatch.toast.setStateToast,
  setStateDonateAssociation: dispatch.associations.setStateDonateAssociation,
});

export default connect(mapState, mapDispatch)(DonationModal);
