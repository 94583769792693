import React from 'react';
import { IonCard } from '@ionic/react';

import { categoryLink } from '../../../constants/routes';

const Card: React.FC<CategoryCardComponent> = ({
  item: category,
  currentLanguage,
  className = '',
}) => {
  return (
    <IonCard
      key={`category-${category?.id}`}
      className={`pointer ${className}`}
      routerLink={categoryLink(category?.code)}
      data-cy={`category-${category?.code}`}
    >
      <img src={`${category?.illustration?.md}`} alt="" />
      <div className="background">
        <div className="title ion-padding-half">
          {category?.[`name_${currentLanguage}`] ?? category?.name}
        </div>
      </div>
    </IonCard>
  );
};

export default Card;
