import React, { useEffect, useState } from 'react';
import { IonButton, IonButtons, isPlatform } from '@ionic/react';
import { FiArrowLeft } from 'react-icons/fi';
import { connect } from 'react-redux';
import { Dispatch, RootState } from '../models/store';
import { useHistory, useLocation } from 'react-router';

const GoBack: React.FC<any> = ({ historyStack, popHistory }) => {
  const history = useHistory();
  const location = useLocation();
  const [lastPage, setLastPage] = useState<string | null>(null);

  useEffect(() => {
    const lastPage: string = historyStack.findLast((page: string) => {
      return !page.includes(location.pathname);
    });
    setLastPage(lastPage);
  }, [historyStack]);

  return !isPlatform('hybrid') || !historyStack.length ? null : (
    <IonButtons slot="start">
      <IonButton
        onClick={() => {
          history.goBack();
          /* if (lastPage) {
            history.push(lastPage);
          } else {
            history.goBack();
          } */
          popHistory();
        }}
      >
        <FiArrowLeft size="1.3em" />
      </IonButton>
    </IonButtons>
  );
};

const mapState = (state: RootState) => ({
  historyStack: state.historyStack.history,
});

const mapDispatch = (dispatch: Dispatch) => ({
  popHistory: dispatch.historyStack.pop,
});

export default connect(mapState, mapDispatch)(GoBack);
