import React from 'react';
import { IonButton, IonIcon } from '@ionic/react';
import { mail } from 'ionicons/icons';

import LoginModal from './LoginModal';

import { SignInWithEmailProps, useAuth } from '../types';

type Props = SignInWithEmailProps & {
  useAuth: typeof useAuth;
};

const SignInWithEmail: React.FC<Props> = ({
  useAuth,
  buttonText,
  isLogin,
  beforeLogin,
  onLoggedIn,
  onError,
  gotoResetPassword,
  expand,
  color,
  size,
  fill,
  shape,
  className,
}) => {
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
  const { user } = useAuth()!;

  return user ? null : (
    <>
      <IonButton
        expand={expand || undefined}
        color={color || 'default'}
        size={size || 'default'}
        fill={fill || 'default'}
        shape={shape || 'round'}
        className={`sign-in-with-email-btn ${className}`}
        onClick={() => {
          setIsModalOpen(true);
        }}
        data-testid="button"
      >
        <IonIcon icon={mail} slot="start" />
        {buttonText ? (
          <span className="ion-margin-start-half">{buttonText}</span>
        ) : (
          ''
        )}
      </IonButton>
      <LoginModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        isLogin={isLogin}
        beforeLogin={beforeLogin}
        onLoggedIn={onLoggedIn}
        onError={onError}
        gotoResetPassword={gotoResetPassword}
        useAuth={useAuth}
      />
    </>
  );
};

export default SignInWithEmail;
