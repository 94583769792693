import React from 'react';
import {
  IonAvatar,
  IonGrid,
  IonCol,
  IonRow,
  IonCard,
  IonText,
} from '@ionic/react';
import { connect } from 'react-redux';

import { Dispatch } from '../../../models/store';
import { associationLink } from '../../../constants/routes';
import Follow from '../../Follow';

const Card: React.FC<AssociationCardComponent> = ({
  item: association,
  list,
  className = '',
  setStateAssociationModalList,
}) => {
  /* const paletteColor = JSON.parse(association?.logo?.paletteColor);
  const colors = [
    `rgba(${paletteColor[0]?.r ?? 255}, ${paletteColor[0]?.g ?? 255}, ${paletteColor[0]?.b ?? 255
    }, 1)`,
    `rgba(${paletteColor[1]?.g ?? 0}, ${paletteColor[1]?.g ?? 0}, ${paletteColor[1]?.b ?? 0
    }, 1)`,
    `rgba(${paletteColor[0]?.r ?? 255}, ${paletteColor[0]?.g ?? 255}, ${paletteColor[0]?.b ?? 255
    }, 0)`,
    `rgba(${paletteColor[1]?.g ?? 0}, ${paletteColor[1]?.g ?? 0}, ${paletteColor[1]?.b ?? 0
    }, 0)`,
  ]; */

  return (
    <IonCard
      key={`association-${association?.id}`}
      className={`ion-no-padding pointer stretch bg-light ${className}`}
      onClick={() => setStateAssociationModalList(list)}
      routerLink={associationLink(association?.code)}
    >
      <IonAvatar className="avatar-absolute avatar-md">
        <img src={association?.logo?.sm} alt="" />
      </IonAvatar>
      <Follow
        association={association}
        type="icon"
        fill="clear"
        className="follow-absolute"
      />
      <img
        src={
          association?.poster?.md ||
          association?.banner?.md ||
          association?.illustration?.md
        }
        alt=""
      />
      <IonGrid
        className="background ion-no-margin ion-padding"
        /* style={{
        backgroundImage: `linear-gradient(to bottom, ${colors[2]}, ${colors[0]})`,
      }} */
      >
        <IonRow className="ion-justify-content-between ion-align-items-center">
          <IonCol
            className="title"
            /* style={{
            color: colors[1],
          }} */
          >
            <p
              className="ion-no-margin"
              /* style={{
              color: colors[1],
            }} */
            >
              {association?.name}
            </p>
            <small>{association?.tagline}</small>
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonCard>
  );
};

const mapDispatch = (dispatch: Dispatch) => ({
  setStateAssociationModalList:
    dispatch.associations.setStateAssociationModalList,
});

export default connect(null, mapDispatch)(Card);
