import { gql } from '@apollo/client';
import imageFragment from '../imageFragment';
import postFragment from '../post/postFragment';
import associationFragment from '../association/associationFragment';

export default gql`
  fragment connectionFragment on Connection {
    id
    approved
    requesterId
    name
    email
    illustration {
      ...imageFragment
    }
    posts {
      ...postFragment
    }
    favorites {
      ...associationFragment
    }
    creationDate
    dateAdded
    dateAccepted
    dateRejected
  }

  ${imageFragment}
  ${postFragment}
  ${associationFragment}
`;
