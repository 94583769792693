import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonList,
  IonLabel,
  IonItem,
  IonNote,
  IonGrid,
  IonRow,
  IonCol,
  IonCard,
  IonButtons,
  IonButton,
  IonIcon,
  IonRouterLink,
  IonSpinner,
  IonText,
} from '@ionic/react';
import { Camera, CameraResultType } from '@capacitor/camera';
import {
  FiUser,
  FiActivity,
  FiGlobe,
  FiCreditCard,
  FiGift,
  FiHome,
} from 'react-icons/fi';
import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/react';
import { useHistory } from 'react-router';

import { RootState, Dispatch } from '../../models/store';

import { editUserPictureBase64 } from '../../services/userService';

import {
  dynamicLink,
  followingLink,
  accountLink,
  favoriteCategoriesLink,
  communityLink,
  recurringDonations,
  donationsLink,
  postsLink,
  associationsLink,
  rootLink,
} from '../../constants/routes';
import { formatDistance } from '../../utils/date';

import Share from '../../components/Share';
import ProfilePicture from '../../components/ProfilePicture';
import Donation from '../../components/Donation';
/* import Doughnut from '../../components/Doughnut'; */

import './Settings.scss';
import { menuOutline } from 'ionicons/icons';
import { CANCELLED, REJECTED } from '../../constants/connections';
import CropperModal from '../../components/CropperModal';
import { LogoutButton } from 'authentication';
import { setUserId, setUserProperty } from '../../services/firebaseApp';
import { FaRegBookmark } from 'react-icons/fa';
import Separator from '../../components/Separator';

const Header: React.FC<HeaderComponent> = ({ user, setStateUser }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation('translation', {
    keyPrefix: 'settings',
  });
  const currentLanguage = language.split('-')[0];
  const [isLoading, setIsLoading] = useState(false);
  const [isNewPicture, setIsNewPicture] = useState(false);
  const [cropData, setCropData] = useState<CropData | null>(null);
  const history = useHistory();

  const takePicture = async () => {
    try {
      const image = await Camera.getPhoto({
        quality: 90,
        allowEditing: true,
        resultType: CameraResultType.Uri,
      });
      const imageUrl = image.webPath;

      if (imageUrl) {
        setIsLoading(true);

        setCropData({
          url: imageUrl,
          type: 'illustration',
          aspectRatio: 1 / 1,
        });
      }
    } catch (e) {
      Sentry.captureException(e);
    }
  };

  const onCropConfirm = async (base64Image: string, cropData: CropData) => {
    const { file } = await editUserPictureBase64({
      file: base64Image,
    });

    setStateUser({ illustration: file.locations });
    setIsNewPicture(true);
    setIsLoading(false);
    setCropData(null);
  };

  const onCropCancel = () => {
    setCropData(null);
    setIsLoading(false);
  };

  return (
    <>
      <CropperModal
        cropData={cropData}
        onCropConfirm={onCropConfirm}
        onCropCancel={onCropCancel}
      />
      <IonItem className="header-lg transparent" detail={false}>
        <ProfilePicture
          slot="start"
          isNewPicture={isNewPicture}
          isLoading={isLoading}
          onClick={takePicture}
        />

        <IonTitle
          size="large"
          onClick={() => {
            history.push(accountLink);
          }}
        >
          {user?.info?.name ?? user?.info?.email}
          <br />
          <IonNote color="primary">
            <small>
              {user?.info?.creationDate &&
                t('Joined Charitable DATE', {
                  date: formatDistance(
                    user?.info?.creationDate,
                    currentLanguage
                  ),
                })}
            </small>
          </IonNote>
        </IonTitle>
      </IonItem>
    </>
  );
};

const Settings: React.FC<SettingsComponent> = ({
  user,
  users,
  associations,
  setStateUser,
  setIsSettingsNavOpen,
  deleteStateUser,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'settings',
  });

  const history = useHistory();

  return (
    <IonPage className="settings">
      <IonHeader>
        <IonToolbar>
          <IonTitle>{user?.info?.name}</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() => setIsSettingsNavOpen(true)}>
              <IonIcon icon={menuOutline} color="dark" size="large" />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense" translucent>
          <IonToolbar>
            <Header user={user} setStateUser={setStateUser} />
          </IonToolbar>
        </IonHeader>
        <div className="page-content with-header-lg">
          <IonGrid className="ion-text-center">
            <IonRow className="ion-align-items-stretch">
              <IonCol size="4" sizeSm="3" sizeMd="2">
                <IonCard className="ion-padding" routerLink={donationsLink}>
                  <FiGift size="2em" />
                  <IonText color="primary">
                    <h4>{user?.payments?.length ?? 0}</h4>
                  </IonText>
                  <small>{t('Donations')}</small>
                </IonCard>
              </IonCol>
              <IonCol size="4" sizeSm="3" sizeMd="2">
                <IonCard className="ion-padding" routerLink={associationsLink}>
                  <FiHome size="2em" />
                  <IonText color="primary">
                    <h4>{user?.associations?.length ?? 0}</h4>
                  </IonText>
                  <small>{t('Associations')}</small>
                </IonCard>
              </IonCol>
              <IonCol size="4" sizeSm="3" sizeMd="2">
                <IonCard className="ion-padding" routerLink={postsLink}>
                  <FiActivity size="2em" />
                  <IonText color="primary">
                    <h4>{user?.posts?.length ?? 0}</h4>
                  </IonText>
                  <small>{t('Posts')}</small>
                </IonCard>
              </IonCol>
              <IonCol size="4" sizeSm="3" sizeMd="2">
                <IonCard className="ion-padding" routerLink={communityLink}>
                  <FiGlobe size="2em" />
                  <IonText color="primary">
                    <h4>
                      {(user?.connections || []).filter(
                        ({ approved }) =>
                          ![REJECTED, CANCELLED].includes(approved)
                      )?.length ?? 0}
                    </h4>
                  </IonText>
                  <small>{t('Connections')}</small>
                </IonCard>
              </IonCol>
              <IonCol size="4" sizeSm="3" sizeMd="2">
                <IonCard className="ion-padding" routerLink={followingLink}>
                  <FaRegBookmark size="2em" />
                  <IonText color="primary">
                    <h4>{user?.favorites?.length ?? 0}</h4>
                  </IonText>
                  <small>{t('Following')}</small>
                </IonCard>
              </IonCol>
            </IonRow>
          </IonGrid>
          {/* <IonGrid>
            <IonRow className="ion-align-items-stretch">
              <IonCol size="6" sizeMd="4">
                <Doughnut donations={0} referrals={0} />
              </IonCol>
              <IonCol size="6" sizeMd="4">
                <Doughnut donations={0} referrals={0} />
              </IonCol>
            </IonRow>
          </IonGrid> */}

          <Separator
            title={t('donations.title')}
            showButtons={!!user?.payments?.length}
            buttons={
              <IonRouterLink
                routerLink={donationsLink}
                className="transparent button-link"
              >
                {t('See all')}
              </IonRouterLink>
            }
          />
          <IonCard>
            {!user?.payments ? (
              <IonGrid>
                <IonRow className="ion-justify-content-center ion-padding">
                  <IonCol size="auto">
                    <IonSpinner color="primary" />
                  </IonCol>
                </IonRow>
              </IonGrid>
            ) : !user?.payments?.length ? (
              <IonGrid className="ion-padding">
                <IonRow className="ion-justify-content-center ion-no-padding">
                  <IonCol size="auto">
                    <p className="ion-text-center">
                      {t("donations.You haven't made donation yet")}
                    </p>
                  </IonCol>
                </IonRow>
                <IonRow className="ion-justify-content-center ion-no-padding">
                  <IonCol size="auto">
                    <IonButton shape="round" routerLink={associationsLink}>
                      {t('donations.Find charities')}
                    </IonButton>
                  </IonCol>
                </IonRow>
              </IonGrid>
            ) : (
              <IonList lines="inset">
                {(user?.payments || []).slice(0, 3).map((payment: Payment) => {
                  const association = (associations?.list || []).find(
                    ({ id }: Association) => id === payment?.associationId
                  );
                  return !association ? null : (
                    <Donation
                      payment={payment}
                      key={`payment-${payment.id}}`}
                    />
                  );
                })}
              </IonList>
            )}
          </IonCard>
          {process.env.REACT_APP_ENV === 'local' ? (
            <Separator
              title={t('associations.title')}
              buttons={
                <IonRouterLink
                  routerLink={associationsLink}
                  className="transparent button-link"
                >
                  {t('See all')}
                </IonRouterLink>
              }
            />
          ) : null}
          <Separator title={t('title')} />
          <IonCard>
            <IonList lines="full">
              <IonItem
                routerLink={accountLink}
                className="ion-align-items-center"
              >
                <IonButtons
                  slot="start"
                  color="primary"
                  className="ion-padding-vertical"
                >
                  <FiUser size="1.2em" />
                </IonButtons>
                <IonLabel>{t('Personal information')}</IonLabel>
              </IonItem>
              {/* <IonItem
                routerLink={communityLink}
                className="ion-align-items-center"
              >
                <IonButtons
                  slot="start"
                  color="primary"
                  className="ion-padding-vertical"
                >
                  <FiGlobe size="1.2em" />
                </IonButtons>
                <IonLabel>{t('Community')}</IonLabel>
              </IonItem>
              <IonItem
                routerLink={followingLink}
                className="ion-align-items-center"
              >
                <IonButtons
                  slot="start"
                  color="primary"
                  className="ion-padding-vertical"
                >
                  <FiHeart size="1.2em" />
                </IonButtons>
                <IonLabel>{t('Following')}</IonLabel>
              </IonItem> */}
              <IonItem
                routerLink={favoriteCategoriesLink}
                className="ion-align-items-center"
              >
                <IonButtons
                  slot="start"
                  color="primary"
                  className="ion-padding-vertical"
                >
                  <FiActivity size="1.2em" />
                </IonButtons>
                <IonLabel>{t('FavoriteCategories')}</IonLabel>
              </IonItem>
              {/* <IonItem
                routerLink={aboutLink}
                className="ion-align-items-center"
              >
                <IonButtons
                  slot="start"
                  color="primary"
                  className="ion-padding-vertical"
                >
                  <FaRegHandPeace size="1.2em" />
                </IonButtons>
                <IonLabel>{t('About')}</IonLabel>
              </IonItem> */}
              {process.env.REACT_APP_ENV === 'local' ? (
                <IonItem
                  routerLink={recurringDonations}
                  className="ion-align-items-center"
                >
                  <IonButtons
                    slot="start"
                    color="primary"
                    className="ion-padding-vertical"
                  >
                    <FiCreditCard size="1.2em" />
                  </IonButtons>
                  <IonLabel>{t('Recurring donations')}</IonLabel>
                </IonItem>
              ) : null}
            </IonList>
          </IonCard>
          <IonGrid>
            <IonRow className="ion-justify-content-center ion-text-center ion-margin-bottom">
              <IonCol size="auto">
                <small>{`Version ${process.env.REACT_APP_VERSION}`}</small>
              </IonCol>
            </IonRow>
          </IonGrid>
          <Share
            type="button"
            fill="outline"
            shape="round"
            title={t('Join Charitable')}
            text={t('Contribute to the good')}
            buttonText={t('Invite friends to join')}
            url={`${dynamicLink}${rootLink}`}
          />
          <IonGrid>
            <IonRow>
              <IonCol>
                <LogoutButton
                  color="medium"
                  fill="clear"
                  expand="full"
                  beforeLogout={deleteStateUser}
                  onLoggedOut={() => {
                    setIsSettingsNavOpen(false);
                    setUserId('');
                    setUserProperty('email', '');
                  }}
                >
                  {t('Logout')}
                </LogoutButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>
      </IonContent>
    </IonPage>
  );
};

const mapState = (state: RootState) => ({
  user: state.user.user,
  users: state.user.users,
  associations: state.associations.associations,
});

const mapDispatch = (dispatch: Dispatch) => ({
  setStateUser: dispatch.user.setStateUserAsync,
  setIsSettingsNavOpen: dispatch.user.setIsSettingsNavOpen,
  deleteStateUser: dispatch.user.deleteStateUserAsync,
});

export default connect(mapState, mapDispatch)(Settings);
