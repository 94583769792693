import React, { useState } from 'react';
import {
  IonContent,
  IonCard,
  IonGrid,
  IonRow,
  IonCol,
  IonItem,
  IonButton,
  IonActionSheet,
  IonRouterLink,
  IonText,
  IonImg,
} from '@ionic/react';
import { connect } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';

import { Dispatch, RootState } from '../../models/store';

import { publishPost, removePost } from '../../services/postService';

import Donate from '../../components/Donate';
import Share from '../../components/Share';
import BadgeList from '../../components/category/lists/BadgeList';

import {
  associationLink,
  dynamicLink,
  postLink,
  postsLink,
  userLink,
} from '../../constants/routes';
import { formatDistance } from '../../utils/date';
import { useHistory } from 'react-router';
import ReadMore from '../../components/ReadMore';
import ReadMoreHTML from '../../components/ReadMoreHTML';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import Like from '../../components/Like';

const PostContent: React.FC<PostContentComponent> = ({
  user,
  post,
  setStateToast,
  className,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation('translation', {
    keyPrefix: 'post',
  });
  const currentLanguage = language.split('-')[0];
  const history = useHistory();

  const [showAlert, setShowAlert] = useState<boolean>(false);

  if (!post) {
    return null;
  }

  const publish = async (id: string) => {
    const { published } = await publishPost({ id });
  };

  const date = formatDistance(post.creationDate, currentLanguage);
  const userName = post?.user?.name;

  return !post.association ? null : (
    <>
      <IonContent fullscreen={true} className={`${className} ion-text-left`}>
        <IonGrid className="ion-no-padding">
          <IonRow>
            <IonCol sizeXs="12" sizeSm="12" sizeMd="6">
              {post?.illustration && (
                <IonCard className="ion-margin main-image">
                  <IonImg src={post?.illustration?.lg} alt="" />
                </IonCard>
              )}
              <IonGrid className="ion-padding-horizontal">
                {!user?.info?.admin ? null : (
                  <IonRow className="ion-justify-content-end ion-padding">
                    <IonCol size="auto">
                      <IonButton
                        shape="round"
                        color="light"
                        className="type-icon"
                        onClick={() => publish(post.id)}
                      >
                        {post?.published ? <FaEye /> : <FaEyeSlash />}
                      </IonButton>
                    </IonCol>
                  </IonRow>
                )}
                <IonRow className="ion-hide-md-down">
                  <IonCol>
                    <BadgeList categories={post.categories} />
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonCol>
            <IonCol
              sizeXs="12"
              sizeSm="12"
              sizeMd="6"
              className="ion-no-padding"
            >
              <IonGrid className="ion-padding">
                <IonRow>
                  <IonCol size="auto">
                    <h1>{post?.title}</h1>
                    <IonItem
                      className="transparent ion-text-center"
                      routerLink={associationLink(post.association?.code)}
                      detail={false}
                    >
                      {post.association?.name}
                    </IonItem>
                    <IonText color="medium">
                      <small>
                        <Trans i18nKey="addedByUser">
                          Added {{ date }} by{' '}
                          <IonRouterLink
                            routerLink={userLink(post?.user?.id)}
                            className="transparent button-link"
                          >
                            {{ userName }}
                          </IonRouterLink>
                        </Trans>
                      </small>
                    </IonText>
                  </IonCol>
                </IonRow>
                <hr />
                <IonRow>
                  <IonCol>
                    <small>
                      <ReadMore
                        type="association"
                        item={post?.id}
                        maxLength={350}
                      >
                        {post?.content}
                      </ReadMore>
                    </small>
                  </IonCol>
                </IonRow>
                <IonRow className="ion-hide-md-up">
                  <IonCol>
                    <BadgeList categories={post.categories} />
                  </IonCol>
                </IonRow>
                <IonRow className="ion-margin-top">
                  <IonCol>
                    <Donate
                      type="button"
                      fill="clear"
                      association={post.association}
                      className="ion-no-margin-vertical"
                    />
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <Share
                      type="button"
                      fill="clear"
                      title={post.title}
                      buttonText={t('Share this post')}
                      url={`${dynamicLink}${postsLink}${postLink(post.id)}`}
                      className="ion-no-margin"
                    />
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <Like
                      type="button"
                      fill="clear"
                      expand="block"
                      post={post}
                      className="ion-no-margin-vertical"
                    />
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonButton
                      color="danger"
                      fill="clear"
                      expand="block"
                      className="ion-no-margin-vertical"
                      onClick={() => setShowAlert(true)}
                    >
                      {t('Delete this post')}
                    </IonButton>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
      <IonActionSheet
        data-testid="logout-alert"
        isOpen={showAlert}
        onDidDismiss={() => setShowAlert(false)}
        header={t('Are you sure?')}
        buttons={[
          {
            text: t('Delete this post'),
            role: 'destructive',
            data: {
              type: 'delete',
            },
            handler: async () => {
              try {
                await removePost({ id: post.id });
                history.push(postsLink);
                setStateToast({
                  isOpen: true,
                  type: 'success',
                  position: 'top',
                  message: 'Post deleted successfully',
                });
              } catch (e) {
                setStateToast({
                  isOpen: true,
                  message:
                    'Cannot delete this post at the moment, please try again later',
                });
              }
            },
          },
          {
            text: t('Cancel'),
            role: 'cancel',
          },
        ]}
      />
    </>
  );
};

const mapState = (state: RootState) => ({
  user: state.user.user,
  posts: state.posts.posts,
});

const mapDispatch = (dispatch: Dispatch) => ({
  setStateToast: dispatch.toast.setStateToast,
});

export default connect(mapState, mapDispatch)(PostContent);
