import { gql } from '@apollo/client';
import associationFragment from '../association/associationFragment';
import categoryFragment from '../category/categoryFragment';
import imageFragment from '../imageFragment';

export default gql`
  fragment postFragment on Post {
    id
    published
    title
    content
    creationDate
    illustration {
      ...imageFragment
    }
    association {
      ...associationFragment
    }
    categories {
      ...categoryFragment
    }
    user {
      id
      name
    }
  }

  ${imageFragment}
  ${categoryFragment}
  ${associationFragment}
`;
