import React from 'react';
import {
  IonButton,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonIcon,
} from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { useSwiper } from 'swiper/react';
import { notificationsOutline } from 'ionicons/icons';

const NotificationSlide: React.FC<NotificationSlideComponent> = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'introduction.notification',
  });
  const swiper = useSwiper();

  return (
    <>
      <IonGrid className="full-height">
        <IonRow className="header-row" />
        <IonRow className="main-row ion-padding ion-align-items-center ion-justify-content-center">
          <IonCol sizeSm="8" sizeLg="6">
            <IonIcon icon={notificationsOutline} color="primary" />
            <h1 className="b">{t('Up to date')}</h1>
            <p>
              {t(
                'Notifications will tackle activities of organizations you follow'
              )}
            </p>
          </IonCol>
        </IonRow>
        <IonRow className="actions-row">
          <IonCol>
            <IonButton
              shape="round"
              size="large"
              className="ion-margin"
              onClick={() => {
                swiper.slideNext();
              }}
            >
              {t('Next')}
            </IonButton>
          </IonCol>
        </IonRow>
      </IonGrid>
    </>
  );
};

export default NotificationSlide;
