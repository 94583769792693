import { isValid } from 'date-fns';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import format from 'date-fns/format';
import { fr, enUS } from 'date-fns/locale';

const isValidTimestamp = (timestamp: string) =>
  new Date(timestamp).getTime() > 0;

export const formatDistance = (
  dateInString: string,
  currentLanguage: string,
  addSuffix: boolean = true
) => {
  const date = new Date(
    isValidTimestamp(dateInString) ? dateInString : parseInt(dateInString)
  );

  if (!isValid(date)) {
    return null;
  }
  return formatDistanceToNow(date, {
    addSuffix,
    locale: currentLanguage === 'fr' ? fr : enUS,
  });
};

export const formatDate = (
  dateInString: string,
  formatDisplay: string = 'yyyy'
) => {
  const date = new Date(
    isValidTimestamp(dateInString) ? dateInString : parseInt(dateInString)
  );

  if (!isValid(date)) {
    return null;
  }
  return format(date, formatDisplay);
};

export const sortByDateAdded = (a: any, b: any) => {
  if (a.dateAdded < b.dateAdded) {
    return 1;
  }
  if (a.dateAdded > b.dateAdded) {
    return -1;
  }
  return 0;
};

export const sortByCreationDate = (a: any, b: any) => {
  if (a.creationDate < b.creationDate) {
    return 1;
  }
  if (a.creationDate > b.creationDate) {
    return -1;
  }
  return 0;
};

export const greetings = (t: Function, username: string | undefined) => {
  const myDate = new Date();
  const hrs = myDate.getHours();

  if (hrs < 12)
    return username ? t('Good morning NAME', { username }) : t('Good morning');
  else if (hrs >= 12 && hrs <= 17)
    return username
      ? t('Good afternoon NAME', { username })
      : t('Good afternoon');
  else if (hrs >= 17 && hrs <= 24)
    return username ? t('Good evening NAME', { username }) : t('Good evening');
};

export const sortByName = (a: Category, b: Category) => {
  if (a.name < b.name) {
    return -1;
  }
  if (a.name > b.name) {
    return 1;
  }
  return 0;
};
