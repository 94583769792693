import gql from 'graphql-tag';
import postFragment from './postFragment';

export const addPostPictureBase64 = gql`
  mutation addPostPictureBase64($file: String!, $id: ID!) {
    addPostPictureBase64(file: $file, id: $id) {
      file {
        success
        message
        locations {
          lg
          md
          sm
        }
      }
      post {
        ...postFragment
      }
    }
  }

  ${postFragment}
`;
