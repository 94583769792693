import React from 'react';
import { IonGrid, IonCol, IonRow, IonButton, IonicSwiper } from '@ionic/react';
import { BiDotsHorizontal } from 'react-icons/bi';
import { FiGrid } from 'react-icons/fi';
import SwiperCore, { FreeMode, Virtual, Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/free-mode';
import 'swiper/css/virtual';
import '@ionic/react/css/ionic-swiper.css';
import Separator from './Separator';

SwiperCore.use([IonicSwiper, Navigation, Pagination]);

const CardList: React.FC<CardListComponent> = ({
  display,
  list,
  title,
  className = '',
  toggleDisplay,
  Card,
  pagination,
  slidesPerView,
  freeMode = false,
}) => {
  return list && !list.length ? null : (
    <>
      {!list?.length ? null : (
        <Separator
          title={title}
          showButtons={!!toggleDisplay}
          buttons={
            <IonButton
              fill="clear"
              size="small"
              onClick={toggleDisplay}
              className="ion-hide-sm-down"
            >
              {display === 'inline' ? (
                <FiGrid className="ion-no-margin" size="1.5em" />
              ) : (
                <BiDotsHorizontal size="1.5em" />
              )}
            </IonButton>
          }
        />
      )}

      {display === 'inline' ? (
        <Swiper
          speed={100}
          pagination={pagination}
          slidesPerView={slidesPerView[0]}
          spaceBetween={16}
          freeMode={freeMode}
          modules={[FreeMode, Virtual, Pagination]}
          breakpoints={{
            576: {
              slidesPerView: slidesPerView[1],
            },
            768: {
              slidesPerView: slidesPerView[2],
            },
            992: {
              slidesPerView: slidesPerView[3],
            },
          }}
          className={`cards-list ${
            pagination ? 'with-pagination' : ''
          } ${className}`}
        >
          {(list || []).map((item) => {
            return (
              <SwiperSlide key={`item-${item.id}`}>
                <Card item={item} list={list} />
              </SwiperSlide>
            );
          })}
        </Swiper>
      ) : (
        <IonGrid className={`cards-list ${className}`}>
          <IonRow>
            {(list || []).map((item) => (
              <IonCol
                key={`item-${item.id}`}
                sizeXs="6"
                sizeSm="6"
                sizeMd="4"
                sizeLg="4"
                sizeXl="3"
              >
                <Card item={item} list={list} />
              </IonCol>
            ))}
          </IonRow>
        </IonGrid>
      )}
    </>
  );
};

export default CardList;
