import React from 'react';
import {
  IonContent,
  IonButton,
  IonGrid,
  IonCol,
  IonRow,
  IonCard,
} from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { useSwiper } from 'swiper/react';
import { connect } from 'react-redux';

import { RootState } from '../../../models/store';

const CategoriesSlide: React.FC<CategoriesSlideComponent> = ({
  categories,
  selectedCategories,
  setSelectedCategories,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation('translation', { keyPrefix: 'help.categories' });
  const swiper = useSwiper();

  const currentLanguage = language.split('-')[0];

  return (
    <>
      <IonContent>
        <IonGrid>
          <IonRow className="content-top">
            <IonCol sizeXs="12" offsetXs="0" sizeMd="6" offsetMd="0">
              <h1>{t('Categories')}</h1>
              <p>{t('Select the causes you care about')}</p>
              <IonButton
                shape="round"
                className="ion-margin ion-hide-md-down"
                onClick={() => {
                  swiper.slideNext();
                }}
              >
                {t('Nex step')}
              </IonButton>
            </IonCol>
            <IonCol
              sizeXs="12"
              offsetXs="0"
              sizeMd="6"
              offsetMd="0"
              className="categories-container ion-no-padding"
            >
              <IonGrid className="cards-list">
                <IonRow>
                  {(
                    (categories?.list || []).filter(
                      ({ children }: Category) => children?.length
                    ) || []
                  ).map((category: Category) => {
                    const isSelected = selectedCategories.filter(
                      ({ id }) => id === category.id
                    ).length;

                    return (
                      <IonCol
                        key={`category-${category.id}`}
                        sizeXs="6"
                        sizeSm="4"
                        sizeMd="4"
                        sizeLg="4"
                        onClick={() =>
                          setSelectedCategories(
                            isSelected
                              ? selectedCategories.filter(
                                  ({ id }) => id !== category.id
                                )
                              : [...selectedCategories, category]
                          )
                        }
                      >
                        <IonCard
                          className={`ion-no-margin ion-no-padding ion-margin-horizontal-half ion-margin-bottom pointer ${
                            isSelected ? 'selected' : ''
                          }`}
                        >
                          <img src={`${category?.illustration?.md}`} alt="" />
                          <div className="background">
                            <div className="title ion-padding">
                              {category?.[`name_${currentLanguage}`] ??
                                category?.name}
                            </div>
                          </div>
                        </IonCard>
                      </IonCol>
                    );
                  })}
                </IonRow>
              </IonGrid>
              <IonButton
                shape="round"
                className="ion-margin ion-hide-md-up"
                onClick={() => {
                  swiper.slideNext();
                }}
              >
                {t('Nex step')}
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </>
  );
};

const mapState = (state: RootState) => ({
  categories: state.categories.categories,
});

export default connect(mapState)(CategoriesSlide);
