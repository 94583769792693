import { gql } from '@apollo/client';
import postFragment from '../post/postFragment';

export const likePost: any = gql`
  mutation likePost($id: ID!) {
    likePost(id: $id) {
      ...postFragment
    }
  }

  ${postFragment}
`;
