import * as Sentry from '@sentry/react';

import { removeTypenames } from '../utils/graphql';

import client from '../apollo/client';

import { getNotificationsByUser as getNotificationsByUserQuery } from '../graphql/notification/getNotificationsByUser';
import { readNotification as readNotificationQuery } from '../graphql/notification/readNotification';

export async function getNotifications(): Promise<{
  notifications: Notification[];
}> {
  try {
    const {
      data: { getNotificationsByUser: notifications },
    } = await client.query({
      query: getNotificationsByUserQuery,
      fetchPolicy: 'no-cache',
    });

    return { notifications: removeTypenames(notifications) };
  } catch (e: any) {
    Sentry.captureException(e);
    throw new Error(e);
  }
}

export async function readNotification(variables: {
  id: string;
}): Promise<{ notification: Notification }> {
  try {
    const {
      data: { readNotification: notification },
    } = await client.mutate({
      mutation: readNotificationQuery,
      variables,
      fetchPolicy: 'no-cache',
    });
    return { notification: removeTypenames(notification) };
  } catch (e: any) {
    Sentry.captureException(e);
    throw new Error(e);
  }
}
