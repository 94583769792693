import { Position } from '@capacitor/geolocation';
import * as Sentry from '@sentry/react';

import { SignInResult } from 'authentication';

import { removeTypenames } from '../utils/graphql';
import { cleanupCoordinates } from '../utils/cleanupCoordinates';
import client from '../apollo/client';

import { getUsers as getUsersQuery } from '../graphql/user/getUsers';
import { getUserByEmail as getUserByEmailQuery } from '../graphql/user/getUserByEmail';
import { getUserPayments as getUserPaymentsQuery } from '../graphql/user/getUserPayments';
import { createUser as createUserQuery } from '../graphql/user/createUser';
import { editUserAddress as editUserAddressQuery } from '../graphql/user/editUserAddress';
import { addUserPicture as addUserPictureQuery } from '../graphql/user/addUserPicture';
import { addUserPictureBase64 as addUserPictureBase64Query } from '../graphql/user/addUserPictureBase64';
import { editUserFavoriteCategories as editUserFavoriteCategoriesQuery } from '../graphql/user/editUserFavoriteCategories';
import { addUserDevice as addDeviceQuery } from '../graphql/user/addUserDevice';
import { addUserLocation as addUserLocationQuery } from '../graphql/user/addUserLocation';
import { editUser as editUserQuery } from '../graphql/user/editUser';
import { editUserNotification as editUserNotificationQuery } from '../graphql/user/editUserNotification';
import { deleteCurrentUser as deleteCurrentUserQuery } from '../graphql/user/deleteCurrentUser';
import { deleteUser as deleteUserQuery } from '../graphql/user/deleteUser';

export async function getUsers(): Promise<{ users: User[] }> {
  try {
    const {
      data: { getUsers: users },
    } = await client.query({
      query: getUsersQuery,
      variables: { all: false },
      fetchPolicy: 'no-cache',
    });
    return { users: removeTypenames(users) };
  } catch (e: any) {
    throw new Error(e);
  }
}

export async function getUserByEmail(variables: {
  email: string;
}): Promise<{ user: User | null }> {
  try {
    const {
      data: { getUserByEmail: user },
    } = await client.query({
      query: getUserByEmailQuery,
      variables,
      fetchPolicy: 'no-cache',
    });
    return { user: removeTypenames(user) };
  } catch (e: any) {
    return { user: null };
  }
}

export async function getUserPayments(): Promise<{ payments: Payment }> {
  try {
    const {
      data: { getUserPayments: payments },
    } = await client.query({
      query: getUserPaymentsQuery,
      fetchPolicy: 'no-cache',
    });
    return { payments: removeTypenames(payments) };
  } catch (e: any) {
    throw new Error(e);
  }
}

export async function createUser(variables: {
  name?: string;
  email?: string;
}): Promise<{ user: User | null }> {
  try {
    const {
      data: { createUser: user },
    } = await client.query({
      query: createUserQuery,
      variables: { input: variables },
      fetchPolicy: 'no-cache',
    });

    return { user: removeTypenames(user) };
  } catch (e: any) {
    Sentry.captureException(e);
    throw new Error(e);
  }
}

export async function editUser(variables: {
  name?: string;
  email?: string;
  gender?: string;
  birthdate?: string;
  firebaseUser?: string;
}): Promise<SignInResult> {
  try {
    const {
      data: { editUser: user },
    } = await client.query({
      query: editUserQuery,
      variables: { input: variables },
      fetchPolicy: 'no-cache',
    });

    return { user: removeTypenames(user), error: null };
  } catch (e: any) {
    Sentry.captureException(e);
    throw new Error(e);
  }
}

export async function editUserPicture(variables: {
  file: any;
}): Promise<{ file: { locations: Image; message: string; success: boolean } }> {
  try {
    const {
      data: { addUserPicture: file },
    } = await client.query({
      query: addUserPictureQuery,
      variables,
      fetchPolicy: 'no-cache',
    });

    return { file: removeTypenames(file) };
  } catch (e: any) {
    Sentry.captureException(e);
    throw new Error(e);
  }
}

export async function editUserPictureBase64(variables: {
  file: string;
}): Promise<{ file: { locations: Image; message: string; success: boolean } }> {
  try {
    const {
      data: { addUserPictureBase64: file },
    } = await client.query({
      query: addUserPictureBase64Query,
      variables,
      fetchPolicy: 'no-cache',
    });

    if (!file.locations) {
      throw new Error();
    }

    return { file: removeTypenames(file) };
  } catch (e: any) {
    Sentry.captureException(e);
    throw new Error(e);
  }
}

export async function editUserAddress(variables: Partial<Address>) {
  try {
    const {
      data: { editUserAddress: address },
    } = await client.query({
      query: editUserAddressQuery,
      variables: { input: variables },
      fetchPolicy: 'no-cache',
    });

    return { address: removeTypenames(address) };
  } catch (e: any) {
    Sentry.captureException(e);
    throw new Error(e);
  }
}

export async function editUserFavoriteCategories(variables: {
  categoryIds: string[];
}) {
  try {
    const {
      data: { editUserFavoriteCategories: categories },
    } = await client.query({
      query: editUserFavoriteCategoriesQuery,
      variables,
      fetchPolicy: 'no-cache',
    });

    return { categories: removeTypenames(categories) };
  } catch (e: any) {
    Sentry.captureException(e);
    throw new Error(e);
  }
}

export async function addUserDevice(variables: {
  deviceId?: string;
  deviceToken?: string;
  manufacturer: string;
  model: string;
  operatingSystem: string;
  osVersion: string;
  platform: string;
}): Promise<{ device: Device | null }> {
  try {
    const {
      data: { addUserDevice: device },
    } = await client.query({
      query: addDeviceQuery,
      variables: { input: variables },
      fetchPolicy: 'no-cache',
    });

    return { device: removeTypenames(device) };
  } catch (e: any) {
    Sentry.captureException(e);
    throw new Error(e);
  }
}

export async function addUserLocation(variables: {
  coordinates: Position;
}): Promise<{ coordinates: Coordinate }> {
  const coordinatesObject = cleanupCoordinates(variables.coordinates);
  try {
    const {
      data: { addUserLocation: coordinates },
    } = await client.query({
      query: addUserLocationQuery,
      variables: { coordinates: coordinatesObject },
      fetchPolicy: 'no-cache',
    });

    return { coordinates: removeTypenames(coordinates) };
  } catch (e: any) {
    Sentry.captureException(e);
    throw new Error(e);
  }
}

export async function editUserNotification(variables: {
  accepted: boolean;
}): Promise<SignInResult> {
  try {
    await client.query({
      query: editUserNotificationQuery,
      variables: { input: variables },
      fetchPolicy: 'no-cache',
    });

    return { user: null, error: null };
  } catch (e: any) {
    Sentry.captureException(e);
    throw new Error(e);
  }
}

export async function deleteCurrentUser(): Promise<SignInResult> {
  try {
    await client.query({
      query: deleteCurrentUserQuery,
      fetchPolicy: 'no-cache',
    });

    return { user: null, error: null };
  } catch (e: any) {
    Sentry.captureException(e);
    throw new Error(e);
  }
}

export async function deleteUser(variables: {
  id: string;
}): Promise<SignInResult> {
  try {
    await client.query({
      query: deleteUserQuery,
      variables,
      fetchPolicy: 'no-cache',
    });

    return { user: null, error: null };
  } catch (e: any) {
    Sentry.captureException(e);
    throw new Error(e);
  }
}
