import gql from 'graphql-tag';

import associationFragment from './associationFragment';

export const addAssociationPictureBase64: any = gql`
  mutation addAssociationPictureBase64(
    $file: String!
    $id: ID!
    $type: FileType!
  ) {
    addAssociationPictureBase64(file: $file, id: $id, type: $type) {
      file {
        success
        message
        locations {
          lg
          md
          sm
        }
      }
      association {
        ...associationFragment
      }
    }
  }

  ${associationFragment}
`;
