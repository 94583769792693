import gql from 'graphql-tag';
import associationFragment from './associationFragment';

export const starAssociation = gql`
  mutation starAssociation($id: ID!) {
    starAssociation(id: $id) {
      ...associationFragment
    }
  }

  ${associationFragment}
`;
