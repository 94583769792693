import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonPage,
} from '@ionic/react';
import { Geolocation } from '@capacitor/geolocation';
import * as Sentry from '@sentry/react';
import { useTranslation } from 'react-i18next';

import { RootState, Dispatch } from '../../models/store';

import { findByCode } from '../../utils/findByCode';

import CategoriesHeader from '../../components/category/CategoriesHeader';
import ConfirmPaymentModal from '../../components/ConfirmPaymentModal';
import AvatarList from '../../components/association/lists/AvatarList';

import { searchAssociations } from '../../services/associationService';
import { useParams } from 'react-router';
import GoBack from '../../components/GoBack';

const Category: React.FC<CategoryComponent> = ({
  categories,
  associations,
  category,
  setStateAssociations,
  setStateCategory,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation('translation', {
    keyPrefix: 'associations',
  });
  const currentLanguage = language.split('-')[0];
  const { categoryCode } = useParams<{ categoryCode: string }>();
  const [list, setList] = useState<Association[] | null>(null);

  useEffect(() => {
    const category = findByCode(categoryCode, categories?.list ?? []);
    setStateCategory(category);
    // eslint-disable-next-line
  }, [categoryCode, categories]);

  useEffect(() => {
    (async () => {
      if (!category) {
        return;
      }
      try {
        const coordinates =
          category?.code === 'local'
            ? await Geolocation.getCurrentPosition()
            : null;
        const { associations: list } = await searchAssociations({
          searchValue: '',
          categoryCode: category?.code ?? '',
          coordinates,
        });
        setList(list);
      } catch (e: any) {
        Sentry.captureMessage(e);
      }
    })();
    // eslint-disable-next-line
  }, [category]);

  return (
    <>
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <GoBack />
            <IonTitle className="ion-text-center">
              {category?.[`name_${currentLanguage}`] ?? category?.name}
            </IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent fullscreen>
          <IonHeader collapse="condense" translucent>
            <IonToolbar>
              <IonTitle size="large">
                {category?.[`name_${currentLanguage}`] ?? category?.name}
              </IonTitle>
            </IonToolbar>
          </IonHeader>

          <div className="page-content">
            <CategoriesHeader />

            {!(list || []).length ? (
              <div className="ion-text-center ion-padding" data-cy="results">
                <p>{t('No results found')}</p>
              </div>
            ) : null}
            <AvatarList list={list} title="Associations" />
          </div>
        </IonContent>
      </IonPage>
      <ConfirmPaymentModal />
    </>
  );
};

const mapState = (state: RootState) => ({
  categories: state.categories.categories,
  associations: state.associations.associations,
  category: state.categories.category,
});

const mapDispatch = (dispatch: Dispatch) => ({
  setStateAssociations: dispatch.associations.setStateAssociations,
  setStateCategory: dispatch.categories.setStateCategory,
});

export default connect(mapState, mapDispatch)(Category);
