import { gql } from '@apollo/client';

export const connectionRequestReceived: any = gql`
  subscription connectionRequestReceived {
    connectionRequestReceived {
      requesterId
      receiverId
      status
    }
  }
`;
