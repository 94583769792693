import React from 'react';
import { connect } from 'react-redux';
import {
  IonContent,
  IonPage,
  IonSpinner,
  IonGrid,
  IonRow,
  IonCol,
  IonHeader,
  IonToolbar,
} from '@ionic/react';
import { useTranslation } from 'react-i18next';

import { RootState } from '../../models/store';

import SearchBar from '../../components/SearchBar';
import SearchResults from '../../components/SearchResults';
import Categories from '../../components/category/Categories';

const Search: React.FC<SearchComponent> = ({
  categories,
  associations,
  posts,
  connections,
  searchValue,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'search',
  });

  return (
    <>
      <IonPage>
        <IonHeader>
          <IonToolbar></IonToolbar>
        </IonHeader>
        <IonContent fullscreen>
          <IonHeader collapse="condense" translucent>
            <IonToolbar>
              <SearchBar />
            </IonToolbar>
          </IonHeader>
          <div className="page-content">
            {[
              associations.status,
              posts.status,
              connections.status,
              categories.status,
            ].includes('loading') ? (
              <IonGrid className="full-height ion-padding">
                <IonRow className="row-grow ion-justify-content-center ion-align-items-center">
                  <IonCol size="auto">
                    <IonSpinner color="primary" />
                  </IonCol>
                </IonRow>
              </IonGrid>
            ) : searchValue ? (
              <SearchResults />
            ) : categories.status === '' && !(categories.list || []).length ? (
              <div className="ion-text-center ion-padding" data-cy="results">
                <p>{t('No results found')}</p>
              </div>
            ) : (
              <Categories display="grid" />
            )}
          </div>
        </IonContent>
      </IonPage>
    </>
  );
};

const mapState = (state: RootState) => ({
  user: state.user.user,
  categories: state.categories.categories,
  posts: state.posts.posts,
  associations: state.associations.associations,
  connections: state.user.users,
  searchValue: state.search.value,
});

export default connect(mapState)(Search);
