import { createModel } from '@rematch/core';
import { addFavorite, removeFavorite } from '../services/favoriteService';
import { addConnection, removeConnection } from '../services/connectionService';
import Storage from '../storage';
import { RootState, RootModel } from './store';
import { getUserByEmail } from '../services/userService';
import { likePost, unlikePost } from '../services/postService';

const initialState: UsersState = {
  user: {
    status: '',
    info: null,
    devices: null,
    payments: null,
    associations: null,
    favorites: null,
    likes: null,
    categories: null,
    connections: null,
    posts: null,
    firebase: null,
  },
  users: {
    status: 'initializing',
    list: null,
  },
  isSettingsNavOpen: false,
};

const storage = new Storage();

export const user = createModel<RootModel>()({
  state: initialState,
  reducers: {
    setStateUser: (state, payload: Partial<UserInterface>) => ({
      ...state,
      user: { ...state.user, ...payload },
    }),
    setStateUserInfo: (state, payload: User) => ({
      ...state,
      user: { ...state.user, info: { ...state.user?.info, ...payload } },
    }),
    deleteStateUser: (state) => ({
      ...state,
      user: initialState.user,
    }),
    setIsSettingsNavOpen: (state, payload: boolean) => ({
      ...state,
      isSettingsNavOpen: payload,
    }),
    setStateUsers: (state, payload: Partial<UsersInterface>) => ({
      ...state,
      users: { ...state.users, ...payload },
    }),
  },
  effects: (dispatch) => {
    const { user, toast } = dispatch;
    return {
      async setStateUserAsync(payload: Partial<UserInterface>): Promise<void> {
        await storage.set('user', payload);
        user.setStateUser(payload);
      },
      async deleteStateUserAsync(): Promise<void> {
        storage.remove('user');
        user.deleteStateUser();
      },
      async toggleStateFollowing(
        payload: Association,
        state: RootState
      ): Promise<void> {
        const { favorites } = state.user.user;
        const ids = (favorites || []).map(({ id }: { id: string }) => id);
        const alreadyInFavourite = ids.includes(payload.id);

        const newList = alreadyInFavourite
          ? (favorites || []).filter(({ id }: Association) => id !== payload.id)
          : [...(favorites || []), payload];

        try {
          await user.setStateUserAsync({ favorites: newList });

          if (alreadyInFavourite) {
            await removeFavorite({ associationId: payload.id });
          } else {
            await addFavorite({ associationId: payload.id });
            dispatch.toast.setStateToast({
              isOpen: true,
              type: 'success',
              message: `Association followed successfully`,
            });
          }
        } catch (e: any) {
          dispatch.toast.setStateToast({
            isOpen: true,
            message: `Cannot ${
              alreadyInFavourite ? 'remove' : 'add'
            } favorite at the moment, please try again later`,
          });
        }
      },
      async toggleStateLike(payload: Post, state: RootState): Promise<void> {
        const { likes } = state.user.user;
        const ids = (likes || []).map(({ id }: { id: string }) => id);
        const alreadyInLikes = ids.includes(payload.id);

        const newList = alreadyInLikes
          ? (likes || []).filter(({ id }: Post) => id !== payload.id)
          : [...(likes || []), payload];

        try {
          await user.setStateUserAsync({ likes: newList });

          if (alreadyInLikes) {
            await unlikePost({ id: payload.id });
          } else {
            await likePost({ id: payload.id });
          }
        } catch (e: any) {
          dispatch.toast.setStateToast({
            isOpen: true,
            message: `Cannot ${
              alreadyInLikes ? 'unlike' : 'like'
            } at the moment, please try again later`,
          });
        }
      },
      async addConnectionInState(
        payload: Connection,
        state: RootState
      ): Promise<void> {
        const { connections, info } = state.user.user;
        if (!info?.email || !connections) {
          return;
        }

        try {
          const newConnection = await addConnection({ userId: payload.id });
          const { user: updatedUser } = await getUserByEmail({
            email: info?.email,
          });
          if (!updatedUser) {
            return;
          }
          const { connections: newConnections } = updatedUser;
          user.setStateUserAsync({ connections: newConnections });
        } catch (e: any) {
          dispatch.toast.setStateToast({
            isOpen: true,
            message: `Cannot add connection at the moment, please try again later`,
          });
        }
      },
      async removeConnectionInState(
        payload: { approved: 'pending' | 'accepted'; connection: Connection },
        state: RootState
      ): Promise<void> {
        const { connections, info } = state.user.user;
        if (!info?.email || !connections) {
          return;
        }

        try {
          await removeConnection({ userId: payload.connection.id });
          const { user: updatedUser } = await getUserByEmail({
            email: info?.email,
          });
          if (!updatedUser) {
            return;
          }
          const { connections: newConnections } = updatedUser;
          user.setStateUserAsync({ connections: newConnections });
        } catch (e: any) {
          dispatch.toast.setStateToast({
            isOpen: true,
            message: `Cannot add connection at the moment, please try again later`,
          });
        }
      },
    };
  },
});
