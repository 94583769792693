import React from 'react';
import { IonActionSheet, IonButton } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/react';

const Confirm: React.FC<any> = ({
  confirm,
  children,
  headerText,
  confirmText,
  className,
  size,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'common',
  });
  const [showAlert, setShowAlert] = React.useState<boolean>(false);
  const [event, setEvent] = React.useState<any>(null);

  return (
    <>
      <span
        onClick={(e) => {
          if (e?.preventDefault) {
            e.preventDefault();
            e.stopPropagation();
            e.nativeEvent.stopImmediatePropagation();
          }
          setShowAlert(true);
          setEvent(e);
        }}
        className={className}
      >
        {children}
      </span>
      <IonActionSheet
        isOpen={showAlert}
        onDidDismiss={() => setShowAlert(false)}
        header={headerText}
        cssClass="confirm-alert"
        buttons={[
          {
            text: confirmText || t('Confirm'),
            role: 'destructive',
            data: {
              type: 'delete',
            },
            handler: async () => {
              try {
                await confirm(event);
              } catch (e) {
                Sentry.captureException(e);
              }
            },
          },
          {
            text: t('Cancel'),
            role: 'cancel',
          },
        ]}
      />
    </>
  );
};

export default Confirm;
