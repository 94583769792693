export const dynamicLink = `${process.env.REACT_APP_BASE_URL}/link`;
export const internalDynamicLink = '/link';
export const searchResultsLink = (value: string) => `?search=${value}`;
export const rootLink = '/';
export const homeLink = '/home';
export const searchLink = '/search';
export const associationsLink = '/associations';
export const associationsUserLink = (id: string) =>
  `/associations?filter=${id}`;
export const associationLink = (code: string) => `?association=${code}`;
export const associationFormLink = (code: string) =>
  `?association=${code}&edit`;
export const associationNewFormLink = `?association=NEW&edit`;
export const associationEditLink = (code: string) => `/association/${code}`;
export const donationsLink = '/donations';
export const associationDonationsLink = (code: string) =>
  `/donations?filter=${code}`;
export const donateLink = (code: string, searchParams: string) =>
  searchParams ? `${searchParams}&donate=${code}` : `?donate=${code}`;
export const categoryRouteLink = '/category/:categoryCode';
export const categoryLink = (code: string) => `/category/${code}`;
export const postsLink = '/posts';
export const postsUserLink = (id: string) => `/posts?filter=${id}`;
export const postFormLink = '/post/new';
export const postLink = (id: string) => `?post=${id}`;
export const followingLink = '/favorites';
export const communityLink = '/community';
export const userLink = (id: string) => `?connection=${id}`;
export const loginLink = '/login';
export const registerLink = '/register';
export const resetPasswordLink = '/reset-password';
export const settingsLink = '/settings';
export const settingsNotificationsLink = '/settings/notification';
export const accountLink = '/account';
export const favoriteCategoriesLink = '/favorite-categories';
export const recurringDonations = '/recurring-donations';
export const aboutLink = '/about';
export const introductionLink = '/introduction';
export const helpLink = '/help';
export const notificationsLink = '/notifications';

export const publicRoutes = [
  loginLink,
  registerLink,
  resetPasswordLink,
  rootLink,
  introductionLink,
  helpLink,
];
