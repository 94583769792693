import { gql } from '@apollo/client';
import userFragment from './userFragment';

export const createUser: any = gql`
  mutation createUser($input: UserInput!) {
    createUser(input: $input) {
      ...userFragment
    }
  }

  ${userFragment}
`;
