import React from 'react';
import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonGrid,
  IonRow,
  IonCol,
} from '@ionic/react';
import { connect } from 'react-redux';
import { IonContent, IonPage, IonButton } from '@ionic/react';
import { useTranslation } from 'react-i18next';

import { RootState } from '../../models/store';

import AvatarList from '../../components/association/lists/AvatarList';
import Helper from '../../components/Helper';

import { homeLink } from '../../constants/routes';
import GoBack from '../../components/GoBack';

const Following: React.FC<FollowingComponent> = ({ user, associations }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'following',
  });

  const associationIds = (user?.favorites || []).map(
    ({ id }: { id: string }) => id
  );

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <GoBack />
          <IonTitle>{t('Following')}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense" translucent>
          <IonToolbar>
            <IonTitle size="large">
              <IonGrid className="ion-no-padding">
                <IonRow className="ion-align-items-center">
                  <IonCol size="auto">{t('Following')}</IonCol>
                  <IonCol>
                    <Helper helper={t('helper')} />
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonTitle>
          </IonToolbar>
        </IonHeader>
        <div className="page-content">
          {!(user?.favorites || []).length ? (
            <div className="ion-text-center ion-padding">
              <p>{t('You dont follow any charity yet')}</p>
              <IonButton shape="round" routerLink={homeLink}>
                {t('Follow charities')}
              </IonButton>
            </div>
          ) : (
            <AvatarList
              list={(associations?.list || []).filter(({ id }) =>
                associationIds.includes(id)
              )}
            />
          )}
        </div>
      </IonContent>
    </IonPage>
  );
};

const mapState = (state: RootState) => ({
  user: state.user.user,
  associations: state.associations.associations,
});

export default connect(mapState)(Following);
