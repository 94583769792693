import React from 'react';
import { IonToast } from '@ionic/react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { RootState, Dispatch } from '../models/store';

const Toast: React.FC<ToastComponent> = ({ toast, setStateToast }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'toast',
  });

  let message = toast?.message?.code || toast?.message;

  if (!message || typeof message !== 'string' || !message.replace) {
    message = 'An error occured. Please try again later.';
  }

  const messageDisplay = toast?.isNotification
    ? message
    : t(
        message
          ?.replace('GraphQL error: ', '')
          ?.replace('Firebase: Error (', '')
          ?.replace('FirebaseError: Firebase: Error ( ', '')
          ?.replace(').', '')
      );

  return (
    <IonToast
      cssClass="toast"
      color={toast?.type}
      isOpen={toast?.isOpen}
      onDidDismiss={() =>
        setStateToast({
          ...toast,
          isOpen: false,
          isNotification: false,
          duration: 4000,
          type: 'warning',
          header: '',
          message: '',
        })
      }
      header={toast?.header}
      message={messageDisplay}
      position={toast?.position || toast?.isNotification ? 'top' : 'bottom'}
      duration={toast?.duration}
      buttons={[
        {
          text: 'OK',
          role: 'cancel',
        },
      ]}
    />
  );
};

const mapState = (state: RootState) => ({
  toast: state.toast.toast,
});

const mapDispatch = (dispatch: Dispatch) => ({
  setStateToast: dispatch.toast.setStateToast,
});

export default connect(mapState, mapDispatch)(Toast);
