import { gql } from '@apollo/client';
import notificationFragment from './notificationFragment';

export const getNotificationsByUser: any = gql`
  query getNotificationsByUser {
    getNotificationsByUser {
      ...notificationFragment
    }
  }

  ${notificationFragment}
`;
