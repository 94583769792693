import { gql } from '@apollo/client';

export default gql`
  fragment imageFragment on Image {
    sm
    md
    lg
    paletteColor
  }
`;
