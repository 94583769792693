import React, { useEffect, useState } from 'react';
import { IonModal, isPlatform } from '@ionic/react';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { Virtual, Keyboard } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { RootState, Dispatch } from '../../models/store';
import { logEvent } from '../../services/firebaseApp';
import ConnectionContent from './ConnectionContent';
import { userLink } from '../../constants/routes';

import 'swiper/css';
import 'swiper/css/virtual';
import '@ionic/react/css/ionic-swiper.css';
import ModalNavigation from '../../components/ModalNavigation';
import { StatusBar } from '@capacitor/status-bar';

const ConnectionModal: React.FC<ConnectionModalComponent> = ({
  setStateConnection,
  connectionModalList,
  users,
  connection,
}) => {
  const history = useHistory();
  const location = useLocation();
  const [swiper, setSwiper] = useState<any | undefined>(undefined);
  const [list, setList] = useState<Connection[] | null>();
  const [currentIndex, setCurrentIndex] = useState<number | undefined>(
    undefined
  );

  useEffect(() => {
    if (!connectionModalList) {
      setList(users?.list);
      return;
    }
    setList(connectionModalList);
    // eslint-disable-next-line
  }, [connectionModalList, users?.list]);

  useEffect(() => {
    if (!list) {
      return;
    }
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.has('connection')) {
      const connection = (list || []).find(
        ({ id }) => id === queryParams.get('connection')
      );
      setStateConnection(connection);
      logEvent('view_item', {
        content_type: 'connection',
        content_id: connection?.id || 'undefined',
      });
    } else {
      setStateConnection(null);
    }
    // eslint-disable-next-line
  }, [list, connection, location]);

  useEffect(() => {
    if (!connection || !list) {
      return;
    }
    const currentIndex = (list || []).findIndex(
      ({ id }: Connection) => id === connection.id
    );
    setCurrentIndex(currentIndex);
    // eslint-disable-next-line
  }, [connection, list]);

  const detectChange = async (swiper: any) => {
    const currentConnection = (list || [])[swiper.activeIndex];
    history.push(userLink(currentConnection.id));
  };

  const closeModal = () => {
    if (isPlatform('hybrid')) {
      StatusBar.show();
    }
    const queryParams = new URLSearchParams(location.search);
    queryParams.delete('connection');
    history.replace({
      search: queryParams.toString(),
    });
  };

  return (
    <IonModal
      isOpen={!!connection}
      onWillDismiss={closeModal}
      onWillPresent={() => {
        isPlatform('hybrid') && StatusBar.hide();
      }}
      className="content-modal"
    >
      <ModalNavigation closeModal={closeModal} swiper={swiper} />
      <Swiper
        modules={[Virtual, Keyboard]}
        onInit={(swiper) => setSwiper(swiper)}
        onSlideChangeTransitionEnd={detectChange}
        initialSlide={currentIndex}
        keyboard={{ enabled: true }}
        virtual
      >
        {(list || []).map((connection, index) => (
          <SwiperSlide key={index} virtualIndex={index}>
            <ConnectionContent connection={connection} />
          </SwiperSlide>
        ))}
      </Swiper>
    </IonModal>
  );
};

const mapState = (state: RootState) => ({
  users: state.user.users,
  connection: state.connections.connection,
  connectionModalList: state.connections.connectionModalList,
});

const mapDispatch = (dispatch: Dispatch) => ({
  setStateConnection: dispatch.connections.setStateConnection,
});

export default connect(mapState, mapDispatch)(ConnectionModal);
