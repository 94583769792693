import React from 'react';
import {
  IonItem,
  IonNote,
  IonAvatar,
  IonImg,
  IonGrid,
  IonRow,
  IonCol,
  IonRouterLink,
} from '@ionic/react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Dispatch, RootState } from '../../../models/store';
import Follow from '../../Follow';
import { formatDate } from '../../../utils/date';
import { associationLink } from '../../../constants/routes';
import Separator from '../../Separator';

const GridList: React.FC<GridListComponent> = ({
  associations,
  list,
  title,
  seeAllLink,
  onClick,
  setStateAssociationModalList,
}) => {
  const { t } = useTranslation('translation');

  return !(list || []).length ? null : (
    <>
      <Separator
        title={title}
        showButtons={!!seeAllLink && !!list?.length}
        buttons={
          <IonRouterLink
            routerLink={seeAllLink}
            className="transparent button-link"
          >
            {t('See all')}
          </IonRouterLink>
        }
      />
      <IonGrid>
        <IonRow>
          {(list || []).map((association: Association) => (
            <IonCol
              key={`association-${association.id}}`}
              sizeXs="12"
              sizeSm="4"
              sizeLg="3"
              sizeXl="2"
            >
              <IonItem
                className="transparent"
                key={`association-${association.id}}`}
                onClick={() =>
                  onClick
                    ? onClick(association.id)
                    : setStateAssociationModalList(associations?.list)
                }
                routerLink={
                  onClick ? undefined : associationLink(association.code)
                }
                detail={false}
              >
                <IonGrid>
                  <IonRow className="ion-justify-content-center ion-text-center">
                    <IonCol size="auto">
                      <IonAvatar className="ion-no-margin avatar-lg">
                        <IonImg src={association.logo?.sm} alt="" />
                      </IonAvatar>
                    </IonCol>
                  </IonRow>
                  <IonRow className="ion-justify-content-center ion-text-center">
                    <IonCol size="auto">
                      <h5 className="ion-no-margin">{association.name}</h5>
                      {!association?.tagline ? (
                        !association.creationDate ? (
                          ''
                        ) : (
                          <IonNote>
                            Creation: {formatDate(association.creationDate)}
                          </IonNote>
                        )
                      ) : (
                        <IonNote>{association.tagline}</IonNote>
                      )}
                    </IonCol>
                  </IonRow>
                  <IonRow className="ion-justify-content-center ion-text-center">
                    <IonCol size="auto">
                      <Follow
                        type="icon"
                        association={association}
                        color="medium"
                        size="small"
                      />
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonItem>
            </IonCol>
          ))}
        </IonRow>
      </IonGrid>
    </>
  );
};

const mapState = (state: RootState) => ({
  user: state.user.user,
  associations: state.associations.associations,
});

const mapDispatch = (dispatch: Dispatch) => ({
  setStateAssociationModalList:
    dispatch.associations.setStateAssociationModalList,
  setStateAssociationForm: dispatch.associations.setStateAssociationForm,
});

export default connect(mapState, mapDispatch)(GridList);
