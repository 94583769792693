import React, { useEffect } from 'react';
import {
  IonList,
  IonListHeader,
  IonItem,
  IonLabel,
  IonNote,
  IonAvatar,
  IonCard,
} from '@ionic/react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { RootState } from '../models/store';
import { formatDistance } from '../utils/date';
import {
  associationLink,
  categoryLink,
  postLink,
  userLink,
} from '../constants/routes';
import { logEvent } from '../services/firebaseApp';

const SearchResults: React.FC<SearchResultsComponent> = ({
  user,
  posts,
  associations,
  searchValue,
  searchSuggestions,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation('translation', {
    keyPrefix: 'searchResults',
  });
  const currentLanguage = language.split('-')[0];

  useEffect(() => {
    if (!searchValue) {
      return;
    }
    logEvent('view_search_results', {
      content_type: 'search_value',
      content_id: searchValue,
    });
  }, [searchValue]);

  const highlightMatch = (word: string) => {
    const re = new RegExp(searchValue, 'gi');
    return word?.replace(re, `<b>$&</b>`);
  };

  return (
    <>
      {!searchSuggestions?.categories?.length &&
      !searchSuggestions?.associations?.length &&
      !searchSuggestions?.posts?.length &&
      !searchSuggestions?.connections?.length ? (
        <p className="ion-text-center">
          <i>{t('No results found')}</i>
        </p>
      ) : null}
      <IonCard>
        <IonList lines="inset">
          {!searchSuggestions?.connections?.length ? null : (
            <>
              <IonListHeader>
                <IonLabel color="primary">{t('Community')}</IonLabel>
              </IonListHeader>
              {searchSuggestions?.connections.map((connection) => {
                const userConnection = (user.connections || []).find(
                  ({ id }) => connection.id === id
                );

                return (
                  <IonItem
                    key={`post-${connection.id}`}
                    routerLink={userLink(connection.id)}
                    detail={false}
                    className="ion-align-items-cente"
                  >
                    <IonAvatar className="ion-margin-half ion-no-margin-start avatar-md">
                      <img src={connection?.illustration?.sm ?? ''} alt="" />
                    </IonAvatar>
                    <IonLabel>
                      <div
                        className="html search-result"
                        dangerouslySetInnerHTML={{
                          __html: highlightMatch(connection.name),
                        }}
                      />
                    </IonLabel>
                    {!userConnection ? null : (
                      <IonNote>
                        {t('Added DATE', {
                          date: formatDistance(
                            userConnection.dateAdded,
                            currentLanguage
                          ),
                        })}
                      </IonNote>
                    )}
                  </IonItem>
                );
              })}
            </>
          )}
          {!searchSuggestions?.associations?.length ? null : (
            <>
              <IonListHeader>
                <IonLabel color="primary">{t('Associations')}</IonLabel>
              </IonListHeader>
              {searchSuggestions?.associations.map((a) => {
                const association = (associations?.list || []).find(
                  ({ id }: Association) => id === a.id
                );
                return !association ? null : (
                  <IonItem
                    key={`association-${association.id}`}
                    routerLink={associationLink(association.code)}
                    detail={false}
                    className="ion-align-items-center"
                  >
                    <IonAvatar className="ion-margin-half ion-no-margin-start avatar-md">
                      <img src={association?.logo?.sm ?? ''} alt="" />
                    </IonAvatar>
                    <IonLabel>
                      <div
                        className="html search-result"
                        dangerouslySetInnerHTML={{
                          __html: highlightMatch(association.name),
                        }}
                      />
                    </IonLabel>
                  </IonItem>
                );
              })}
            </>
          )}
          {!searchSuggestions?.posts?.length ? null : (
            <>
              <IonListHeader>
                <IonLabel color="primary">{t('Posts')}</IonLabel>
              </IonListHeader>
              {searchSuggestions?.posts.map((a) => {
                const post = (posts?.list || []).find(({ id }) => id === a.id);
                return !post ? null : (
                  <IonItem
                    key={`post-${post.id}`}
                    routerLink={postLink(post.id)}
                    detail={false}
                    className="ion-align-items-center"
                  >
                    <IonAvatar className="ion-margin-half ion-no-margin-start avatar-md">
                      <img src={post?.illustration?.sm ?? ''} alt="" />
                    </IonAvatar>
                    <IonLabel>
                      <div
                        className="html search-result"
                        dangerouslySetInnerHTML={{
                          __html: highlightMatch(post.title),
                        }}
                      />
                    </IonLabel>
                    <IonNote>
                      {t('Added DATE', {
                        date: formatDistance(
                          post.creationDate,
                          currentLanguage
                        ),
                      })}
                    </IonNote>
                  </IonItem>
                );
              })}
            </>
          )}
          {!searchSuggestions?.categories?.length ? null : (
            <>
              <IonListHeader>
                <IonLabel color="primary">{t('Categories')}</IonLabel>
              </IonListHeader>
              {searchSuggestions?.categories.map((category) => (
                <IonItem
                  key={`association-${category.id}`}
                  routerLink={categoryLink(category.code)}
                  detail={false}
                  className="ion-align-items-center"
                >
                  <IonAvatar className="ion-margin-half ion-no-margin-start avatar-md">
                    <img src={category?.illustration?.sm ?? ''} alt="" />
                  </IonAvatar>
                  <IonLabel>
                    <div
                      className="html search-result"
                      dangerouslySetInnerHTML={{
                        __html: highlightMatch(
                          category?.[`name_${currentLanguage}`] ??
                            category?.name
                        ),
                      }}
                    />
                  </IonLabel>
                  <IonNote>
                    {t('NUMBER associations', {
                      number: category.numberOfAssociations,
                    })}
                  </IonNote>
                </IonItem>
              ))}
            </>
          )}
        </IonList>
      </IonCard>
    </>
  );
};

const mapState = (state: RootState) => ({
  user: state.user.user,
  posts: state.posts.posts,
  associations: state.associations.associations,
  searchSuggestions: state.search.suggestions,
  searchValue: state.search.value,
});

export default connect(mapState)(SearchResults);
