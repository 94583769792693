import * as Sentry from '@sentry/react';

import { removeTypenames } from '../utils/graphql';

import client from '../apollo/client';

import { getPosts } from '../graphql/post/getPosts';
import { getPost } from '../graphql/post/getPost';

import { addPost } from '../graphql/post/addPost';
import { editPost } from '../graphql/post/editPost';
import { deletePost } from '../graphql/post/deletePost';
import { addPostPicture } from '../graphql/post/addPostPicture';
import { addPostPictureBase64 as addPostPictureBase64Query } from '../graphql/post/addPostPictureBase64';
import { publishPost as publishPostQuery } from '../graphql/post/publishPost';
import { likePost as likePostQuery } from '../graphql/post/likePost';
import { unlikePost as unlikePostQuery } from '../graphql/post/unlikePost';

export async function getAllPosts({
  force = false,
}: {
  force?: boolean;
}): Promise<{ posts: Post[] }> {
  try {
    const {
      data: { getPosts: posts },
    } = await client.query({
      query: getPosts,
      fetchPolicy: force ? 'no-cache' : 'network-only',
    });

    return {
      posts: removeTypenames(posts),
    };
  } catch (e: any) {
    Sentry.captureException(e);
    throw new Error();
  }
}

export async function getOnePost(variables: any): Promise<{ post: Post }> {
  try {
    const {
      data: { getPost: post },
    } = await client.query({
      query: getPost,
      variables,
      fetchPolicy: 'no-cache',
    });

    return { post: removeTypenames(post) };
  } catch (e: any) {
    Sentry.captureException(e);
    throw new Error();
  }
}

export async function createPost(variables: any): Promise<{ post: Post }> {
  try {
    const {
      data: { addPost: post },
    } = await client.query({
      query: addPost,
      variables,
      fetchPolicy: 'no-cache',
    });

    return { post: removeTypenames(post) };
  } catch (e: any) {
    Sentry.captureException(e);
    throw new Error();
  }
}

export async function updatePost(variables: any): Promise<{ post: Post }> {
  try {
    const {
      data: { editPost: post },
    } = await client.query({
      query: editPost,
      variables,
      fetchPolicy: 'no-cache',
    });

    return { post: removeTypenames(post) };
  } catch (e: any) {
    Sentry.captureException(e);
    throw new Error();
  }
}

export async function removePost(variables: any): Promise<{ post: Post }> {
  try {
    const {
      data: { deletePost: post },
    } = await client.query({
      query: deletePost,
      variables,
      fetchPolicy: 'no-cache',
    });

    return { post: removeTypenames(post) };
  } catch (e: any) {
    Sentry.captureException(e);
    throw new Error();
  }
}

export async function editPostPicture(variables: any): Promise<{
  file: { locations: Image; message: string; success: boolean };
  post: Post;
}> {
  try {
    const {
      data: {
        addPostPicture: { file, post },
      },
    } = await client.query({
      query: addPostPicture,
      variables,
      fetchPolicy: 'no-cache',
    });

    return {
      file: removeTypenames(file),
      post: removeTypenames(post),
    };
  } catch (e: any) {
    Sentry.captureException(e);
    throw new Error(e);
  }
}

export async function editPostPictureBase64(variables: any): Promise<{
  file: { locations: Image; message: string; success: boolean };
  post: Post;
}> {
  try {
    const {
      data: {
        addPostPictureBase64: { file, post },
      },
    } = await client.query({
      query: addPostPictureBase64Query,
      variables,
      fetchPolicy: 'no-cache',
    });

    return {
      file: removeTypenames(file),
      post: removeTypenames(post),
    };
  } catch (e: any) {
    Sentry.captureException(e);
    throw new Error(e);
  }
}

export async function publishPost(variables: { id: string }): Promise<Post> {
  try {
    const {
      data: { publishPost: association },
    } = await client.query({
      query: publishPostQuery,
      variables,
      fetchPolicy: 'no-cache',
    });

    return removeTypenames(association);
  } catch (e) {
    Sentry.captureException(e);
    throw new Error();
  }
}

export async function likePost(variables: { id: string }): Promise<Post[]> {
  try {
    const {
      data: { likePost: posts },
    } = await client.mutate({
      mutation: likePostQuery,
      variables,
      fetchPolicy: 'no-cache',
    });
    return removeTypenames(posts);
  } catch (e: any) {
    Sentry.captureException(e);
    throw new Error();
  }
}

export async function unlikePost(variables: { id: string }): Promise<Post[]> {
  try {
    const {
      data: { unlikePost: posts },
    } = await client.mutate({
      mutation: unlikePostQuery,
      variables,
      fetchPolicy: 'no-cache',
    });
    return removeTypenames(posts);
  } catch (e: any) {
    Sentry.captureException(e);
    throw new Error();
  }
}
