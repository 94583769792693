import React from 'react';
import { IonButton, IonText, useIonPopover, useIonAlert } from '@ionic/react';

import { FiInfo } from 'react-icons/fi';

const PopoverHelp: React.FC<{
  onHide: () => void;
  helper: string;
}> = ({ onHide, helper }) => {
  return (
    <IonText color="medium">
      <p className="ion-padding-horizontal">{helper}</p>
    </IonText>
  );
};

const Helper: React.FC<any> = ({ helper }) => {
  const [present, dismiss] = useIonPopover(PopoverHelp, {
    onHide: () => dismiss(),
    helper,
  });
  const [presentHelper] = useIonAlert();

  return !helper ? null : (
    <IonButton
      fill="clear"
      size="small"
      className="ion-no-margin"
      onClick={(e) => {
        presentHelper({
          message: helper,
        });
        /* present({
          event: e.nativeEvent,
        }) */
      }}
    >
      <FiInfo />
    </IonButton>
  );
};

export default Helper;
