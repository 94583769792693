import React from 'react';
import {
  IonGrid,
  IonCol,
  IonRow,
  IonCard,
  IonAvatar,
  IonText,
  IonButton,
  IonItem,
  IonImg,
  IonLabel,
  IonCardHeader,
} from '@ionic/react';

import {
  associationLink,
  dynamicLink,
  postLink,
  postsLink,
} from '../../../constants/routes';
import Share from '../../Share';
import { useTranslation } from 'react-i18next';
import Follow from '../../Follow';
import Donate from '../../Donate';
import { formatDistance } from '../../../utils/date';
import Like from '../../Like';

const Card: React.FC<PostCardComponent> = ({ item: post, className = '' }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation('translation', {
    keyPrefix: 'posts',
  });
  const currentLanguage = language.split('-')[0];

  return (
    <IonCard
      key={`post-${post?.id}`}
      className={`pointer ${className}`}
      routerLink={postLink(post?.id)}
    >
      <div className="background top-background"></div>
      <IonCardHeader>
        <IonGrid className="ion-no-padding">
          <IonRow className="ion-padding-horizontal">
            <IonCol>
              <IonItem
                className="transparent"
                routerLink={associationLink(post.association.code)}
                detail={false}
              >
                <IonAvatar slot="start" className="avatar-sm ion-margin-end">
                  <IonImg src={post.association?.logo?.sm} alt="" />
                </IonAvatar>
                <IonLabel className="ion-color-white b">
                  <h5 className="ion-no-margin b">{post.association?.name}</h5>
                  <small>
                    <sup>
                      {t('DATE by USER', {
                        date: formatDistance(
                          post.creationDate,
                          currentLanguage
                        ),
                        user: post?.user?.name || 'Anonymous',
                      })}
                    </sup>
                  </small>
                </IonLabel>
              </IonItem>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonCardHeader>
      <IonImg src={`${post?.illustration?.md}`} alt="" />
      <IonGrid className="ion-no-padding">
        <IonRow className="ion-no-padding ion-justify-content-end">
          <IonCol size="auto" className="ion-no-padding">
            <Share
              type="icon"
              fill="clear"
              size="large"
              title={post.title}
              buttonText={t('Share this post')}
              url={`${dynamicLink}${postsLink}${postLink(post.id)}`}
            />
            <Like
              type="icon"
              fill="clear"
              size="large"
              post={post}
              className="ion-no-padding"
            />
            <Donate
              type="icon"
              fill="clear"
              size="large"
              association={post.association}
              className="ion-no-padding"
            />
          </IonCol>
        </IonRow>
        <IonRow className="ion-padding ion-no-padding-top">
          <IonCol>
            <h5>{post?.title}</h5>
            <small>{`${post?.content.slice(0, 70)}...`}</small>
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonCard>
  );
};

export default Card;
