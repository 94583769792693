import React from 'react';
import {
  IonList,
  IonItem,
  IonLabel,
  IonButtons,
  IonNote,
  IonAvatar,
  IonImg,
  IonCard,
  IonRouterLink,
  IonButton,
} from '@ionic/react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Dispatch, RootState } from '../../../models/store';
import Follow from '../../Follow';
import { formatDate } from '../../../utils/date';
import {
  associationFormLink,
  associationLink,
} from '../../../constants/routes';
import {
  publishAssociation,
  starAssociation,
} from '../../../services/associationService';
import { FaEdit, FaEye, FaEyeSlash, FaRegStar, FaStar } from 'react-icons/fa';
import Separator from '../../Separator';

const AvatarList: React.FC<AvatarListComponent> = ({
  user,
  list,
  title,
  helper,
  seeAllLink,
  setStateAssociationForm,
  setStateAssociationModalList,
}) => {
  const { t } = useTranslation('translation');

  const star = async (e: any, id: string) => {
    e.preventDefault();
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    const { isStared } = await starAssociation({ id });
  };

  const publish = async (e: any, id: string) => {
    e.preventDefault();
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    const { published } = await publishAssociation({ id });
  };

  return !(list || []).length ? null : (
    <>
      <Separator
        title={title}
        helper={helper}
        showButtons={!!seeAllLink && !!list?.length}
        buttons={
          <IonRouterLink
            routerLink={seeAllLink}
            className="transparent button-link"
          >
            {t('See all')}
          </IonRouterLink>
        }
      />
      <IonCard>
        <IonList lines="inset">
          {(list || []).map((association: Association) => (
            <IonItem
              key={`association-${association.id}}`}
              onClick={() => setStateAssociationModalList(list)}
              routerLink={associationLink(association.code)}
              detail={false}
            >
              <IonAvatar
                slot="start"
                className="ion-margin ion-no-margin-start avatar-md"
              >
                <IonImg src={association.logo?.sm} alt="" />
              </IonAvatar>
              <IonLabel>
                <h5 className="ion-no-margin">{association.name}</h5>
                {!association?.tagline ? (
                  !association.creationDate ? (
                    ''
                  ) : (
                    <IonNote>
                      Creation: {formatDate(association.creationDate)}
                    </IonNote>
                  )
                ) : (
                  <IonNote>{association.tagline}</IonNote>
                )}
              </IonLabel>
              <IonButtons slot="end" className="ion-no-margin">
                <Follow
                  type="icon"
                  association={association}
                  color="medium"
                  size="small"
                />
                {!user?.info?.admin ? null : (
                  <>
                    <IonButton
                      shape="round"
                      size="small"
                      color={association?.published ? 'primary' : 'medium'}
                      onClick={(e) => publish(e, association.id)}
                    >
                      {association?.published ? <FaEye /> : <FaEyeSlash />}
                    </IonButton>
                    <IonButton shape="round" size="small">
                      <IonRouterLink
                        routerLink={associationFormLink(association.code)}
                        color="medium"
                        onClick={() => setStateAssociationForm(association)}
                      >
                        <FaEdit />
                      </IonRouterLink>
                    </IonButton>
                    <IonButton
                      shape="round"
                      size="small"
                      color={association?.isStared ? 'primary' : 'medium'}
                      onClick={(e) => star(e, association.id)}
                    >
                      {association?.isStared ? <FaStar /> : <FaRegStar />}
                    </IonButton>
                  </>
                )}
              </IonButtons>
            </IonItem>
          ))}
        </IonList>
      </IonCard>
    </>
  );
};

const mapState = (state: RootState) => ({
  user: state.user.user,
});

const mapDispatch = (dispatch: Dispatch) => ({
  setStateAssociationModalList:
    dispatch.associations.setStateAssociationModalList,
  setStateAssociationForm: dispatch.associations.setStateAssociationForm,
});

export default connect(mapState, mapDispatch)(AvatarList);
