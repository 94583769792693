import gql from 'graphql-tag';
import postFragment from './postFragment';

export const getPost = gql`
  query getPost($id: ID!) {
    getPost(id: $id) {
      ...postFragment
    }
  }

  ${postFragment}
`;
