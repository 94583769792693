import React, { useState } from 'react';
import {
  IonButton,
  IonInput,
  IonModal,
  IonContent,
  IonItem,
  IonGrid,
  IonRow,
  IonCol,
} from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';

import { showError } from '../utils/form';

import { LoginModalProps, useAuth } from '../types';

type FormValues = {
  email: string;
  password: string;
};

type Props = LoginModalProps & {
  useAuth: typeof useAuth;
};

const LoginModal: React.FC<Props> = ({
  useAuth,
  isOpen,
  setIsOpen,
  isLogin,
  beforeLogin,
  onLoggedIn,
  onError,
  gotoResetPassword,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'login',
  });
  const { signIn, signUp } = useAuth()!;
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<FormValues>();
  const [isSignUp, setIsSignUp] = useState<boolean>(!isLogin);

  const onSubmit: SubmitHandler<FormValues> = async ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => {
    isSignUp
      ? await signUp(email, password, beforeLogin, onLoggedIn, onError)
      : await signIn(email, password, beforeLogin, onLoggedIn, onError);
    setIsOpen(false);
  };

  return (
    <IonModal
      isOpen={isOpen}
      breakpoints={[0.1, 0.5, 0.7, 1]}
      initialBreakpoint={0.5}
      onDidDismiss={() => setIsOpen(false)}
      data-testid="login-modal"
    >
      <IonContent className="ion-padding">
        <IonGrid className="ion-padding ion-text-center">
          <IonRow>
            <IonCol>
              <h1 className="ion-margin">
                {t(`${isSignUp ? 'Create a free account' : 'Sign in'}_TITLE`)}
              </h1>
            </IonCol>
          </IonRow>
          <IonRow className="ion-justify-content-center">
            <IonCol sizeSm="8" sizeLg="6">
              <form onSubmit={handleSubmit(onSubmit)}>
                <IonItem>
                  <Controller
                    render={({ field: { onChange } }) => (
                      <IonInput
                        type="email"
                        aria-label="email-input"
                        placeholder={t('Email')}
                        autocomplete="email"
                        onIonChange={(e) => {
                          onChange(e.detail.value);
                        }}
                      />
                    )}
                    name="email"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: 'Please enter your email address',
                      },
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        message: 'Please verify your email address',
                      },
                    }}
                  />
                  {showError(errors, 'email')}
                </IonItem>
                <IonItem>
                  <Controller
                    render={({ field: { onChange } }) => (
                      <IonInput
                        type="password"
                        placeholder={t('Password')}
                        aria-label="password-input"
                        onIonChange={(e) => {
                          onChange(e.detail.value);
                        }}
                      />
                    )}
                    name="password"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: 'Please enter your password',
                      },
                    }}
                  />
                  {showError(errors, 'password')}
                </IonItem>
                <IonButton
                  role="button"
                  expand="block"
                  shape="round"
                  className="ion-margin"
                  type="submit"
                >
                  {t(
                    `${isSignUp ? 'Create a free account' : 'Sign in'}_BUTTON`
                  )}
                </IonButton>
                {/* https://github.com/ionic-team/ionic-framework/issues/19368 */}
                <input
                  type="submit"
                  className="submit-enter"
                  data-testid="submit-button"
                />
              </form>
            </IonCol>
          </IonRow>

          <IonRow className="ion-padding-top">
            <IonCol>
              {isSignUp ? (
                <small>
                  <span
                    className="pointer u ion-padding-start-half"
                    onClick={() => setIsSignUp(false)}
                    data-testid="switch-to-signin"
                  >
                    {t('Sign in')}
                  </span>
                </small>
              ) : (
                <small>
                  <span
                    className="pointer u ion-padding-start-half"
                    onClick={() => setIsSignUp(true)}
                    data-testid="switch-to-signup"
                  >
                    {t('Create an account')}
                  </span>
                </small>
              )}
            </IonCol>
          </IonRow>
          {!gotoResetPassword ? null : (
            <IonRow className="ion-padding-bottom">
              <IonCol>
                <small>
                  <span
                    className="pointer u"
                    onClick={() => {
                      gotoResetPassword();
                      setIsOpen(false);
                    }}
                  >
                    {t('Password forgotten')}
                  </span>
                </small>
              </IonCol>
            </IonRow>
          )}
        </IonGrid>
      </IonContent>
    </IonModal>
  );
};

export default LoginModal;
