import React, { useEffect, useState } from 'react';
import {
  IonList,
  IonItem,
  IonLabel,
  IonAvatar,
  IonImg,
  IonCard,
  IonButtons,
  IonGrid,
  IonRow,
  IonCol,
  IonSpinner,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonNote,
  IonButton,
} from '@ionic/react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { generateHSL } from '../../../utils/generateRandomColor';
import { userLink } from '../../../constants/routes';
import { Dispatch, RootState } from '../../../models/store';
import AddConnection from '../../../components/AddConnection';
import Helper from '../../../components/Helper';
import { formatDistance } from '../../../utils/date';
import { ACCEPTED, PENDING } from '../../../constants/connections';
import { deleteUser, getUsers } from '../../../services/userService';
import { FaEye, FaEyeSlash, FaTrash, FaTrashRestore } from 'react-icons/fa';
import Confirm from '../../../components/Confirm';
import Separator from '../../../components/Separator';

const UsersAvatarList: React.FC<ConnectionsListComponent> = ({
  user,
  loading,
  type,
  list,
  title,
  helper,
  showStatus,
  showDetails,
  showButtons,
  setStateConnectionModalList,
  setStateUsers,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation('translation', {
    keyPrefix: 'community',
  });
  const currentLanguage = language.split('-')[0];
  const [data, setData] = useState<any[]>([]);
  const [isInfiniteDisabled, setInfiniteDisabled] = useState<boolean>(false);

  useEffect(() => {
    if (!list?.length || data.length) {
      return;
    }
    pushData();
    // eslint-disable-next-line
  }, [list, type]);

  const pushData = (ev?: any) => {
    const max = data.length + 10;
    const min = max - 10;
    const newData = [];
    for (let i = min; i < max; i++) {
      if (list?.[i]) {
        newData.push(list[i]);
      }
    }

    setData([...data, ...newData]);
    ev?.target?.complete();
    if (data.length === list?.length) {
      setInfiniteDisabled(true);
    }
  };

  const deleteThisUser = async (e: any, id: string) => {
    e.preventDefault();
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    await deleteUser({ id });
    const { users: list } = await getUsers();
    setStateUsers({ list, status: '' });
  };

  return (
    <>
      <Separator title={title} count={list?.length} helper={helper} />
      {loading ? (
        <IonGrid className="ion-padding">
          <IonRow className="ion-justify-content-center">
            <IonCol size="auto">
              <IonSpinner color="primary" />
            </IonCol>
          </IonRow>
        </IonGrid>
      ) : !(list || []).length ? (
        <div className="ion-text-center ion-padding">
          <p>
            {t(
              type === 'connections'
                ? 'You dont have connections yet'
                : 'No results found'
            )}
          </p>
        </div>
      ) : (
        <IonCard>
          <IonList lines="inset">
            {(list || []).map((connection) => (
              <IonItem
                key={`connection-${type}-${connection.id}}`}
                onClick={() => setStateConnectionModalList(list)}
                routerLink={userLink(connection.id)}
                detail={!showButtons}
              >
                <IonAvatar
                  slot="start"
                  className="ion-no-margin-start avatar-md"
                  style={{
                    background: generateHSL(
                      (connection?.name || connection?.email) ?? 'User'
                    ),
                  }}
                >
                  {connection?.illustration ? (
                    <IonImg
                      src={connection.illustration.sm}
                      alt={connection?.name}
                    />
                  ) : (
                    <b>
                      {((connection?.name || connection?.email) ?? 'User')
                        .slice(0, 1)
                        .toUpperCase()}
                    </b>
                  )}
                </IonAvatar>
                <IonLabel>
                  <h5 className="ion-no-margin">{connection.name}</h5>
                  <IonNote>
                    {connection.approved !== PENDING
                      ? connection.tagline ||
                        (connection?.creationDate &&
                          t('Member since DATE', {
                            date: formatDistance(
                              connection?.creationDate,
                              currentLanguage,
                              false
                            ),
                          }))
                      : t(connection.approved)}
                  </IonNote>
                </IonLabel>
                {!showButtons ? null : (
                  <IonButtons slot="end" className="ion-no-margin">
                    <AddConnection
                      connection={connection}
                      size="small"
                      short={true}
                      type={showDetails ? 'button' : 'icon'}
                      showStatus={showStatus}
                      showDetails={showDetails}
                      only={[PENDING, ACCEPTED]}
                    />
                  </IonButtons>
                )}

                {!user?.info?.admin ? null : (
                  <IonButtons slot="end" className="ion-no-margin">
                    <IonButton
                      shape="round"
                      size="small"
                      color="medium"
                      disabled
                    >
                      {!connection?.userDeleted ? <FaEye /> : <FaEyeSlash />}
                    </IonButton>
                    <Confirm
                      confirm={(e: any) => deleteThisUser(e, connection.id)}
                      headerText={t('Delete this user')}
                      confirmText={t('Delete')}
                    >
                      <IonButton shape="round" size="small" color="medium">
                        {!connection?.deleted ? (
                          <FaTrash />
                        ) : (
                          <FaTrashRestore />
                        )}
                      </IonButton>
                    </Confirm>
                  </IonButtons>
                )}
              </IonItem>
            ))}
          </IonList>
        </IonCard>
      )}
      <IonInfiniteScroll onIonInfinite={pushData} disabled={isInfiniteDisabled}>
        <IonInfiniteScrollContent></IonInfiniteScrollContent>
      </IonInfiniteScroll>
    </>
  );
};

const mapState = (state: RootState) => ({
  user: state.user.user,
});

const mapDispatch = (dispatch: Dispatch) => ({
  setStateUsers: dispatch.user.setStateUsers,
  setStateConnectionModalList: dispatch.connections.setStateConnectionModalList,
});

export default connect(mapState, mapDispatch)(UsersAvatarList);
