import { gql } from '@apollo/client';
import userFragment from './userFragment';

export const editUser: any = gql`
  mutation editUser($input: UserInput!) {
    editUser(input: $input) {
      ...userFragment
    }
  }

  ${userFragment}
`;
