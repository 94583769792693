import React, { useState } from 'react';
import { IonActionSheet, IonButton } from '@ionic/react';
import { useTranslation } from 'react-i18next';

import { LogoutProps, useAuth } from '../types';

type Props = LogoutProps & {
  useAuth: typeof useAuth;
};

const Logout: React.FC<Props> = ({
  useAuth,
  children,
  beforeLogout,
  onLoggedOut,
  onError,
  expand,
  shape,
  color,
  size,
  fill,
  className,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'account',
  });
  const { user, logOut } = useAuth()!;
  const [showAlert, setShowAlert] = useState<boolean>(false);

  return !user ? null : (
    <>
      <IonButton
        expand={expand || undefined}
        color={color || 'default'}
        size={size || 'default'}
        fill={fill || 'default'}
        shape={shape || 'round'}
        onClick={() => setShowAlert(true)}
        className={className}
        data-testid="logout-button"
      >
        {children || t('Logout')}
      </IonButton>
      <IonActionSheet
        data-testid="logout-alert"
        isOpen={showAlert}
        onDidDismiss={() => setShowAlert(false)}
        header={t('Are you going?')}
        buttons={[
          {
            text: t('Log me out'),
            role: 'destructive',
            data: {
              type: 'delete',
            },
            handler: async () => {
              try {
                await logOut(beforeLogout, onLoggedOut);
              } catch (e) {
                onError && onError(e);
              }
            },
          },
          {
            text: t('Cancel'),
            role: 'cancel',
          },
        ]}
      />
    </>
  );
};

export default Logout;
