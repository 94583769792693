import { isPlatform } from '@ionic/react';
import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token,
} from '@capacitor/push-notifications';
import { FCM } from '@capacitor-community/fcm';
import {
  getMessaging,
  isSupported as isSupportedMessaging,
  onMessage,
  getToken as getTokenFirebase,
  Messaging,
} from '@firebase/messaging';

const isSupportedNotification = () =>
  'Notification' in window &&
  'serviceWorker' in navigator &&
  'PushManager' in window;

export const addListeners = (
  setNotificationAccepted: Function,
  setNotificationReceived: Function
): Promise<{ eventName: string; object: any }> | undefined => {
  if (!isPlatform('hybrid')) {
    return;
  }
  PushNotifications.addListener('registration', async (token: Token) => {
    setNotificationAccepted(true);
    return { eventName: 'registration', token: token.value };
  });

  PushNotifications.addListener('registrationError', (error: any) => {
    setNotificationAccepted(false);
    console.log('Error on registration: ' + JSON.stringify(error));
    return { eventName: 'registrationError', error };
  });

  PushNotifications.addListener(
    'pushNotificationReceived',
    (notification: PushNotificationSchema) => {
      console.log('ok');
      setNotificationReceived(notification);
      return { eventName: 'pushNotificationReceived', notification };
    }
  );

  PushNotifications.addListener(
    'pushNotificationActionPerformed',
    (notification: ActionPerformed) => {
      setNotificationReceived(null);
      console.log('Push action performed: ' + JSON.stringify(notification));
      return {
        eventName: 'pushNotificationActionPerformed',
        notification: null,
      };
    }
  );
};

export const removeAllListeners = () => {
  if (!isPlatform('hybrid')) {
    return;
  }
  return PushNotifications?.removeAllListeners();
};

export const requestPermissions = PushNotifications.requestPermissions;

export const register = PushNotifications.register;

export const checkPermissions = PushNotifications.checkPermissions;

export const checkNotificationsAccepted = async (): Promise<boolean> => {
  if (!isPlatform('hybrid')) {
    return isSupportedNotification() && Notification?.permission === 'granted';
  } else {
    const permStatus = await checkPermissions();
    return permStatus.receive === 'granted';
  }
};

export const getToken = async (
  messaging?: Messaging | null,
  vapidKey?: string
): Promise<string | undefined> => {
  if (isPlatform('hybrid')) {
    const { token } = await FCM.getToken();
    return token;
  }
  return messaging && vapidKey
    ? await getTokenFirebase(messaging, { vapidKey })
    : undefined;
};
