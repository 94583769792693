import { Position } from '@capacitor/geolocation';

export const cleanupCoordinates = (coordinates: Position) => ({
  timestamp: coordinates?.timestamp,
  coords: {
    accuracy: coordinates?.coords?.accuracy,
    altitude: coordinates?.coords?.altitude,
    altitudeAccuracy: coordinates?.coords?.altitudeAccuracy,
    heading: coordinates?.coords?.heading,
    latitude: coordinates?.coords?.latitude,
    longitude: coordinates?.coords?.longitude,
    speed: coordinates?.coords?.speed,
  },
});
