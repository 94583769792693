import React from 'react';
import { IonContent, IonPage, IonGrid, IonRow, IonCol } from '@ionic/react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { ResetPasswordForm } from 'authentication';

import { Dispatch } from '../../models/store';
import { loginLink } from '../../constants/routes';
import { useHistory } from 'react-router';

const ResetPassword: React.FC<ResetPasswordComponent> = ({ setStateToast }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'resetPassword',
  });
  const history = useHistory();

  const gotoSignIn = () => history.replace(loginLink);

  return (
    <IonPage className="reset-password">
      <IonContent fullscreen>
        <IonGrid className="full-height ion-justify-content-center ion-text-center">
          <IonRow className="row-grow ion-justify-content-center ion-align-items-end">
            <IonCol size="auto">
              <img src="/charitable.png" alt="" style={{ width: '100px' }} />
              <h1 className="main-title">Charitable</h1>
            </IonCol>
          </IonRow>
          <IonRow className="ion-justify-content-center ion-align-items-end">
            <IonCol size="auto">
              <h2 className="ion-margin-vertical b">
                {t('Reset my password')}
              </h2>
            </IonCol>
          </IonRow>
          <IonRow className="ion-justify-content-center">
            <IonCol size="auto">
              <ResetPasswordForm
                onSuccess={(email: string) =>
                  setStateToast({
                    isOpen: true,
                    message:
                      'An email has been sent with the instruction to follow',
                  })
                }
              />
            </IonCol>
          </IonRow>
          <IonRow className="ion-padding-vertical">
            <IonCol>
              <small>
                {t('Already know your password?')}
                <br />
                <span
                  className="pointer u ion-padding-start-half"
                  onClick={gotoSignIn}
                >
                  {t('Login')}
                </span>
              </small>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

const mapDispatch = (dispatch: Dispatch) => ({
  setStateToast: dispatch.toast.setStateToast,
});

export default connect(null, mapDispatch)(ResetPassword);
