import { gql } from '@apollo/client';
import categoryFragment from './categoryFragment';

export const getCategories: any = gql`
  query getCategories {
    getCategories {
      ...categoryFragment
    }
  }

  ${categoryFragment}
`;
