import { gql } from '@apollo/client';
import imageFragment from '../imageFragment';
import categoryFragment from '../category/categoryFragment';
import addressFragment from '../user/addressFragment';

export default gql`
  fragment associationFragment on Association {
    id
    published
    name
    code
    isStared
    usesInternalPayment
    tagline
    description
    creationDate
    website
    donateUrl
    address {
      ...addressFragment
    }
    categories {
      ...categoryFragment
    }
    countries {
      code
      name
    }
    logo {
      ...imageFragment
    }
    illustration {
      ...imageFragment
    }
    banner {
      ...imageFragment
    }
    poster {
      ...imageFragment
    }
    user {
      id
      name
    }
    likesCount
    donationsCount
    postsCount
    dateAdded
  }

  ${imageFragment}
  ${categoryFragment}
  ${addressFragment}
`;
