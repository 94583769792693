import * as Sentry from '@sentry/react';

import { removeTypenames } from '../utils/graphql';

import client from '../apollo/client';

import { searchAnything as searchAnythingQuery } from '../graphql/search/searchAnything';
import { getSearchSuggestions as getSearchSuggestionsQuery } from '../graphql/search/getSearchSuggestions';

export async function searchAnything(variables: {
  type: string;
  searchValue: string;
}): Promise<{
  categories: Category[];
  posts: Category[];
  associations: Category[];
  connections: Category[];
  message?: string;
}> {
  try {
    const {
      data: { searchAnything: searchResults },
    } = await client.query({
      query: searchAnythingQuery,
      variables,
      fetchPolicy: 'no-cache',
    });

    return removeTypenames(searchResults);
  } catch (e: any) {
    Sentry.captureException(e);
    return {
      categories: [],
      posts: [],
      associations: [],
      connections: [],
      message: e.message,
    };
  }
}

export async function getSearchSuggestions(variables: {
  type: string;
  searchValue: string;
}): Promise<{
  categories: Category[];
  posts: Category[];
  associations: Category[];
  connections: Category[];
  message?: string;
}> {
  try {
    const {
      data: { getSearchSuggestions: searchResults },
    } = await client.query({
      query: getSearchSuggestionsQuery,
      variables,
    });

    return removeTypenames(searchResults);
  } catch (e: any) {
    Sentry.captureException(e);
    return {
      categories: [],
      posts: [],
      associations: [],
      connections: [],
      message: e.message,
    };
  }
}
