// eslint-disable-next-line
import React, { Component, ErrorInfo, ReactNode } from 'react';
import * as Sentry from '@sentry/react';
import { IonCol, IonGrid, IonRow, IonButton } from '@ionic/react';
import { homeLink } from '../constants/routes';

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo);
    if (typeof Sentry !== 'undefined') {
      Sentry.withScope((scope) => {
        scope.setExtra('error', error);
        scope.setExtra('errorInfo', errorInfo);
        scope.setExtra('whitePage', 'Yes');
        Sentry.captureException(error);
      });
    }
  }

  public render() {
    if (this.state.hasError) {
      return (
        <IonGrid>
          <IonRow
            className="ion-align-items-center"
            style={{ height: '100vh' }}
          >
            <IonCol className="ion-text-center">
              <p>Sorry... there was an error</p>
              <IonButton shape="round" routerLink={homeLink}>
                Reload
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
