import { gql } from '@apollo/client';

export const countFavoritesByCategories: any = gql`
  query countFavoritesByCategories {
    countFavoritesByCategories {
      categoryName_en
      categoryName_fr
      count
    }
  }
`;
