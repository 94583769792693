import { gql } from '@apollo/client';

export default gql`
  fragment addressFragment on Address {
    street
    number
    postcode
    city
    country
  }
`;
