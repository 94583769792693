import React from 'react';
import { IonButton } from '@ionic/react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { logEvent } from '../services/firebaseApp';
import { RootState, Dispatch } from '../models/store';
import Confirm from './Confirm';
import { FaBookmark, FaRegBookmark } from 'react-icons/fa';

const Follow: React.FC<FollowComponent> = ({
  association,
  type,
  color,
  expand,
  size,
  fill,
  shape,
  className,
  user,
  toggleStateFollowing,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'follow',
  });

  const addFavorite = (e: MouseEvent | any) => {
    e.preventDefault();
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();

    toggleStateFollowing(association);

    if (isInFavorites()) {
      logEvent('follow', {
        content_type: 'remove',
        content_id: association.code,
      });
    } else {
      logEvent('follow', {
        content_type: 'add',
        content_id: association.code,
      });
    }
  };

  const isInFavorites = () =>
    (user?.favorites || [])
      .map(({ id }: Association) => id)
      .includes(association.id);

  return (
    <Confirm
      confirm={(e: any) => isInFavorites() && addFavorite(e)}
      confirmText={t('Unfollow')}
    >
      <IonButton
        className={`type-${type} ${className}`}
        onClick={(e) => !isInFavorites() && addFavorite(e)}
        color={color || 'primary'}
        size={size || 'default'}
        fill={fill || 'default'}
        shape={shape || undefined}
        expand={expand || undefined}
      >
        {isInFavorites() ? (
          <>
            <FaBookmark className={type !== 'icon' ? 'ion-margin-end' : ''} />
            {type === 'icon' ? null : t('Unfollow')}
          </>
        ) : (
          <>
            <FaRegBookmark
              className={type !== 'icon' ? 'ion-margin-end' : ''}
            />
            {type === 'icon' ? null : t('Follow')}
          </>
        )}
      </IonButton>
    </Confirm>
  );
};

const mapState = (state: RootState) => ({
  user: state.user.user,
});

const mapDispatch = (dispatch: Dispatch) => ({
  toggleStateFollowing: dispatch.user.toggleStateFollowing,
});

export default connect(mapState, mapDispatch)(Follow);
