import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  IonContent,
  IonCol,
  IonGrid,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonRefresher,
  IonRefresherContent,
  IonRow,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonButtons,
  IonRouterLink,
  IonActionSheet,
} from '@ionic/react';

import { RefresherEventDetail } from '@ionic/core';
import { useTranslation } from 'react-i18next';

import { RootState, Dispatch } from '../../models/store';

import { getAllPosts, removePost } from '../../services/postService';

import { postsLink, postFormLink } from '../../constants/routes';
import { FiPlusCircle } from 'react-icons/fi';
import { useHistory } from 'react-router';
import Card from '../../components/post/lists/Card';
import GoBack from '../../components/GoBack';

const Posts: React.FC<PostsComponent> = ({
  user,
  posts,
  setStatePostsList,
  setStateToast,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'posts',
  });
  const history = useHistory();

  const [data, setData] = useState<Post[]>([]);
  const [isInfiniteDisabled, setInfiniteDisabled] = useState<boolean>(false);
  const [showAlert, setShowAlert] = useState<string | null>(null);

  useEffect(() => {
    if (!posts?.list?.length || data.length) {
      return;
    }
    pushData();
    // eslint-disable-next-line
  }, [posts?.list]);

  const pushData = (ev?: any) => {
    const max = data.length + 5;
    const min = max - 5;
    const newData = [];
    for (let i = min; i < max; i++) {
      if (posts?.list?.[i]) {
        newData.push(posts?.list[i]);
      }
    }

    setData([...data, ...newData]);
    ev?.target?.complete();
    if (data.length === posts?.list?.length) {
      setInfiniteDisabled(true);
    }
  };

  const doRefresh = async (event: CustomEvent<RefresherEventDetail>) => {
    const { posts: list } = await getAllPosts({ force: true });
    await setStatePostsList(list);
    event.detail.complete();
  };

  return !posts ? null : (
    <>
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <GoBack />
            <IonTitle>{t('title')}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent fullscreen>
          <IonHeader collapse="condense" translucent>
            <IonToolbar>
              <IonTitle size="large">{t('title')}</IonTitle>
              <IonButtons slot="end">
                <IonRouterLink routerLink={postFormLink}>
                  <FiPlusCircle size="1.5em" />
                </IonRouterLink>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonRefresher
            slot="fixed"
            onIonRefresh={doRefresh}
            snapbackDuration="2000ms"
          >
            <IonRefresherContent></IonRefresherContent>
          </IonRefresher>
          <div className="page-content">
            <IonGrid>
              <IonRow className="cards-list">
                {(data || []).map((tmpPost: Post) => {
                  const post =
                    (posts?.list || []).find(({ id }) => id === tmpPost.id) ||
                    tmpPost;

                  return !post.association ? null : (
                    <IonCol
                      size="12"
                      sizeMd="6"
                      sizeLg="6"
                      sizeXl="4"
                      key={`post-${post.id}`}
                    >
                      <Card item={post} />
                    </IonCol>
                  );
                })}
                <IonInfiniteScroll
                  onIonInfinite={pushData}
                  disabled={isInfiniteDisabled}
                >
                  <IonInfiniteScrollContent></IonInfiniteScrollContent>
                </IonInfiniteScroll>
              </IonRow>
            </IonGrid>
          </div>
        </IonContent>
      </IonPage>
      <IonActionSheet
        data-testid="logout-alert"
        isOpen={!!showAlert}
        onDidDismiss={() => setShowAlert(null)}
        header={t('More actions')}
        buttons={[
          {
            text: t('Delete this post'),
            role: 'destructive',
            data: {
              type: 'delete',
            },
            handler: async () => {
              try {
                await removePost({ id: showAlert });
                history.push(postsLink);
                setStateToast({
                  isOpen: true,
                  type: 'success',
                  position: 'top',
                  message: 'Post deleted successfully',
                });
              } catch (e) {
                setStateToast({
                  isOpen: true,
                  message:
                    'Cannot delete this post at the moment, please try again later',
                });
              }
            },
          },
          {
            text: t('Cancel'),
            role: 'cancel',
          },
        ]}
      />
    </>
  );
};

const mapState = (state: RootState) => ({
  user: state.user.user,
  posts: state.posts.posts,
});

const mapDispatch = (dispatch: Dispatch) => ({
  setStatePostsList: dispatch.posts.setStatePostsList,
  setStateToast: dispatch.toast.setStateToast,
});

export default connect(mapState, mapDispatch)(Posts);
