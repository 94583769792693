import React, { useEffect, useState } from 'react';
import { IonReactRouter } from '@ionic/react-router';
import { Route, Redirect } from 'react-router-dom';
import {
  IonApp,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonSelect,
  IonLoading,
  IonSelectOption,
  IonImg,
  IonBadge,
  IonNav,
} from '@ionic/react';
import { setupIonicReact } from '@ionic/react';

import { isPlatform } from '@ionic/core';
import { useSubscription } from '@apollo/react-hooks';
import { connect } from 'react-redux';
import {
  FiGlobe,
  FiSearch,
  FiHeart,
  FiActivity,
  FiBell,
  FiHome,
} from 'react-icons/fi';
import { useTranslation } from 'react-i18next';
import { Elements } from '@stripe/react-stripe-js';
import { Stripe, loadStripe } from '@stripe/stripe-js';
import CookieConsent from 'react-cookie-consent';
import { useAuth } from 'authentication';
import { useNotification } from 'notification';

import Introduction from './pages/introduction/Introduction';
import Posts from './pages/post/Posts';
import About from './pages/settings/about/About';
import Category from './pages/association/Category';
import Associations from './pages/association/Associations';
import Following from './pages/following/Following';
import Login from './pages/login/Login';
import Register from './pages/register/Register';
import ResetPassword from './pages/resetPassword/ResetPassword';
import Settings from './pages/settings/Settings';
import Account from './pages/settings/account/Account';
import FavoriteCategories from './pages/settings/profile/FavoriteCategories';
import Notification from './pages/settings/notification/Notification';
import Community from './pages/community/Community';
import Help from './pages/help/Help';
import RecurringDonations from './pages/settings/recurringDonations/RecurringDonations';
import SettingsNavigationModal from './pages/settings/SettingsNavigationModal';
import Donations from './pages/donations/Donations';

import PostModal from './pages/post/PostModal';
import AssociationModal from './pages/association/AssociationModal';
import ConnectionModal from './pages/community/ConnectionModal';
import DonationModal from './components/DonationModal';
import ConfirmPaymentModal from './components/ConfirmPaymentModal';

import AppUrlListener from './components/AppUrlListener';
import Toast from './components/Toast';
import ProfilePicture from './components/ProfilePicture';

import {
  associationsLink,
  categoryRouteLink,
  postsLink,
  followingLink,
  loginLink,
  registerLink,
  resetPasswordLink,
  settingsLink,
  accountLink,
  settingsNotificationsLink,
  favoriteCategoriesLink,
  aboutLink,
  internalDynamicLink,
  communityLink,
  introductionLink,
  helpLink,
  recurringDonations,
  donationsLink,
  homeLink,
  rootLink,
  notificationsLink,
  postFormLink,
  associationFormLink,
  searchLink,
  searchResultsLink,
} from './constants/routes';
import { RootState, Dispatch } from './models/store';
import Storage from './storage';
import * as networkStatus from './utils/networkStatus';
import * as notification from './utils/notification';

import { connectionRequestReceived } from './graphql/community/connectionRequestReceived';
import {
  addUserLocation,
  createUser,
  editUserFavoriteCategories,
  getUserByEmail,
  getUserPayments,
  getUsers,
} from './services/userService';
import { searchAssociations } from './services/associationService';
import { getAllCategories } from './services/categoryService';
import { getAllPosts } from './services/postService';
import { getCountries } from './services/countryService';
import { setUserId, setUserProperty } from './services/firebaseApp';

import 'authentication/dist/css/bundle.css';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.scss';

/* Custom css */
import './theme/alert.scss';
import './theme/animation.scss';
import './theme/avatar.scss';
import './theme/badge.scss';
import './theme/button.scss';
import './theme/card.scss';
import './theme/grid.scss';
import './theme/header.scss';
import './theme/form.scss';
import './theme/html.scss';
import './theme/image.scss';
import './theme/item.scss';
import './theme/list.scss';
import './theme/misc.scss';
import './theme/modal.scss';
import './theme/swipe.scss';
import './theme/spacing.scss';
import './theme/scrollbar.scss';
import './theme/searchbar.scss';
import './theme/shine.scss';
import './theme/table.scss';
import './theme/tabs.scss';
import './theme/toast.scss';
import './theme/typo.scss';
import './theme/draft-editor.scss';
import AllAssociations from './pages/association/AllAssociations';
import { getNotifications } from './services/notificationService';
import Notifications from './pages/notifications/Notifications';
import PostForm from './pages/post/PostForm';
import { newPostReceived } from './graphql/post/newPostReceived';
import AssociationForm from './pages/association/AssociationForm';
import Search from './pages/search/Search';

(async () => {
  if (isPlatform('mobileweb')) {
    await require('./theme/mobileweb.scss');
  } else if (isPlatform('android')) {
    await require('./theme/android.scss');
  } else if (isPlatform('ios')) {
    await require('./theme/ios.scss');
  }
})();

setupIonicReact({
  mode: isPlatform('android') ? 'md' : 'ios',
  rippleEffect: false,
  spinner: 'circles',
});

let stripePromise: Stripe | null = null;

//if (process.env.REACT_APP_ENV === 'production') {
loadStripe(process.env.REACT_APP_STRIPE_PK || '');
//}

const App: React.FC<AppComponent> = ({
  user,
  users,
  categories,
  associations,
  countries,
  posts,
  notifications,
  setStateUser,
  setStateCategories,
  setStateAssociations,
  setStatePosts,
  setStateCountries,
  setStateUsers,
  setStateNotifications,
  setStateToast,
  setStateSearchValue,
  resetHistory,
}) => {
  const { user: userFirebase, logOut, loading: loadingFirebase } = useAuth()!;
  const { t, i18n } = useTranslation();
  const { notificationReceived } = useNotification()!;
  const [isFirstTime, setFirstTime] = useState<boolean>();
  const [loading, setLoading] = useState<boolean>(true);
  const [userNotificationBadge, setUserNotificationBadge] = useState<number>();

  const changeLanguage = (lang: string) => i18n.changeLanguage(lang);

  useEffect(() => {
    (async () => {
      setLoading(true);

      if (loadingFirebase || user?.status === 'creating') {
        return;
      }

      if (!loadingFirebase && !userFirebase) {
        setLoading(false);
        return;
      }
      try {
        if (!userFirebase?.email) {
          await logOut();
          setLoading(false);
          return;
        }

        let currentUser;

        const { user } = await getUserByEmail({
          email: userFirebase?.email,
        });
        currentUser = user;

        if (!currentUser) {
          const { user } = await createUser({
            name: userFirebase?.displayName ?? '',
            email: userFirebase?.email,
          });
          currentUser = user;
          const categoryIds = localStorage.getItem('favorites_categories');
          if (categoryIds) {
            await editUserFavoriteCategories({
              categoryIds: JSON.parse(categoryIds),
            });
          }
          const coordinates = localStorage.getItem('coordinates');
          if (coordinates) {
            await addUserLocation({
              coordinates: JSON.parse(coordinates),
            });
          }
        }

        if (!currentUser) {
          await logOut();
          setLoading(false);
          return;
        }

        const {
          connections,
          associations,
          favorites,
          likes,
          categories,
          payments,
          devices,
          posts,
          ...info
        } = currentUser;
        await setStateUser({
          firebase: userFirebase,
          info,
          devices,
          associations,
          favorites,
          likes,
          categories,
          connections,
          posts,
        });
        setUserId(info?.id);
        setUserProperty('email', info.email);
        setLoading(false);
      } catch (e: any) {
        await logOut();
        setLoading(false);
      }
    })();
    // eslint-disable-next-line
  }, [userFirebase, loadingFirebase, user?.status]);

  useEffect(() => {
    (async () => {
      networkStatus.addListener(setStateToast);

      return () => {
        networkStatus.removeAllListeners();
      };
    })();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (notificationReceived) {
      setStateToast({
        isOpen: true,
        isNotification: true,
        header: notificationReceived?.title ?? '',
        message: notificationReceived?.body ?? '',
      });
    }
    // eslint-disable-next-line
  }, [notificationReceived]);

  const {
    data: subscriptionConnectionsData,
    loading: subscriptionConnectionsLoading = true,
  } = useSubscription<{
    connectionRequestReceived: {
      receiverId: string;
      requesterId: string;
      status: string;
    };
  }>(connectionRequestReceived);

  useEffect(() => {
    if (subscriptionConnectionsLoading || !subscriptionConnectionsData) {
      return;
    }
    (async () => {
      const { receiverId, requesterId } =
        subscriptionConnectionsData.connectionRequestReceived;
      if (![receiverId, requesterId].includes(user?.info?.id || '')) {
        return;
      }

      const { user: updatedUser } = await getUserByEmail({
        email: user?.info?.email || '',
      });
      if (!updatedUser) {
        return;
      }
      const { connections } = updatedUser;

      await setStateUser({ connections });
      setUserNotificationBadge(
        notification.getNotificationsLength(user, connections)
      );
      const { notifications: list }: any = await getNotifications();
      setStateNotifications({ list, status: '' });
    })();
    // eslint-disable-next-line
  }, [subscriptionConnectionsData]);

  const {
    data: subscriptionPostsData,
    loading: subscriptionPostsLoading = true,
  } = useSubscription<{
    newPostReceived: {
      id: string;
      status: 'added' | 'deleted' | 'edited';
    };
  }>(newPostReceived);

  useEffect(() => {
    if (subscriptionPostsLoading || !subscriptionPostsData) {
      return;
    }
    (async () => {
      const { id, status } = subscriptionPostsData.newPostReceived;
      if (
        status !== 'deleted' &&
        (posts?.list || []).find((post) => post.id === id)
      ) {
        return;
      }
      const { posts: list } = await getAllPosts({ force: true });
      await setStatePosts({ list });
    })();
    // eslint-disable-next-line
  }, [subscriptionPostsData]);

  useEffect(() => {
    if (!user?.connections) {
      return;
    }
    setUserNotificationBadge(
      notification.getNotificationsLength(user, user.connections)
    );
    // eslint-disable-next-line
  }, [user?.connections]);

  useEffect(() => {
    (async () => {
      const storage = await new Storage();
      const first_time = await storage.get('first_time');
      if (first_time !== null) {
        setFirstTime(false);
      } else {
        setFirstTime(true);
        storage.set('first_time', 'done');
      }
    })();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    (async () => {
      const { associations: list } = await searchAssociations({
        searchValue: '',
        categoryCode: '',
      });
      setStateAssociations({ list, status: '' });
    })();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (categories?.list) {
      return;
    }
    (async () => {
      const { categories: list } = await getAllCategories({});
      setStateCategories({ list, status: '' });
    })();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (countries?.list) {
      return;
    }
    (async () => {
      const { countries: list } = await getCountries({ all: true });
      setStateCountries({ list, status: '' });
    })();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!userFirebase || !user?.info?.id || users?.list) {
      return;
    }
    (async () => {
      const { users: list } = await getUsers();
      setStateUsers({ list, status: '' });
    })();
    // eslint-disable-next-line
  }, [userFirebase, user]);

  useEffect(() => {
    if (!userFirebase || !user?.info?.id || user.payments) {
      return;
    }
    (async () => {
      const { payments } = await getUserPayments();
      setStateUser({ payments });
    })();
    // eslint-disable-next-line
  }, [userFirebase, user]);

  useEffect(() => {
    if (!userFirebase || !user?.info?.id || notifications?.list) {
      return;
    }
    (async () => {
      const { notifications: list } = await getNotifications();
      setStateNotifications({ list, status: '' });
    })();
    // eslint-disable-next-line
  }, [userFirebase, user]);

  useEffect(() => {
    (async () => {
      if (posts.list) {
        return;
      }
      const { posts: list, error }: any = await getAllPosts({});
      setStatePosts({ list, status: '' });
      if (error) {
        setStateToast({
          isOpen: true,
          duration: 0,
          type: 'danger',
          message:
            'It seems we can not get your data at the moment, please try again later',
        });
      }
    })();
    // eslint-disable-next-line
  }, []);

  const resetSearch = (e: any) => {
    resetHistory();
    setStateSearchValue('');
  };

  return loading || isFirstTime === undefined ? (
    <IonApp>
      <IonLoading isOpen={loading} />
    </IonApp>
  ) : (
    <IonApp className={isFirstTime || !userFirebase ? 'no-navigation' : ''}>
      {isPlatform('hybrid') || isFirstTime ? null : (
        <CookieConsent location="bottom" buttonText={t('cookies.Accept')}>
          {t('cookies.We use 🍪 cookies to personalise your experience.')}
          <a
            href="https://charitable.be/privacy-policy"
            target="_blank"
            rel="noreferrer"
          >
            {t('cookies.Learn more')}
          </a>
        </CookieConsent>
      )}
      <IonReactRouter>
        <AppUrlListener />
        <IonTabs>
          <IonRouterOutlet>
            <Route path={[postsLink]} exact>
              <Posts />
            </Route>
            <Route path={[postFormLink]}>
              <PostForm />
            </Route>
            <Route path={[categoryRouteLink]} exact>
              <Category />
            </Route>
            <Route path={[associationsLink]} exact>
              <AllAssociations />
            </Route>
            <Route path={searchLink} exact>
              <Search />
            </Route>
            <Route path={aboutLink} exact>
              <About />
            </Route>
            <Route path={followingLink} exact>
              <Following />
            </Route>
            <Route path={helpLink} exact>
              <Help />
            </Route>
            <Route path={settingsLink} exact>
              <Settings />
            </Route>
            <Route path={accountLink} exact>
              <Account />
            </Route>
            <Route path={[communityLink]} exact>
              <Community />
            </Route>
            <Route path={recurringDonations} exact>
              <RecurringDonations />
            </Route>
            <Route path={favoriteCategoriesLink} exact>
              <FavoriteCategories />
            </Route>
            <Route path={settingsNotificationsLink} exact>
              <Notification />
            </Route>
            <Route path={donationsLink}>
              <Donations />
            </Route>
            <Route path={notificationsLink} exact>
              <Notifications />
            </Route>
            <Route path={resetPasswordLink} exact>
              <ResetPassword />
            </Route>
            <Route path={[homeLink]} exact>
              <Associations />
            </Route>

            <Route
              path={registerLink}
              exact
              render={(props) =>
                !loading && !userFirebase ? (
                  <Register {...props} />
                ) : (
                  <Redirect to={rootLink} />
                )
              }
            />
            <Route
              path={loginLink}
              exact
              render={(props) =>
                !loading && !userFirebase ? (
                  <Login {...props} />
                ) : (
                  <Redirect to={rootLink} />
                )
              }
            />
            <Route
              path={introductionLink}
              render={(props) =>
                !loading && isFirstTime ? (
                  <Introduction {...props} setFirstTime={setFirstTime} />
                ) : (
                  <Redirect to={rootLink} />
                )
              }
            />
            <Route
              path={rootLink}
              exact
              render={() =>
                !loading && isFirstTime ? (
                  <Redirect to={introductionLink} />
                ) : !userFirebase ? (
                  <Redirect to={loginLink} />
                ) : (
                  <Redirect to={homeLink} />
                )
              }
            />

            <Route path={internalDynamicLink}>
              <Associations />
            </Route>
          </IonRouterOutlet>
          {isFirstTime || !userFirebase ? (
            <IonTabBar />
          ) : (
            <IonTabBar slot="bottom" className="tab-bar">
              <IonTabButton
                tab="home"
                href={homeLink}
                data-cy="link-home"
                className="logo ion-hide-sm-down"
              >
                <IonImg
                  src="/charitable.png"
                  alt=""
                  style={{ width: '50px' }}
                />
                <h1 className="main-title ion-hide-lg-down">Charitable</h1>
              </IonTabButton>
              <IonTabButton tab="home" href={homeLink} data-cy="link-home">
                <FiHome size="2em" onClick={resetSearch} />
                <p className="ion-hide-lg-down" onClick={resetSearch}>
                  {t('tabs.Home')}
                </p>
              </IonTabButton>
              <IonTabButton
                tab="search"
                href={searchLink}
                data-cy="link-search"
              >
                <FiSearch size="2em" onClick={resetSearch} />
                <p className="ion-hide-lg-down" onClick={resetSearch}>
                  {t('tabs.Search')}
                </p>
              </IonTabButton>
              <IonTabButton tab="posts" href={postsLink} data-cy="link-posts">
                <FiActivity size="2em" onClick={resetSearch} />
                <p className="ion-hide-lg-down" onClick={resetSearch}>
                  {t('tabs.Posts')}
                </p>
              </IonTabButton>
              <IonTabButton
                tab="community"
                href={communityLink}
                data-cy="link-community"
              >
                <FiGlobe size="2em" onClick={resetSearch} />
                {!userNotificationBadge ? null : (
                  <IonBadge color="danger">{userNotificationBadge}</IonBadge>
                )}
                <p className="ion-hide-lg-down" onClick={resetSearch}>
                  {t('tabs.Community')}
                </p>
              </IonTabButton>
              <IonTabButton
                tab="notifications"
                href={notificationsLink}
                data-cy="link-notifications"
                className="ion-hide-sm-down"
              >
                <FiBell size="2em" onClick={resetSearch} />
                {!notifications?.unread?.length ? null : (
                  <IonBadge color="danger">
                    {notifications?.unread?.length}
                  </IonBadge>
                )}
                <p className="ion-hide-lg-down" onClick={resetSearch}>
                  {t('tabs.Notifications')}
                </p>
              </IonTabButton>
              <IonTabButton
                tab="settings"
                href={!loading && userFirebase ? settingsLink : loginLink}
                data-cy="link-login"
              >
                <ProfilePicture
                  className="icon-only avatar-xs ion-color-medium transparent ion-margin-start-half"
                  isNewPicture={true}
                  onClick={resetSearch}
                />
                <p className="ion-hide-lg-down" onClick={resetSearch}>
                  {t('tabs.Profile')}
                </p>
              </IonTabButton>
              {isPlatform('hybrid') ? null : (
                <IonTabButton
                  onClick={(e) => e.stopImmediatePropagation()}
                  tab=""
                  href=""
                  data-cy="link-lang"
                  className="lang-switcher ion-hide-sm-down"
                >
                  <IonSelect
                    value={i18n.language || 'en-US'}
                    onIonChange={(e) => changeLanguage(e.detail.value)}
                  >
                    <IonSelectOption value="en-US">EN</IonSelectOption>
                    <IonSelectOption value="fr-FR">FR</IonSelectOption>
                  </IonSelect>
                </IonTabButton>
              )}
              )
            </IonTabBar>
          )}
        </IonTabs>

        <SettingsNavigationModal />
        <PostModal />
        <AssociationModal />
        <AssociationForm />
        <ConnectionModal />
        <ConfirmPaymentModal />
        <Elements stripe={stripePromise}>
          <DonationModal />
        </Elements>
      </IonReactRouter>
      <Toast />
    </IonApp>
  );
};

const mapState = (state: RootState) => ({
  user: state.user.user,
  users: state.user.users,
  countries: state.countries.countries,
  categories: state.categories.categories,
  associations: state.associations.associations,
  posts: state.posts.posts,
  notifications: state.notifications.notifications,
});

const mapDispatch = (dispatch: Dispatch) => ({
  setStateUser: dispatch.user.setStateUserAsync,
  setStateCategories: dispatch.categories.setStateCategories,
  setStateAssociations: dispatch.associations.setStateAssociations,
  setStatePosts: dispatch.posts.setStatePosts,
  setStateCountries: dispatch.countries.setStateCountries,
  setStateUsers: dispatch.user.setStateUsers,
  setStateNotifications: dispatch.notifications.setStateNotifications,
  setStateToast: dispatch.toast.setStateToast,
  setStateSearchValue: dispatch.search.setStateSearchValue,
  resetHistory: dispatch.historyStack.resetHistory,
});

export default connect(mapState, mapDispatch)(App);
