import { createModel } from '@rematch/core';

import { RootState, RootModel } from './store';

const initialState: PostState = {
  post: null,
  postForm: null,
  posts: {
    status: 'initializing',
    list: null,
  },
};

export const posts = createModel<RootModel>()({
  state: initialState,
  reducers: {
    setStatePosts: (state, payload: Partial<PostsInterface>) => ({
      ...state,
      posts: { ...state.posts, ...payload },
    }),
    setStatePostsList: (state, payload: Post[]) => ({
      ...state,
      posts: { ...state.posts, list: payload },
    }),
    setStatePost: (state, payload: Post) => ({
      ...state,
      post: payload,
    }),
    setStatePostForm: (state, payload: Post) => ({
      ...state,
      postForm: payload,
    }),
  },
});
