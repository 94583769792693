import React, { useEffect, useState } from 'react';
import { IonModal, isPlatform } from '@ionic/react';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { Virtual, Keyboard } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { RootState, Dispatch } from '../../models/store';
import { logEvent } from '../../services/firebaseApp';
import PostContent from './PostContent';
import { homeLink, postLink } from '../../constants/routes';

import 'swiper/css';
import '@ionic/react/css/ionic-swiper.css';
import ModalNavigation from '../../components/ModalNavigation';
import { StatusBar } from '@capacitor/status-bar';

const PostModal: React.FC<PostModalComponent> = ({
  setStatePost,
  posts,
  post,
}) => {
  const history = useHistory();
  const location = useLocation();
  const [swiper, setSwiper] = useState<any | undefined>(undefined);
  const [list, setList] = useState<Post[] | null>(null);
  const [currentIndex, setCurrentIndex] = useState<number | undefined>(
    undefined
  );

  useEffect(() => {
    setList(posts?.list);
    // eslint-disable-next-line
  }, [posts?.list]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.has('edit')) {
      return;
    }
    if (!queryParams.has('post')) {
      setStatePost(null);
      return;
    }
    if (queryParams.get('post') === 'NEW') {
      history.push(homeLink);
      return;
    }
    const post = (posts?.list || []).find(
      ({ id }) => id === queryParams.get('post')
    );
    if (!post) {
      history.push(homeLink);
      return;
    }
    setStatePost(post);
    logEvent('view_item', {
      content_type: 'association',
      content_id: post?.id || 'undefined',
    });

    // eslint-disable-next-line
  }, [post, location]);

  useEffect(() => {
    if (!post || !list) {
      return;
    }
    const currentIndex = (list || []).findIndex(
      ({ id }: Post) => id === post.id
    );
    setCurrentIndex(currentIndex);
    // eslint-disable-next-line
  }, [post, list]);

  const detectChange = async (swiper: any) => {
    const currentPost = (list || [])[swiper.activeIndex];
    history.push(postLink(currentPost.id));
  };

  const closeModal = () => {
    if (isPlatform('hybrid')) {
      StatusBar.show();
    }
    const queryParams = new URLSearchParams(location.search);
    queryParams.delete('post');
    history.replace({
      search: queryParams.toString(),
    });
  };

  return (
    <IonModal
      isOpen={!!post}
      onWillDismiss={closeModal}
      onWillPresent={() => {
        isPlatform('hybrid') && StatusBar.hide();
      }}
      className="content-modal"
    >
      <ModalNavigation closeModal={closeModal} swiper={swiper} />
      <Swiper
        modules={[Virtual, Keyboard]}
        onInit={(swiper) => setSwiper(swiper)}
        onSlideChangeTransitionEnd={detectChange}
        initialSlide={currentIndex}
        keyboard={{ enabled: true }}
        freeMode={true}
        virtual
      >
        {(list || []).map((post, index) => (
          <SwiperSlide key={index} virtualIndex={index}>
            <PostContent post={post} />
          </SwiperSlide>
        ))}
      </Swiper>
    </IonModal>
  );
};

const mapState = (state: RootState) => ({
  posts: state.posts.posts,
  post: state.posts.post,
});

const mapDispatch = (dispatch: Dispatch) => ({
  setStatePost: dispatch.posts.setStatePost,
});

export default connect(mapState, mapDispatch)(PostModal);
