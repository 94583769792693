import React from 'react';
import { IonCol, IonButton, IonIcon } from '@ionic/react';
import { isPlatform } from '@ionic/core';
import { logoApple } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';
import { OAuthProvider } from 'firebase/auth';

import { SignInWithProviderProps, useAuth } from '../types';

type Props = SignInWithProviderProps & {
  useAuth: typeof useAuth;
};

const appleProvider = new OAuthProvider('apple.com');

const SignInWithApple: React.FC<Props> = ({
  useAuth,
  buttonText,
  isLogin,
  beforeLogin,
  onLoggedIn,
  onError,
  expand,
  shape,
  color,
  size,
  fill,
  className,
}) => {
  const { user, logInWithProvider } = useAuth()!;
  const {
    i18n: { language },
  } = useTranslation('translation', {
    keyPrefix: 'signInWithProvider',
  });
  const currentLanguage = (language || 'en-US').split('-')[0];

  appleProvider.setCustomParameters({
    locale: currentLanguage,
  });

  const signInWithProvider = async (provider: OAuthProvider): Promise<void> => {
    await logInWithProvider({
      provider,
      isLogin,
      beforeLogin,
      onLoggedIn,
      onError,
    });
  };

  return !user && !isPlatform('android') ? (
    <IonButton
      expand={expand || undefined}
      color={color || 'default'}
      size={size || 'default'}
      fill={fill || 'default'}
      shape={shape || 'round'}
      className={`sign-in-with-apple-btn ${className}`}
      onClick={() => signInWithProvider(appleProvider)}
      data-testid="button"
    >
      <IonIcon icon={logoApple} slot="start" />
      {buttonText ? (
        <span className="ion-margin-start-half">{buttonText}</span>
      ) : (
        ''
      )}
    </IonButton>
  ) : null;
};

export default SignInWithApple;
