import * as Sentry from '@sentry/react';

import { removeTypenames } from '../utils/graphql';

import client from '../apollo/client';

import { getCountries as getCountriesQuery } from '../graphql/country/getCountries';

export async function getCountries(variables: {
  all?: boolean;
}): Promise<{ countries: Country[] }> {
  try {
    const {
      data: { getCountries: countries },
    } = await client.query({
      query: getCountriesQuery,
      variables,
      fetchPolicy: 'no-cache',
    });

    return { countries: removeTypenames(countries) };
  } catch (e: any) {
    Sentry.captureException(e);
    throw new Error();
  }
}
