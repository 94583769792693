import gql from 'graphql-tag';
import postFragment from './postFragment';

export const publishPost: any = gql`
  mutation publishPost($id: ID!) {
    publishPost(id: $id) {
      ...postFragment
    }
  }

  ${postFragment}
`;
