import { gql } from '@apollo/client';
import connectionFragment from './connectionFragment';

export const addConnection: any = gql`
  mutation addConnection($userId: ID!) {
    addConnection(userId: $userId) {
      ...connectionFragment
    }
  }

  ${connectionFragment}
`;
