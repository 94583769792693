import React, { useState } from 'react';
import { IonAlert, IonButton } from '@ionic/react';
import { useTranslation } from 'react-i18next';

import { DeleteAccountProps, useAuth } from '../types';

type Props = DeleteAccountProps & {
  useAuth: typeof useAuth;
};

const DeleteAccount: React.FC<Props> = ({
  useAuth,
  children,
  color,
  fill,
  beforeDeleteAccount,
  onDeletedAccount,
  onError,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'account',
  });
  const { user, deleteAccount } = useAuth()!;
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);

  return !user ? null : (
    <>
      <IonButton
        color={color || 'primary'}
        fill={fill || 'default'}
        onClick={() => setShowAlert(true)}
      >
        {children || t('Delete account')}
      </IonButton>

      <IonAlert
        data-testid="deleteAccount-alert"
        isOpen={showAlert}
        onWillDismiss={() => setShowAlert(false)}
        header={t('So this is goodbye?')}
        message={t('Are you sure you want to delete your account?')}
        buttons={[
          {
            text: t('Delete my acccount'),
            role: 'destructive',
            handler: async () => {
              try {
                await deleteAccount({ beforeDeleteAccount, onDeletedAccount });
                setShowConfirmation(true);
              } catch (e) {
                onError && onError(e);
              }
            },
          },
          {
            text: t('Cancel'),
            role: 'cancel',
          },
        ]}
      />
      <IonAlert
        isOpen={showConfirmation}
        onDidDismiss={() => setShowConfirmation(false)}
        header={t('Your account has been deleted')}
        message={t('All the team at Collective thank you for your efforts')}
        buttons={[
          {
            text: t('Close'),
            role: 'cancel',
          },
        ]}
      />
    </>
  );
};

export default DeleteAccount;
