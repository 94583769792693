import React, { useEffect, KeyboardEvent } from 'react';
import { IonSearchbar } from '@ionic/react';
import { connect } from 'react-redux';
import { SearchbarChangeEventDetail } from '@ionic/core';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';

import { RootState, Dispatch } from '../models/store';

import {
  searchAnything,
  getSearchSuggestions,
} from '../services/searchService';
import { searchResultsLink } from '../constants/routes';

const SearchBar: React.FC<SearchBarComponent> = ({
  searchValue = '',
  setStateSearchValue,
  setStateSearchValueAsync,
  setStateSearchSuggestions,
  setStateAssociations,
  setStateCategories,
  setStatePosts,
}) => {
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation('translation', {
    keyPrefix: 'common',
  });

  useEffect(() => {
    (async () => {
      const queryParams = new URLSearchParams(location.search);
      if (queryParams.has('search')) {
        setStateSearchValue(queryParams.get('search'));
        await handleGetSuggestions(queryParams.get('search') || '');
      }
    })();
    // eslint-disable-next-line
  }, [location]);

  const handleSearchSuggestion = async (
    e: CustomEvent<SearchbarChangeEventDetail>
  ): Promise<void> => {
    const value = e.detail.value!;
    setStateSearchValue(value);
    await handleGetSuggestions(value);
  };

  const handleGetSuggestions = async (value: string): Promise<void> => {
    const results = await getSearchSuggestions({
      type: 'all',
      searchValue: value,
    });
    setStateSearchSuggestions(results);
  };

  const handleCancelSearch = async (): Promise<void> => {
    await handleSearch(true);
  };

  const handleSearch = async (reset: boolean = false): Promise<void> => {
    setStateAssociations({ status: 'loading' });
    setStateCategories({ status: 'loading' });
    setStatePosts({ status: 'loading' });
    if (reset) {
      await setStateSearchValueAsync('');
    } else {
      history.push(searchResultsLink(searchValue));
    }
    const results = await searchAnything({
      type: 'all',
      searchValue: reset ? '' : searchValue,
    });
    setStateSearchSuggestions(results);
    setStateAssociations({ status: '' });
    setStateCategories({ status: '' });
    setStatePosts({ status: '' });
  };

  return (
    <IonSearchbar
      mode="ios"
      debounce={1000}
      showCancelButton="never"
      placeholder={t('search')}
      onIonChange={handleSearchSuggestion}
      onIonClear={handleCancelSearch}
      value={searchValue}
    />
  );
};

const mapState = (state: RootState) => ({
  searchValue: state.search.value,
});

const mapDispatch = (dispatch: Dispatch) => ({
  setStateAssociations: dispatch.associations.setStateAssociations,
  setStateCategories: dispatch.categories.setStateCategories,
  setStatePosts: dispatch.posts.setStatePosts,
  setStateSearchValue: dispatch.search.setStateSearchValue,
  setStateSearchValueAsync: dispatch.search.setStateSearchValueAsync,
  setStateSearchSuggestions: dispatch.search.setStateSearchSuggestions,
});

export default connect(mapState, mapDispatch)(SearchBar);
