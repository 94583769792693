import React from 'react';
import { IonButton, IonInput, IonItem, IonNote } from '@ionic/react';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { showError } from '../utils/form';

import { ResetPasswordFormProps, useAuth } from '../types';

type FormValues = {
  email: string;
};

type Props = ResetPasswordFormProps & {
  useAuth: typeof useAuth;
};

const ResetPassword: React.FC<Props> = ({
  useAuth,
  onSuccess,
  onError,
  className,
}) => {
  const { resetPassword } = useAuth()!;
  const { t } = useTranslation('translation', {
    keyPrefix: 'resetPassword',
  });
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<FormValues>();

  const onSubmit: SubmitHandler<FormValues> = async ({ email }) =>
    await resetPassword(email, onSuccess, onError);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={className}>
      <IonItem>
        <Controller
          render={({ field: { onChange } }) => (
            <IonInput
              type="email"
              aria-label="email-input"
              placeholder={t('Email')}
              autocomplete="email"
              onIonChange={(e) => {
                onChange(e.detail.value);
              }}
            />
          )}
          name="email"
          control={control}
          rules={{
            required: {
              value: true,
              message: 'Please enter your email address',
            },
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
              message: 'Please verify your email address',
            },
          }}
        />
        {showError(errors, 'email')}
      </IonItem>
      <IonButton
        expand="block"
        shape="round"
        className="ion-margin"
        type="submit"
      >
        {t('Reset my password')}
      </IonButton>
    </form>
  );
};

export default ResetPassword;
