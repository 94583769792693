import React from 'react';
import { DeepMap } from 'react-hook-form/dist/types/utils';
import { FieldError } from 'react-hook-form';
import { IonNote } from '@ionic/react';

export const showError = (
  errors: DeepMap<Record<string, any>, FieldError>,
  fieldName: string
) => {
  return (
    (errors as any)[fieldName] && (
      <IonNote color="danger" className="error">
        {errors[fieldName].message}
      </IonNote>
    )
  );
};
