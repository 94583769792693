import { gql } from '@apollo/client';
import associationFragment from './associationFragment';

export const search: any = gql`
  query search(
    $searchValue: String!
    $categoryCode: String
    $coordinates: GeolocationPositionInput
  ) {
    search(
      searchValue: $searchValue
      categoryCode: $categoryCode
      coordinates: $coordinates
    ) {
      ...associationFragment
    }
  }
  ${associationFragment}
`;
