import { createModel } from '@rematch/core';
import { RootState, RootModel } from './store';

const initialState: CategoryState = {
  categories: {
    status: 'initializing',
    list: null,
  },
  category: null,
};

export const categories = createModel<RootModel>()({
  state: initialState,
  reducers: {
    setStateCategory: (state, payload: Category) => ({
      ...state,
      category: payload,
    }),
    setStateCategories: (state, payload: Partial<CategoryInterface>) => ({
      ...state,
      categories: { ...state.categories, ...payload },
    }),
  },
});
