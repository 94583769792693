import React, { useEffect } from 'react';
import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonGrid,
  IonRow,
  IonCol,
  IonRefresher,
  IonRefresherContent,
  RefresherEventDetail,
} from '@ionic/react';
import { connect } from 'react-redux';
import { IonContent, IonPage } from '@ionic/react';
import { useTranslation } from 'react-i18next';

import { RootState, Dispatch } from '../../models/store';

import AvatarList from './lists/NotificationsList';
import {
  getNotifications,
  readNotification,
} from '../../services/notificationService';
import GoBack from '../../components/GoBack';

const Notifications: React.FC<NotificationsComponent> = ({
  notifications,
  setStateNotifications,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'notifications',
  });

  useEffect(() => {
    if (!notifications?.list) {
      return;
    }

    setTimeout(async () => {
      (notifications?.list || [])
        .filter(({ read }) => !read)
        .map(async ({ id }) => {
          const { notification } = await readNotification({ id });
          return notification;
        });
    }, 2000);
  }, [notifications]);

  const doRefresh = async (event: CustomEvent<RefresherEventDetail>) => {
    const { notifications: list } = await getNotifications();
    await setStateNotifications({ list });
    event.detail.complete();
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <GoBack />
          <IonTitle>{t('Notifications')}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense" translucent>
          <IonToolbar>
            <IonTitle size="large">
              <IonGrid className="ion-no-padding">
                <IonRow className="ion-align-items-center">
                  <IonCol size="auto">{t('Notifications')}</IonCol>
                </IonRow>
              </IonGrid>
            </IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonRefresher
          slot="fixed"
          onIonRefresh={doRefresh}
          snapbackDuration="2000ms"
        >
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
        <div className="page-content">
          {notifications.status === '' && !(notifications.list || []).length ? (
            <div className="ion-text-center ion-padding" data-cy="results">
              <p>{t('Nothing here')}</p>
            </div>
          ) : (
            <AvatarList list={notifications?.list} title={t('All')} />
          )}
        </div>
      </IonContent>
    </IonPage>
  );
};

const mapState = (state: RootState) => ({
  notifications: state.notifications.notifications,
});

const mapDispatch = (dispatch: Dispatch) => ({
  setStateNotifications: dispatch.notifications.setStateNotifications,
});

export default connect(mapState, mapDispatch)(Notifications);
