import React, { useEffect } from 'react';
import { IonGrid, IonRow, IonCol, IonCard, IonImg } from '@ionic/react';
import { connect } from 'react-redux';

import { RootState } from '../../models/store';
import { logEvent } from '../../services/firebaseApp';

const CategoriesHeader: React.FC<CategoriesHeaderComponent> = ({
  category,
}) => {
  useEffect(() => {
    if (!category) {
      return;
    }
    logEvent('view_item_list', {
      content_type: 'category',
      content_id: category.name,
    });
  }, [category]);

  return !category ? null : (
    <>
      <IonGrid className="ion-no-padding">
        <IonRow className="ion-justify-content-center">
          <IonCol size="auto" className="ion-no-padding">
            <IonCard>
              <IonImg
                src={category.illustration?.md}
                alt=""
                className="img-md"
              />
            </IonCard>
          </IonCol>
        </IonRow>
      </IonGrid>
    </>
  );
};

const mapState = (state: RootState) => ({
  category: state.categories.category,
});

export default connect(mapState)(CategoriesHeader);
