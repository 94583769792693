import gql from 'graphql-tag';
import postFragment from './postFragment';

export const addPost = gql`
  mutation addPost($post: PostInput!) {
    addPost(post: $post) {
      ...postFragment
    }
  }

  ${postFragment}
`;
