import { gql } from '@apollo/client';
import associationFragment from './associationFragment';

export const getAssociations: any = gql`
  query getAssociations {
    getAssociations {
      ...associationFragment
    }
  }

  ${associationFragment}
`;
