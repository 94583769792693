import { gql } from '@apollo/client';
import associationFragment from '../association/associationFragment';

export const getFavoritesByTag: any = gql`
  query getFavoritesByTag($tag: String!) {
    getFavoritesByTag(tag: $tag) {
      ...associationFragment
    }
  }

  ${associationFragment}
`;
