import { gql } from '@apollo/client';
import associationFragment from '../association/associationFragment';

export const addFavoritesInBunk: any = gql`
  mutation addFavoritesInBunk($associationIds: [ID]!) {
    addFavoritesInBunk(associationIds: $associationIds) {
      ...associationFragment
    }
  }

  ${associationFragment}
`;
