import { createModel } from '@rematch/core';

import { RootModel } from './store';

const initialState: any = {
  history: [],
};

export const historyStack = createModel<RootModel>()({
  state: initialState,
  reducers: {
    push: (state, payload: string) => {
      return {
        ...state,
        history: [...state.history, payload],
      };
    },
    pop: (state, payload: string) => {
      return {
        ...state,
        history: state.history.slice(0, -2),
      };
    },
    resetHistory: (state) => ({
      ...state,
      history: [],
    }),
  },
});
