import { createModel } from '@rematch/core';
import { delay } from './utils';
import { RootModel } from './store';

const initialState: NotificationState = {
  notifications: {
    status: 'initializing',
    list: null,
    unread: null,
  },
};

export const notifications = createModel<RootModel>()({
  state: initialState,
  reducers: {
    setStateNotification: (state, payload: NotificationLocal) => {
      const newList = state.notifications.list || [];
      const index = newList.findIndex(({ id }) => id !== payload.id);

      if (index !== -1) {
        newList[index] = payload;
      }

      return {
        ...state,
        notifications: {
          ...state.notifications,
          list: newList,
        },
      };
    },
    setStateNotifications: (
      state,
      payload: Partial<NotificationsInterface>
    ) => ({
      ...state,
      notifications: {
        ...state.notifications,
        unread: (payload?.list || []).filter(({ read }) => !read),
        ...payload,
      },
    }),
  },
  effects: (dispatch) => {
    const { notifications } = dispatch;
    return {
      async setStateNotificationAsync(
        payload: NotificationLocal
      ): Promise<void> {
        await delay(0);
        notifications.setStateNotification(payload);
      },
    };
  },
});
