import gql from 'graphql-tag';
import associationFragment from './associationFragment';

export const publishAssociation: any = gql`
  mutation publishAssociation($id: ID!) {
    publishAssociation(id: $id) {
      ...associationFragment
    }
  }

  ${associationFragment}
`;
