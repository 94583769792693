import React, { useEffect } from 'react';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { useHistory, useLocation } from 'react-router';

import {
  dynamicLink,
  internalDynamicLink,
  loginLink,
  publicRoutes,
} from '../constants/routes';
import { logPageView, setScreenName } from '../services/firebaseApp';
import { Dispatch, RootState } from '../models/store';
import { connect } from 'react-redux';
import { useAuth } from 'authentication';
import { capitalizeFirstLetter } from '../utils/capitalizeFirstLetter';

const AppUrlListener: React.FC<any> = ({ user, historyStack, pushHistory }) => {
  const history = useHistory();
  const location = useLocation();
  const { user: userFirebase, logOut, loading: loadingFirebase } = useAuth()!;

  useEffect(() => {
    if (loadingFirebase) {
      return;
    }

    if (
      !publicRoutes.includes(location.pathname) &&
      !userFirebase &&
      !user?.info?.id
    ) {
      history.replace(loginLink);
      return;
    }
    if (location.pathname.includes(internalDynamicLink)) {
      const queryParams = new URLSearchParams(location.search);
      history.replace({
        pathname: location.pathname.replace(internalDynamicLink, '') || '/',
        search: queryParams.toString(),
      });
      return;
    }
    // eslint-disable-next-line
  }, [loadingFirebase, user]);

  useEffect(() => {
    if (location.pathname.includes(internalDynamicLink)) {
      const queryParams = new URLSearchParams(location.search);
      history.replace({
        pathname: location.pathname.replace(internalDynamicLink, '') || '/',
        search: queryParams.toString(),
      });
      return;
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    pushHistory(`${location.pathname}${location.search}`);
  }, []);

  useEffect(() => {
    return history.listen((location) => {
      const screenName =
        location.search && !location.search.includes('filter=')
          ? location.search.replace('?', '').split('=')[0]
          : location.pathname.replace('/', '');
      const screenClassOverride = capitalizeFirstLetter(screenName);

      setScreenName(screenName, screenClassOverride);
      logPageView({
        firebase_screen: screenName,
      });

      pushHistory(`${location.pathname}${location.search}`);
    });
  }, [history, historyStack]);

  useEffect(() => {
    (async () => {
      const urlOpen = await App.getLaunchUrl();
      const slug = (urlOpen?.url || '').split(`${dynamicLink}/`).pop() || '';
      const slugWithoutAnalytics = slug.split('&').shift();
      if (slugWithoutAnalytics) {
        history.push(`/${slugWithoutAnalytics}`);
      }
    })();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      const slug = event.url.split(`${dynamicLink}/`).pop() || '';
      const slugWithoutAnalytics = slug.split('&').shift();
      if (slugWithoutAnalytics) {
        history.push(`/${slugWithoutAnalytics}`);
      }
    });

    /* App.addListener('appStateChange', (data) => {
      console.log('appStateChange', data);
    });

    App.addListener('appRestoredResult', (data) => {
      console.log('appRestoredResult', data);
    }); */

    return () => {
      App.removeAllListeners();
    };
    // eslint-disable-next-line
  }, [history]);

  return null;
};

const mapState = (state: RootState) => ({
  user: state.user.user,
  historyStack: state.historyStack.history,
});

const mapDispatch = (dispatch: Dispatch) => ({
  pushHistory: dispatch.historyStack.push,
});

export default connect(mapState, mapDispatch)(AppUrlListener);
