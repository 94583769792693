import React from 'react';
import { I18nextProvider } from 'react-i18next';

import {
  AuthProvider as AuthContextProvider,
  useAuth as useContextAuth,
} from './contexts/AuthContext';
import i18n from './language/i18nextConf';

import ResetPassword from './forms/ResetPassword';

import SignInWithApple from './components/SignInWithApple';
import SignInWithGoogle from './components/SignInWithGoogle';
import SignInWithFacebook from './components/SignInWithFacebook';
import SignInWithEmail from './components/SignInWithEmail';
import Logout from './components/Logout';
import DeleteAccount from './components/DeleteAccount';

import './theme/variables.scss';
import './theme/button.scss';
import './theme/spacing.scss';
import './theme/form.scss';

import {
  ResetPasswordFormProps,
  SignInWithProviderProps,
  SignInWithEmailProps,
  LogoutProps,
  DeleteAccountProps,
} from './types';

export const AuthProvider = AuthContextProvider;
export const useAuth = useContextAuth;

export const ResetPasswordForm = (props: ResetPasswordFormProps) => (
  <I18nextProvider i18n={i18n}>
    <ResetPassword {...props} useAuth={useAuth} />
  </I18nextProvider>
);

export const SignInWithAppleButton = (props: SignInWithProviderProps) => (
  <I18nextProvider i18n={i18n}>
    <SignInWithApple {...props} useAuth={useAuth} />
  </I18nextProvider>
);

export const SignInWithGoogleButton = (props: SignInWithProviderProps) => (
  <I18nextProvider i18n={i18n}>
    <SignInWithGoogle {...props} useAuth={useAuth} />
  </I18nextProvider>
);

export const SignInWithFacebookButton = (props: SignInWithProviderProps) => (
  <I18nextProvider i18n={i18n}>
    <SignInWithFacebook {...props} useAuth={useAuth} />
  </I18nextProvider>
);

export const SignInWithEmailButton = (props: SignInWithEmailProps) => (
  <I18nextProvider i18n={i18n}>
    <SignInWithEmail {...props} useAuth={useAuth} />
  </I18nextProvider>
);

export const LogoutButton = (props: LogoutProps) => (
  <I18nextProvider i18n={i18n}>
    <Logout {...props} useAuth={useAuth} />
  </I18nextProvider>
);

export const DeleteAccountButton = (props: DeleteAccountProps) => (
  <I18nextProvider i18n={i18n}>
    <DeleteAccount {...props} useAuth={useAuth} />
  </I18nextProvider>
);
