import gql from 'graphql-tag';
import postFragment from './postFragment';

export const addPostPicture = gql`
  mutation addPostPicture($file: Upload!, $id: ID!) {
    addPostPicture(file: $file, id: $id) {
      file {
        success
        message
        locations {
          lg
          md
          sm
        }
      }
      post {
        ...postFragment
      }
    }
  }

  ${postFragment}
`;
