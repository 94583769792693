import { gql } from '@apollo/client';
import connectionFragment from './connectionFragment';

export const acceptConnection: any = gql`
  mutation acceptConnection($userId: ID!) {
    acceptConnection(userId: $userId) {
      ...connectionFragment
    }
  }

  ${connectionFragment}
`;
