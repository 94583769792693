import React from 'react';
import { IonButton, IonBadge, IonChip, IonText } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { homeLink } from '../../../constants/routes';
import { connect } from 'react-redux';
import { Dispatch, RootState } from '../../../models/store';

const BadgeList: React.FC<CountryBadgeListComponent> = ({
  countries,
  countriesList,
  setStateSearchValue,
  className,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation('translation', {
    keyPrefix: 'countries',
  });
  const currentLanguage = language.split('-')[0];
  const history = useHistory();

  const search = async (search: string) => {
    await setStateSearchValue(search.trim());
    history.push(homeLink);
  };

  return !(countries || []).length ? null : (
    <>
      <h4 className="ion-margin-bottom">
        <IonText color="dark">{t('Countries')}</IonText>
      </h4>
      <div className={`${className}`}>
        {(countries || []).map((country: Country) => {
          const countryName =
            (countriesList || []).find(({ code }) => code === country?.code)
              ?.name || country?.code;

          return !country ? null : (
            <IonChip
              key={`country-tag-${country.code}`}
              onClick={() => search(countryName)}
            >
              {countryName}
            </IonChip>
          );
        })}
      </div>
    </>
  );
};

const mapState = (state: RootState) => ({
  countriesList: state.countries.countries?.list,
});

const mapDispatch = (dispatch: Dispatch) => ({
  setStateSearchValue: dispatch.search.setStateSearchValue,
});

export default connect(mapState, mapDispatch)(BadgeList);
