import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  IonHeader,
  IonToolbar,
  IonContent,
  IonPage,
  IonGrid,
  IonRow,
  IonCol,
  IonTitle,
  IonButtons,
  IonButton,
  IonIcon,
  IonBadge,
  IonActionSheet,
  IonText,
  IonCard,
} from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import { intersection } from 'ramda';
import { notificationsOutline } from 'ionicons/icons';

import { RootState, Dispatch } from '../../models/store';
import { categoryLink } from '../../constants/routes';

import Categories from '../../components/category/Categories';
import CardList from '../../components/CardList';
import AvatarList from '../../components/association/lists/AvatarList';

import {
  associationNewFormLink,
  associationsLink,
  loginLink,
  notificationsLink,
  followingLink,
  postFormLink,
} from '../../constants/routes';
import {
  greetings,
  sortByDateAdded,
  sortByCreationDate,
} from '../../utils/date';
import Card from '../../components/association/lists/Card';
import CardPost from '../../components/post/lists/Card';
import { FiBell, FiPlusCircle } from 'react-icons/fi';
import Separator from '../../components/Separator';
import { FaRegBookmark } from 'react-icons/fa';

const Associations: React.FC<AssociationsComponent> = ({
  user,
  categories,
  posts,
  associations,
  notifications,
  searchValue,
  setStateAssociationForm,
}) => {
  const location = useLocation();
  const history = useHistory();
  const { t } = useTranslation('translation', {
    keyPrefix: 'associations',
  });
  const [latestPosts, setLatestPosts] = useState<Post[]>([]);
  const [latestAssociations, setLatestAssociations] = useState<Association[]>(
    []
  );
  const [spotLight, setSpotlight] = useState<Association[]>([]);
  const [forYou, setForYou] = useState<Association[]>([]);
  const [random, setRandom] = useState<Association[]>([]);
  const [displayLatestPosts, setDisplayLatestPosts] = useState<
    'grid' | 'inline'
  >('inline');
  const [displayLatest, setDisplayLatest] = useState<'grid' | 'inline'>(
    'inline'
  );
  const [displaySpotlight, setDisplaySpotlight] = useState<'grid' | 'inline'>(
    'inline'
  );
  const [displayCategories, setDisplayCategories] = useState<'grid' | 'inline'>(
    'inline'
  );
  const [showAlert, setShowAlert] = useState<boolean>(false);

  useEffect(() => {
    setSpotlight(
      (associations.list || []).filter(({ isStared }) => !!isStared)
    );
    setLatestAssociations(
      (associations.list || [])
        .filter(({ dateAdded }) => {
          return !!dateAdded;
        })
        .sort(sortByDateAdded)
        .slice(0, 5)
    );
    setRandom(
      (associations.list || []).sort(() => Math.random() - 0.5).slice(0, 5)
    );
    // eslint-disable-next-line
  }, [associations.list]);

  useEffect(() => {
    setLatestPosts(
      (posts.list || [])
        .filter(({ creationDate }) => !!creationDate)
        .sort(sortByCreationDate)
        .slice(0, 5)
    );
    // eslint-disable-next-line
  }, [posts.list]);

  useEffect(() => {
    const userCategoryIds = (user?.categories || []).map(({ id }) => id);
    const userChildrenCategoryIds = (categories?.list || [])
      .filter(({ id }) => userCategoryIds.includes(id))
      .reduce((acc: Category[], { children }) => {
        return [...acc, ...children];
      }, [])
      .map(({ id }) => id);
    setForYou(
      (associations.list || [])
        .filter(({ categories }) => {
          const associationCategoryIds = (categories || []).map(({ id }) => id);
          return intersection(userChildrenCategoryIds, associationCategoryIds)
            .length;
        })
        .sort(() => Math.random() - 0.5)
        .slice(0, 5)
    );
    // eslint-disable-next-line
  }, [user?.categories, associations.list]);

  return (
    <>
      <IonPage>
        <IonHeader className="ion-hide-sm-up">
          <IonToolbar>
            <IonTitle className="main-title ion-text-left ion-padding-start">
              Charitable
            </IonTitle>
            <IonButtons
              slot="end"
              className="button-badge ion-no-margin-vertical ion-margin-end-half"
            >
              <IonButton
                onClick={() => setShowAlert(true)}
                color="dark"
                className="ion-margin-start-half"
              >
                <FiPlusCircle size="1.2em" />
              </IonButton>
              <IonButton
                routerLink={followingLink}
                color="dark"
                className="ion-margin-start-half"
              >
                <FaRegBookmark size="1.1em" />
              </IonButton>
              {!notifications?.unread?.length ? null : (
                <IonBadge color="danger"> </IonBadge>
              )}
              <IonButton routerLink={notificationsLink} color="dark">
                <FiBell size="1.2em" />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <div className="page-content">
            <IonGrid className="ion-no-padding">
              <IonRow className="ion-margin-vertical ion-margin-top-2x-sm-up ion-align-items-center">
                <IonCol>
                  <IonText color="primary">
                    <h3>
                      {greetings(t, user.info?.firstname ?? user.info?.name)}
                    </h3>
                  </IonText>
                </IonCol>
                <IonCol size="auto" className="ion-no-padding ion-hide-sm-down">
                  <IonButtons className="button-badge ion-no-margin-vertical">
                    <IonButton
                      onClick={() => setShowAlert(true)}
                      color="dark"
                      className="ion-margin-start-half"
                    >
                      <FiPlusCircle size="1.2em" />
                    </IonButton>
                    <IonButton
                      routerLink={followingLink}
                      color="dark"
                      className="ion-margin-start-half"
                    >
                      <FaRegBookmark size="1.1em" />
                    </IonButton>
                    <div>
                      {!notifications?.unread?.length ? null : (
                        <IonBadge color="danger"> </IonBadge>
                      )}
                      <IonButton routerLink={notificationsLink} color="dark">
                        <FiBell size="1.2em" />
                      </IonButton>
                    </div>
                  </IonButtons>
                </IonCol>
              </IonRow>
            </IonGrid>
            <CardList
              display={displaySpotlight}
              list={spotLight}
              title={t('On the spotlight')}
              toggleDisplay={() =>
                setDisplaySpotlight(
                  displaySpotlight === 'inline' ? 'grid' : 'inline'
                )
              }
              Card={Card}
              pagination={true}
              slidesPerView={[1, 2, 3, 3]}
            />
            <CardList
              display={displayLatestPosts}
              list={latestPosts}
              title={t('Latest posts')}
              toggleDisplay={() =>
                setDisplayLatestPosts(
                  displayLatestPosts === 'inline' ? 'grid' : 'inline'
                )
              }
              Card={CardPost}
              pagination={true}
              slidesPerView={[1, 2, 3, 3]}
            />
            <Separator title={t('Close to you')} />
            <IonGrid className="ion-margin-vertical-2x-sm-up">
              <IonRow className="ion-justify-content-center">
                <IonCol sizeSm="12" sizeMd="6">
                  <IonCard className="ion-padding ion-text-center">
                    <IonButton
                      shape="round"
                      fill="outline"
                      routerLink={categoryLink('local')}
                    >
                      {t('Charities close to you')}
                    </IonButton>
                  </IonCard>
                </IonCol>
              </IonRow>
            </IonGrid>
            <AvatarList
              list={forYou}
              title={t('For you')}
              seeAllLink={associationsLink}
            />
            <Categories
              display={displayCategories}
              toggleDisplay={() =>
                setDisplayCategories(
                  displayCategories === 'inline' ? 'grid' : 'inline'
                )
              }
            />
            <AvatarList
              list={random}
              title={t('All')}
              seeAllLink={associationsLink}
            />
          </div>
        </IonContent>
      </IonPage>
      <IonActionSheet
        data-testid="logout-alert"
        isOpen={!!showAlert}
        onDidDismiss={() => setShowAlert(false)}
        header={t('What do you want to add?')}
        buttons={[
          {
            text: t('Add a post'),
            handler: () => {
              history.push(!user?.info?.email ? loginLink : postFormLink);
            },
          },
          {
            text: t('Add an association'),
            handler: () => {
              if (!user?.info?.email) {
                history.push(loginLink);
                return;
              }
              setStateAssociationForm({});
              history.push(associationNewFormLink);
            },
          },
          {
            text: t('Cancel'),
            role: 'cancel',
          },
        ]}
      />
    </>
  );
};

const mapState = (state: RootState) => ({
  user: state.user.user,
  posts: state.posts.posts,
  categories: state.categories.categories,
  associations: state.associations.associations,
  notifications: state.notifications.notifications,
  searchValue: state.search.value,
});

const mapDispatch = (dispatch: Dispatch) => ({
  setStateAssociationForm: dispatch.associations.setStateAssociationForm,
});

export default connect(mapState, mapDispatch)(Associations);
