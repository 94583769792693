import React, { useEffect, useState } from 'react';
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonTitle,
  IonContent,
  IonButton,
  IonToggle,
  IonLabel,
  IonList,
  IonItem,
  IonCard,
  IonGrid,
  IonRow,
  IonCol,
} from '@ionic/react';
import { connect } from 'react-redux';
import { FiArrowLeft } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/react';
import { useNotification } from 'notification';

import {
  addUserDevice,
  editUserNotification,
} from '../../../services/userService';
import { Dispatch, RootState } from '../../../models/store';
import { useHistory } from 'react-router';
import Separator from '../../../components/Separator';
import GoBack from '../../../components/GoBack';

const formParts = [
  {
    label: '',
    fields: [{ label: 'Enable all notifications', name: 'notifications' }],
  },
];

const Notification: React.FC<NotificationComponent> = ({
  user,
  setStateUser,
  setStateUserInfo,
  setStateToast,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'settings.notifications',
  });
  const history = useHistory();
  const {
    acceptNotification,
    refuseNotification,
    notificationAccepted,
    getDevice,
  } = useNotification()!;

  useEffect(() => {
    async () => {
      try {
        await getDevice();
      } catch (e: any) {
        setStateToast({
          isOpen: true,
          duration: 0,
          type: 'warning',
          message: e,
        });
        Sentry.captureException(e);
      }
    };
  }, []);

  const toggleNotification = async () => {
    try {
      if (notificationAccepted && user.info?.notificationAccepted) {
        const response = await refuseNotification();
        editUserNotification({ accepted: false });
        setStateUserInfo({ notificationAccepted: false });
        if (response.error) {
          console.log('refuseNotification error : ', response.error);
        }
        return;
      }
      const device = await acceptNotification();
      editUserNotification({ accepted: true });
      setStateUserInfo({ notificationAccepted: true });
      const { error, deviceId, deviceToken, deviceInfo } = device;
      if (error) {
        setStateToast({
          isOpen: true,
          duration: 0,
          type: 'warning',
          message: error,
        });
        return;
      }

      if (deviceInfo) {
        const { manufacturer, model, operatingSystem, osVersion, platform } =
          deviceInfo;
        const { device: newDevice } = await addUserDevice({
          deviceId,
          deviceToken,
          manufacturer,
          model,
          operatingSystem,
          osVersion,
          platform,
        });
        setStateUser({ devices: [...(user.devices || []), newDevice] });
      }
    } catch (e: any) {
      setStateToast({
        isOpen: true,
        duration: 0,
        type: 'warning',
        message: e,
      });
      Sentry.captureException(e);
    }
  };

  return !user ? null : (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <GoBack />
          <IonTitle>{t('title')}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense" translucent>
          <IonToolbar>
            <IonTitle size="large">{t('title')}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <div className="page-content">
          <form>
            {formParts.map(
              ({ label, fields }: { label: string; fields: any[] }, i) => (
                <div key={i}>
                  <Separator title={label} />
                  <IonCard>
                    <IonList lines="inset">
                      {fields.map(({ label }: { label: string }, index) => (
                        <IonItem key={index} detail={false}>
                          <IonLabel>{t(label)}</IonLabel>
                          <IonToggle
                            slot="end"
                            checked={
                              notificationAccepted &&
                              user.info?.notificationAccepted
                            }
                            onIonChange={toggleNotification}
                            className="ion-no-padding"
                          />
                        </IonItem>
                      ))}
                    </IonList>
                  </IonCard>
                </div>
              )
            )}
          </form>
        </div>
      </IonContent>
    </IonPage>
  );
};

const mapState = (state: RootState) => ({
  user: state.user.user,
});

const mapDispatch = (dispatch: Dispatch) => ({
  setStateUser: dispatch.user.setStateUserAsync,
  setStateUserInfo: dispatch.user.setStateUserInfo,
  setStateToast: dispatch.toast.setStateToast,
});

export default connect(mapState, mapDispatch)(Notification);
