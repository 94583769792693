import * as Sentry from '@sentry/react';

import { removeTypenames } from '../utils/graphql';

import client from '../apollo/client';

import { getAbout as getAboutQuery } from '../graphql/about/getAbout';

export async function getAbout(): Promise<string> {
  try {
    const {
      data: { getAbout: about },
    } = await client.query({
      query: getAboutQuery,
    });

    return removeTypenames(about);
  } catch (e: any) {
    Sentry.captureException(e);
    throw new Error();
  }
}
