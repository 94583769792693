import React, { useState } from 'react';
import { initializeApp } from 'firebase/app';
import { isPlatform } from '@ionic/react';
import { PushNotificationSchema } from '@capacitor/push-notifications';
import {
  getMessaging,
  Messaging,
  isSupported as isSupportedMessaging,
  onMessage,
} from '@firebase/messaging';

import {
  addListeners,
  removeAllListeners,
  register,
  requestPermissions,
  checkPermissions,
  checkNotificationsAccepted,
  getToken,
} from '../services';
import { getId, getInfo } from '../services/device';

import {
  DeviceResponse,
  NotificationContextInterface,
  NotificationProviderProps,
  NotificationResponse,
} from '../types';

export const NotificationContext = React.createContext<
  NotificationContextInterface | undefined
>(undefined);

export const NotificationProvider: React.FC<NotificationProviderProps> = ({
  apiKey,
  vapidKey,
  authDomain,
  projectId,
  storageBucket,
  appId,
  measurementId,
  messagingSenderId,
  children,
  ...props
}) => {
  const [firebaseApp, setFirebaseApp] = React.useState<any>(null);
  const [onMessageListener, setOnMessageListener] =
    React.useState<Function | null>(null);
  const [notificationAccepted, setNotificationAccepted] =
    React.useState<boolean>(false);
  const [notificationReceived, setNotificationReceived] =
    React.useState<PushNotificationSchema | null>(null);

  const config = {
    apiKey,
    vapidKey,
    authDomain,
    projectId,
    storageBucket,
    appId,
    measurementId,
    messagingSenderId,
  };

  /**
   * Initialize Firebase
   */
  React.useEffect(() => {
    (async () => {
      const firebaseApp = initializeApp(config);
      setFirebaseApp(firebaseApp);

      const messaging = async () => {
        return (await isSupportedMessaging()) ? getMessaging() : null;
      };

      const onMessageListener = async (next: any) => {
        const firebaseMessaging = await messaging();
        if (!firebaseMessaging) {
          console.log('firebaseMessaging error : ', firebaseMessaging);
          return;
        }

        return onMessage(firebaseMessaging, next);
      };
      setOnMessageListener(onMessageListener);
    })();
    // eslint-disable-next-line
  }, []);

  /**
   * Initialize Capacitor
   */
  React.useEffect(() => {
    if (!firebaseApp || !onMessageListener) {
      return;
    }
    addListeners(setNotificationAccepted, setNotificationReceived);

    return () => {
      removeAllListeners();
    };
    // eslint-disable-next-line
  }, [firebaseApp, onMessageListener]);

  React.useEffect(() => {
    if (!firebaseApp) {
      return;
    }
    (async () => {
      setNotificationAccepted(await checkNotificationsAccepted());
    })();
  }, [firebaseApp]);

  const refuseNotification = async (): Promise<NotificationResponse> => {
    setNotificationAccepted(false);
    return { error: null, message: 'Notification refused' };
  };

  const acceptNotification = async (): Promise<NotificationResponse> => {
    if (isPlatform('hybrid')) {
      await requestPermissions();
      await register();
    }

    try {
      const messaging = (await isSupportedMessaging())
        ? getMessaging()
        : undefined;
      const deviceId = await getId();
      const deviceInfo = await getInfo();
      const deviceToken = await getToken(messaging, vapidKey);
      setNotificationAccepted(true);
      return {
        error: null,
        message: 'Notification granted',
        deviceToken,
        deviceInfo,
        deviceId,
      };
    } catch (e: any) {
      return { error: e, message: 'catched error' };
    }
  };

  const getDevice = async (): Promise<DeviceResponse> => {
    try {
      const deviceId = await getId();
      const deviceInfo = await getInfo();
      return { error: null, message: '', deviceId, deviceInfo };
    } catch (e: any) {
      return { error: e, message: '' };
    }
  };

  let v = {
    acceptNotification,
    refuseNotification,
    notificationAccepted,
    notificationReceived,
    getDevice,
  };

  return (
    <NotificationContext.Provider value={v} {...props}>
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotification: () =>
  | NotificationContextInterface
  | undefined = () => React.useContext(NotificationContext);

/**
 *
 * Tests purpose
 */
export const NotificationConsumer: React.FC = (children, ...props: any) => {
  const [response, setResponse] = useState<NotificationResponse | undefined>();
  const [notificationReceived, setNotificationReceived] = useState<
    PushNotificationSchema | undefined
  >();
  const [device, setDevice] = useState<DeviceResponse | undefined>();
  return (
    <NotificationContext.Consumer {...props}>
      {(value) => (
        <>
          <div>{response?.error}</div>
          <div>{response?.message}</div>
          <div>{response?.deviceToken}</div>
          <div>{response?.deviceId}</div>
          <div>{response?.deviceInfo}</div>
          ---
          <div>{notificationReceived?.title}</div>
          ---
          <div>{device?.error}</div>
          <div>{device?.message}</div>
          <div>{device?.deviceId}</div>
          <div>{device?.deviceInfo}</div>
          <button
            onClick={async () => {
              setNotificationReceived({
                id: 'ID',
                data: {},
                title: 'NOTIFICATION_TITLE',
              });
            }}
          >
            notificationReceived
          </button>
          <button
            onClick={async () => {
              setResponse(await value?.refuseNotification());
            }}
          >
            refuseNotification
          </button>
          <button
            onClick={async () => {
              setResponse(await value?.acceptNotification());
            }}
          >
            acceptNotification
          </button>
          <button
            onClick={async () => {
              setDevice(await value?.getDevice());
            }}
          >
            getDevice
          </button>
        </>
      )}
    </NotificationContext.Consumer>
  );
};
