import React from 'react';
import { connect } from 'react-redux';
import { IonText } from '@ionic/react';
import { useHistory } from 'react-router';

import { Dispatch } from '../models/store';

import { homeLink } from '../constants/routes';

const HashTags: React.FC<HashtagsComponent> = ({
  setStateSearchValue,
  children,
}) => {
  const history = useHistory();

  const search = async (search: string) => {
    await setStateSearchValue(search.trim());
    history.push(homeLink);
  };

  const formatHashtag = (string: string) => {
    return string
      .split(/((?:^|\s)(?:#[-'0-9a-zÀ-ÿ]+))/gi)
      .filter(Boolean)
      .map((word, i) => {
        if (word.includes('#')) {
          return (
            <IonText
              key={i}
              onClick={() => search(word.replace('#', ''))}
              color="primary"
              className="pointer"
            >
              {word}
            </IonText>
          );
        } else {
          return <IonText key={i}>{word}</IonText>;
        }
      });
  };

  const text = formatHashtag(children);

  return <>{text}</>;
};

const mapDispatch = (dispatch: Dispatch) => ({
  setStateSearchValue: dispatch.search.setStateSearchValue,
});

export default connect(null, mapDispatch)(HashTags);
