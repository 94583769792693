import React, { useEffect, useState } from 'react';
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonToggle,
  IonLabel,
  IonList,
  IonItem,
  IonCard,
} from '@ionic/react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { RootState, Dispatch } from '../../../models/store';

import { sortByName } from '../../../utils/date';
import { editUserFavoriteCategories } from '../../../services/userService';
import { logEvent, setUserProperty } from '../../../services/firebaseApp';
import { useHistory } from 'react-router';
import Separator from '../../../components/Separator';
import GoBack from '../../../components/GoBack';

const FavoriteCategories: React.FC<FavoriteCategoriesComponent> = ({
  user,
  categories,
  setStateUser,
}) => {
  const [formParts, setFormParts] = useState<any[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<{
    [key: string]: boolean;
  } | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const {
    t,
    i18n: { language },
  } = useTranslation('translation', {
    keyPrefix: 'settings.profile',
  });
  const currentLanguage = language.split('-')[0];
  const history = useHistory();

  useEffect(() => {
    if (selectedCategories || !user?.categories) {
      return;
    }
    const userCategories = (categories?.list || []).reduce((acc, curr) => {
      return {
        ...acc,
        [curr.id]: !!(user?.categories || []).find(
          ({ id }: Category) => id === curr.id
        ),
      };
    }, {});
    setSelectedCategories(userCategories);
    // eslint-disable-next-line
  }, [user?.categories]);

  useEffect(() => {
    setFormParts([
      {
        label: 'Categories',
        fields: (categories?.list || [])
          .filter(({ children }: Category) => children?.length)
          .sort(sortByName)
          .map((category: Category) => ({
            label: category?.[`name_${currentLanguage}`] ?? category?.name,
            value: category.id,
          })),
      },
    ]);
    // eslint-disable-next-line
  }, [categories]);

  const getCategoryIds = (
    selectedCategories: { [key: string]: boolean } | null
  ) => {
    if (!selectedCategories) {
      return [];
    }
    return Object.entries(selectedCategories)
      .filter(([_, value]) => !!value)
      .map((key) => key[0]);
  };

  const getCategoryNamesAsString = (categoryIds: string[]) => {
    return (categories?.list || [])
      .filter(({ id }) => categoryIds.includes(id))
      .map(({ name }) =>
        name
          .toLowerCase()
          .replaceAll(/[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~ ]/g, '_')
      )
      .join(',');
  };
  const getCategoryNamesAsObject = (categoryIds: string[]) => {
    return (categories?.list || [])
      .filter(({ id }) => categoryIds.includes(id))
      .map(({ name }) => ({
        name: name
          .toLowerCase()
          .replaceAll(/[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~ ]/g, '_'),
      }));
  };

  const toggleCategory = async (categoryId: string, checked: boolean) => {
    if (!selectedCategories) {
      return;
    }
    setIsLoading(true);
    setSelectedCategories({ ...selectedCategories, [categoryId]: checked });
    const userFavoriteCategoryIds = getCategoryIds({
      ...selectedCategories,
      [categoryId]: checked,
    });

    const { categories: newCategories } = await editUserFavoriteCategories({
      categoryIds: userFavoriteCategoryIds,
    });
    setStateUser({ categories: newCategories });
    logEvent('favorites_categories', {
      content_type: 'settings',
      content_id: 'undefined',
      items: getCategoryNamesAsObject(userFavoriteCategoryIds),
    });
    setUserProperty(
      'favorites_categories',
      getCategoryNamesAsString(userFavoriteCategoryIds)
    );
    setIsLoading(false);
  };

  return !user ? null : (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <GoBack />
          <IonTitle>{t('title')}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense" translucent>
          <IonToolbar>
            <IonTitle size="large">{t('title')}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <div className="page-content">
          <form>
            {(formParts || []).map(
              ({ label, fields }: { label: string; fields: any[] }, i) => (
                <div key={i}>
                  <Separator title={label} />
                  <IonCard>
                    <IonList lines="inset">
                      {fields.map(
                        (
                          { label, value }: { label: string; value: string },
                          index
                        ) => (
                          <IonItem key={index} detail={false}>
                            <IonLabel>{label}</IonLabel>
                            <IonToggle
                              slot="end"
                              checked={!!selectedCategories?.[value]}
                              value={value}
                              onIonChange={(e) => {
                                !!selectedCategories?.[value] !==
                                  e.detail.checked &&
                                  !isLoading &&
                                  toggleCategory(
                                    e.detail.value,
                                    e.detail.checked
                                  );
                              }}
                              className="ion-no-padding"
                              disabled={isLoading}
                            />
                          </IonItem>
                        )
                      )}
                    </IonList>
                  </IonCard>
                </div>
              )
            )}
          </form>
        </div>
      </IonContent>
    </IonPage>
  );
};

const mapState = (state: RootState) => ({
  user: state.user.user,
  categories: state.categories.categories,
});

const mapDispatch = (dispatch: Dispatch) => ({
  setStateUser: dispatch.user.setStateUserAsync,
});

export default connect(mapState, mapDispatch)(FavoriteCategories);
