import React, { useEffect, useState } from 'react';
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonTitle,
  IonContent,
  IonButton,
  IonToggle,
  IonLabel,
  IonList,
  IonItem,
  IonCard,
  IonItemSliding,
  IonItemOptions,
  IonItemOption,
  IonIcon,
  IonNote,
  useIonAlert,
  IonGrid,
  IonRow,
  IonCol,
} from '@ionic/react';
import { connect } from 'react-redux';
import { FiArrowLeft } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/react';

import { settingsLink } from '../../../constants/routes';
import { addUserDevice } from '../../../services/userService';
import { Dispatch, RootState } from '../../../models/store';
import { pauseCircleOutline, removeCircleOutline } from 'ionicons/icons';
import { useHistory } from 'react-router';
import Separator from '../../../components/Separator';
import GoBack from '../../../components/GoBack';

const RecurringDonations: React.FC<RecurringDonationsComponent> = ({
  user,
  associations,
  setStateUser,
  setStateToast,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'settings.recurringDonations',
  });
  const history = useHistory();
  const [formParts, setFormParts] = useState<any[]>([]);
  const [presentLogOut] = useIonAlert();

  useEffect(() => {
    setFormParts([
      {
        label: 'Recurring Donations',
        fields: (associations?.list || []).map((association) => ({
          label: association.name,
          amount: 10,
          value: association.id,
        })),
      },
    ]);
    // eslint-disable-next-line
  }, [associations]);

  const toggleRecurringDonation = async () => {
    presentLogOut({
      header: t('Are you going?'),
      buttons: [
        t('Cancel'),
        {
          text: t('Log me out'),
          handler: async () => {
            /* try {
              await logOut();
              onLoggedOut && onLoggedOut(user);
            } catch (e) {
              onError && onError(e);
            } */
          },
        },
      ],
    });
    /* try {
      const { device: newDevice } = await cancelRecurringDonation();
      setStateUser({ devices: [...(user.devices || []), newDevice] });
    } catch (e: any) {
      setStateToast({
        isOpen: true,
        duration: 0,
        type: 'warning',
        message: e,
      });
      Sentry.captureException(e);
    } */
  };

  return !user ? null : (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <GoBack />
          <IonTitle>{t('title')}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense" translucent>
          <IonToolbar>
            <IonTitle size="large">{t('title')}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <div className="page-content">
          <form>
            {formParts.map(
              ({ label, fields }: { label: string; fields: any[] }, i) => (
                <div key={i}>
                  <Separator title={label} />
                  <IonCard>
                    <IonList lines="inset">
                      {fields.map(
                        (
                          { label, amount }: { label: string; amount: number },
                          index
                        ) => (
                          <IonItemSliding key={index}>
                            <IonItem detail={false}>
                              <IonLabel>
                                {label}
                                <IonNote slot="end">{amount}€</IonNote>
                              </IonLabel>
                              <IonToggle
                                slot="end"
                                checked={true}
                                onIonChange={toggleRecurringDonation}
                                className="ion-no-padding"
                              />
                            </IonItem>

                            <IonItemOptions side="end">
                              <IonItemOption
                                color="danger"
                                onClick={() => console.log('delete clicked')}
                              >
                                <IonIcon
                                  icon={removeCircleOutline}
                                  size="large"
                                />
                              </IonItemOption>
                              <IonItemOption
                                onClick={() => console.log('pause clicked')}
                              >
                                <IonIcon
                                  icon={pauseCircleOutline}
                                  size="large"
                                />
                              </IonItemOption>
                            </IonItemOptions>
                          </IonItemSliding>
                        )
                      )}
                    </IonList>
                  </IonCard>
                </div>
              )
            )}
          </form>
        </div>
      </IonContent>
    </IonPage>
  );
};

const mapState = (state: RootState) => ({
  user: state.user.user,
  associations: state.associations.associations,
});

const mapDispatch = (dispatch: Dispatch) => ({
  setStateUser: dispatch.user.setStateUserAsync,
  setStateToast: dispatch.toast.setStateToast,
});

export default connect(mapState, mapDispatch)(RecurringDonations);
