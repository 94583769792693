import { gql } from '@apollo/client';
import imageFragment from '../imageFragment';

export default gql`
  fragment categoryFragment on Category {
    id
    name
    name_en
    name_fr
    code
    numberOfAssociations
    illustration {
      ...imageFragment
    }
    children {
      id
      name
      name_en
      name_fr
      code
      numberOfAssociations
      illustration {
        ...imageFragment
      }
    }
  }

  ${imageFragment}
`;
