import React, { useEffect, useState } from 'react';
import { IonModal, isPlatform } from '@ionic/react';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { Virtual, Keyboard } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { RootState, Dispatch } from '../../models/store';
import { logEvent } from '../../services/firebaseApp';
import AssociationContent from './AssociationContent';
import { associationLink, homeLink } from '../../constants/routes';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/virtual';
import '@ionic/react/css/ionic-swiper.css';
import ModalNavigation from '../../components/ModalNavigation';
import { StatusBar } from '@capacitor/status-bar';

const AssociationModal: React.FC<AssociationModalComponent> = ({
  setStateAssociation,
  associationModalList,
  associations,
  association,
}) => {
  const history = useHistory();
  const location = useLocation();
  const [swiper, setSwiper] = useState<any | undefined>(undefined);
  const [list, setList] = useState<Association[] | null>(null);
  const [currentIndex, setCurrentIndex] = useState<number | undefined>(
    undefined
  );

  useEffect(() => {
    if (!associationModalList) {
      setList(associations?.list);
      return;
    }
    setList(associationModalList);
    // eslint-disable-next-line
  }, [associationModalList, associations?.list]);

  useEffect(() => {
    if (!list) {
      return;
    }
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.has('edit')) {
      return;
    }
    if (!queryParams.has('association')) {
      setStateAssociation(null);
      return;
    }
    if (queryParams.get('association') === 'NEW') {
      history.push(homeLink);
      return;
    }
    const association = (associations?.list || []).find(
      ({ code }) => code === queryParams.get('association')
    );
    if (!association) {
      history.push(homeLink);
      return;
    }
    setStateAssociation(association);
    logEvent('view_item', {
      content_type: 'association',
      content_id: association?.code || 'undefined',
    });
    // eslint-disable-next-line
  }, [list, association, location]);

  useEffect(() => {
    if (!association || !list) {
      return;
    }
    const currentIndex = (list || []).findIndex(
      ({ code }: Association) => code === association.code
    );
    setCurrentIndex(currentIndex);
    // eslint-disable-next-line
  }, [association, list]);

  const detectChange = async (swiper: any) => {
    const currentAssociation = (list || [])[swiper.activeIndex];
    history.push(associationLink(currentAssociation.code));
  };

  const closeModal = () => {
    if (isPlatform('hybrid')) {
      StatusBar.show();
    }
    const queryParams = new URLSearchParams(location.search);
    queryParams.delete('association');
    history.replace({
      search: queryParams.toString(),
    });
  };

  return (
    <IonModal
      isOpen={association !== null}
      onWillDismiss={closeModal}
      onWillPresent={() => {
        isPlatform('hybrid') && StatusBar.hide();
      }}
      className="content-modal"
    >
      <ModalNavigation closeModal={closeModal} swiper={swiper} />
      <Swiper
        modules={[Virtual, Keyboard]}
        onInit={(swiper) => setSwiper(swiper)}
        onSlideChangeTransitionEnd={detectChange}
        initialSlide={currentIndex}
        keyboard={{ enabled: true }}
        virtual
      >
        {(list || []).map((association, index) => (
          <SwiperSlide key={index} virtualIndex={index}>
            <AssociationContent association={association} list={list} />
          </SwiperSlide>
        ))}
      </Swiper>
    </IonModal>
  );
};

const mapState = (state: RootState) => ({
  associations: state.associations.associations,
  associationModalList: state.associations.associationModalList,
  association: state.associations.association,
});

const mapDispatch = (dispatch: Dispatch) => ({
  setStateAssociation: dispatch.associations.setStateAssociation,
});

export default connect(mapState, mapDispatch)(AssociationModal);
