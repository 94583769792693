import { gql } from '@apollo/client';
import userFragment from './userFragment';

export const getUserByEmail: any = gql`
  query getUserByEmail($email: String!) {
    getUserByEmail(email: $email) {
      ...userFragment
    }
  }

  ${userFragment}
`;
