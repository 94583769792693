import { is, clone } from 'ramda';

type ObjectInterface = { __typename: string } | any;

export const removeTypenames: Function = (obj: ObjectInterface) => {
  if (!is(Object, obj)) return obj;
  const objClone = clone(obj);

  Object.keys(objClone).forEach((key) => {
    if (key === '__typename') {
      delete objClone[key];
    } else if (is(Object, objClone[key])) {
      objClone[key] = removeTypenames(objClone[key]);
    }
  });

  return objClone;
};
