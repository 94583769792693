import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import detector from 'i18next-browser-languagedetector';

import en from './en';
import fr from './fr';

const resources = { en, fr };
const isWarningEnvironment = process.env.REACT_APP_ENV === 'dev';

i18n
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    fallbackLng: 'en',
    react: {
      useSuspense: false,
    },
    resources,
    saveMissing: true,
    saveMissingTo: 'all',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    parseMissingKeyHandler: (key) =>
      isWarningEnvironment ? `👹👹${key}👹👹` : key,
  });

export default i18n;
