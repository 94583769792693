import { gql } from '@apollo/client';
import associationFragment from '../association/associationFragment';

export const addFavorite: any = gql`
  mutation addFavorite($associationId: ID!) {
    addFavorite(associationId: $associationId) {
      ...associationFragment
    }
  }

  ${associationFragment}
`;
