import React, { useEffect, useState } from 'react';
import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
  IonImg,
  IonIcon,
  IonLabel,
  IonList,
  IonItem,
  isPlatform,
} from '@ionic/react';
import { FiArrowLeft, FiStar } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';
import { logoFacebook, logoInstagram, logoLinkedin } from 'ionicons/icons';
import { FiGlobe } from 'react-icons/fi';
import { Browser } from '@capacitor/browser';

import { getAbout } from '../../../services/aboutService';
import { useHistory } from 'react-router';
import Share from '../../../components/Share';
import { dynamicLink, rootLink } from '../../../constants/routes';
import GoBack from '../../../components/GoBack';

const About: React.FC = () => {
  const [about, setAbout] = useState<string>('');
  const {
    t,
    i18n: { language },
  } = useTranslation('translation', {
    keyPrefix: 'settings.about',
  });
  const currentLanguage = language.split('-')[0];
  const history = useHistory();

  useEffect(() => {
    (async () => {
      if (about) {
        return;
      }
      const { about: aboutResult }: any = await getAbout();
      setAbout(aboutResult);
    })();
    // eslint-disable-next-line
  }, []);

  const rateCharitable = () => {
    if (isPlatform('ios')) {
      Browser.open({
        url: 'itms-apps://itunes.apple.com/us/app/domainsicle-domain-name-search/id1538446539?ls=1&mt=8',
      }); // or itms://
    } else if (isPlatform('android')) {
      Browser.open({
        url: 'market://details?id=com.nicolassandron.charitable',
      });
    } else {
      Browser.open({
        url: 'https://apps.apple.com/bj/app/charitable/id1538446539',
      });
    }
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <GoBack />
          <IonTitle>{t('title')}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense" translucent>
          <IonToolbar>
            <IonTitle size="large">{t('title')}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <div className="page-content">
          <IonGrid className="ion-margin-top ion-padding">
            <IonRow>
              <IonCol>
                <div
                  className={`html lng-${currentLanguage}`}
                  dangerouslySetInnerHTML={{
                    __html: about,
                  }}
                />
              </IonCol>
            </IonRow>
          </IonGrid>

          <IonCard>
            <IonList lines="full">
              <IonItem
                onClick={rateCharitable}
                className="ion-align-items-center"
              >
                <IonButtons
                  slot="start"
                  color="primary"
                  className="ion-padding-vertical"
                >
                  <FiStar />
                </IonButtons>
                <IonLabel>{t('Rate us')}</IonLabel>
              </IonItem>
              <IonItem
                onClick={() =>
                  Browser.open({
                    url: 'https://facebook.com/charitable.together',
                  })
                }
                className="ion-align-items-center"
              >
                <IonButtons
                  slot="start"
                  color="primary"
                  className="ion-padding-vertical"
                >
                  <IonIcon icon={logoFacebook} />
                </IonButtons>
                <IonLabel>{t('Like us on Facebook')}</IonLabel>
              </IonItem>
              <IonItem
                onClick={() =>
                  Browser.open({
                    url: 'https://instagram.com/be.charitable',
                  })
                }
                className="ion-align-items-center"
              >
                <IonButtons
                  slot="start"
                  color="primary"
                  className="ion-padding-vertical"
                >
                  <IonIcon icon={logoInstagram} />
                </IonButtons>
                <IonLabel>{t('Follow us on Instagram')}</IonLabel>
              </IonItem>
              <IonItem
                onClick={() =>
                  Browser.open({
                    url: 'https://www.linkedin.com/company/be-charitable',
                  })
                }
                className="ion-align-items-center"
              >
                <IonButtons
                  slot="start"
                  color="primary"
                  className="ion-padding-vertical"
                >
                  <IonIcon icon={logoLinkedin} />
                </IonButtons>
                <IonLabel>{t('Follow us on LinkedIn')}</IonLabel>
              </IonItem>
              <IonItem
                onClick={() => Browser.open({ url: 'https://charitable.be' })}
                className="ion-align-items-center"
              >
                <IonButtons
                  slot="start"
                  color="primary"
                  className="ion-padding-vertical"
                >
                  <FiGlobe />
                </IonButtons>
                <IonLabel>{t('Visit our website')}</IonLabel>
              </IonItem>
            </IonList>
          </IonCard>

          <Share
            type="button"
            fill="outline"
            shape="round"
            title={t('Join Charitable')}
            text={t('Contribute to the good')}
            buttonText={t('Invite friends to join')}
            url={`${dynamicLink}${rootLink}`}
          />

          <IonGrid className="ion-no-padding">
            <IonRow className="ion-justify-content-center ion-text-center ion-margin-bottom">
              <IonCol size="auto">
                <small>{`v ${process.env.REACT_APP_VERSION}`}</small>
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default About;
