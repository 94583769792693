import { createModel } from '@rematch/core';

import { delay } from './utils';
import { RootState, RootModel } from './store';

const initialState: SearchState = {
  value: '',
  suggestions: {
    categories: null,
    associations: null,
    posts: null,
    connections: null,
  },
};

export const search = createModel<RootModel>()({
  state: initialState,
  reducers: {
    setStateSearchValue: (state, payload: string) => ({
      ...state,
      value: payload,
    }),
    setStateSearchSuggestions: (state, payload: any) => ({
      ...state,
      suggestions: payload,
    }),
  },
  effects: (dispatch) => {
    const { search } = dispatch;
    return {
      async setStateSearchValueAsync(payload: string): Promise<void> {
        await delay(500);
        search.setStateSearchValue(payload);
      },
    };
  },
});
