import { gql } from '@apollo/client';

export const getSearchSuggestions: any = gql`
  query getSearchSuggestions($type: String!, $searchValue: String!) {
    getSearchSuggestions(type: $type, searchValue: $searchValue) {
      categories {
        id
        code
        name
        name_fr
        name_en
        numberOfAssociations
        illustration {
          sm
        }
      }
      associations {
        id
        code
        name
        logo {
          sm
        }
      }
      posts {
        id
        title
        illustration {
          sm
        }
      }
      connections {
        id
        name
        illustration {
          sm
        }
      }
    }
  }
`;
