import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { RootState, Dispatch } from '../../models/store';

import CardList from '../CardList';
import Card from './lists/Card';

const Categories: React.FC<CategoriesComponent> = ({
  categories,
  display,
  toggleDisplay,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'categories',
  });

  const getList = () =>
    (categories?.list || []).filter(
      ({ children }: Category) => children?.length
    );

  return (
    <CardList
      display={display}
      title={t('Categories')}
      list={getList()}
      toggleDisplay={toggleDisplay}
      Card={Card}
      slidesPerView={[1.5, 2.5, 3.5, 3.5]}
      freeMode={true}
      pagination={{
        dynamicBullets: true,
      }}
    />
  );
};

const mapState = (state: RootState) => ({
  categories: state.categories.categories,
});

const mapDispatch = (dispatch: Dispatch) => ({
  setStateCategory: dispatch.categories.setStateCategory,
});

export default connect(mapState, mapDispatch)(Categories);
