import { gql } from '@apollo/client';

export const createPaymentSheet: any = gql`
  mutation createPaymentSheet($type: String!) {
    createPaymentSheet(type: $type) {
      paymentIntent
      ephemeralKey
      customer
    }
  }
`;
