import gql from 'graphql-tag';
import associationFragment from './associationFragment';

export const addAssociation: any = gql`
  mutation addAssociation($association: AssociationInput!) {
    addAssociation(association: $association) {
      ...associationFragment
    }
  }

  ${associationFragment}
`;
