import { init, RematchDispatch, RematchRootState } from '@rematch/core';
import { models, RootModel as Models } from './models';

export const store = init({
  models,
});

export type Store = typeof store;
export type RootModel = Models;
export type Dispatch = RematchDispatch<RootModel> | any;
export type RootState = RematchRootState<RootModel>;
