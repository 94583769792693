import { createModel } from '@rematch/core';
import { delay } from './utils';
import { RootState, RootModel } from './store';

const initialState: AssociationState = {
  donateAssociation: null,
  association: null,
  associationForm: null,
  associations: {
    status: 'initializing',
    list: null,
  },
  associationModalList: null,
};

export const associations = createModel<RootModel>()({
  state: initialState,
  reducers: {
    setStateDonateAssociation: (state, payload: Association) => ({
      ...state,
      donateAssociation: payload,
    }),
    setStateAssociation: (state, payload: Association) => ({
      ...state,
      association: payload,
    }),
    setStateAssociations: (state, payload: Partial<AssociationsInterface>) => ({
      ...state,
      associations: { ...state.associations, ...payload },
    }),
    setStateAssociationsList: (state, payload: Association[]) => ({
      ...state,
      associations: { ...state.associations, list: payload },
    }),
    setStateAssociationModalList: (state, payload: Association[]) => ({
      ...state,
      associationModalList: payload,
    }),
    setStateAssociationForm: (state, payload: Association) => ({
      ...state,
      associationForm: payload,
    }),
  },
  effects: (dispatch) => {
    const { associations } = dispatch;
    return {
      async setStateAssociationAsync(payload: Association): Promise<void> {
        await delay(0);
        associations.setStateAssociation(payload);
      },
    };
  },
});
