import gql from 'graphql-tag';
import postFragment from './postFragment';

export const editPost = gql`
  mutation editPost($id: ID!, $post: PostInput!) {
    editPost(id: $id, post: $post) {
      ...postFragment
    }
  }

  ${postFragment}
`;
