import React from 'react';
import { IonButton, IonCol, IonGrid, IonRow } from '@ionic/react';
import { FiArrowLeft, FiArrowRight, FiX } from 'react-icons/fi';

const ModalNavigation: React.FC<ModalNavigationComponent> = ({
  closeModal,
  swiper,
}) => {
  return (
    <IonGrid className="modal-navigation">
      <IonRow className="ion-justify-content-between">
        <IonCol size="auto">
          <IonButton
            className="type-icon"
            onClick={() => closeModal()}
            color="light"
            shape="round"
            fill="solid"
            size="small"
          >
            <FiX size="1.3em" />
          </IonButton>
        </IonCol>
        <IonCol size="auto">
          <IonButton
            className="type-icon"
            onClick={() => swiper.slidePrev()}
            color="light"
            shape="round"
            fill="solid"
            size="small"
          >
            <FiArrowLeft size="1.3em" />
          </IonButton>
          <IonButton
            className="type-icon"
            onClick={() => swiper.slideNext()}
            color="light"
            shape="round"
            fill="solid"
            size="small"
          >
            <FiArrowRight size="1.3em" />
          </IonButton>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default ModalNavigation;
