import { useEffect, useState, useRef } from 'react';
import { useForm, Controller } from 'react-hook-form';

import {
  createAssociation,
  updateAssociation,
  editAssociationPictureBase64,
  searchAssociations,
} from '../../services/associationService';

import { Dispatch, RootState } from '../../models/store';
import { connect } from 'react-redux';
import {
  IonAvatar,
  IonButton,
  IonButtons,
  IonCard,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonImg,
  IonInput,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonList,
  IonModal,
  IonPage,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonText,
  IonTextarea,
  IonTitle,
  IonToolbar,
  isPlatform,
} from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { showError } from '../../utils/form';
import {
  associationLink,
  associationsLink,
  homeLink,
  loginLink,
} from '../../constants/routes';
import { FiArrowLeft, FiX } from 'react-icons/fi';
import { Editor } from 'react-draft-wysiwyg';
import {
  EditorState,
  ContentState,
  convertFromRaw,
  convertToRaw,
} from 'draft-js';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { formatDate } from '../../utils/date';
import CropperModal from '../../components/CropperModal';
import { logEvent } from '../../services/firebaseApp';
import { StatusBar } from '@capacitor/status-bar';

const aspectRatios = {
  logo: 1 / 1,
  banner: 8 / 5,
  illustration: 8 / 5,
  poster: 3 / 4,
};

function isJson(str: any) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

const AssociationForm: React.FC<AssociationFormComponent> = ({
  user,
  associationForm,
  associations,
  categories,
  countries,
  setStateAssociations,
  setStateAssociation,
  setStateAssociationForm,
  setStateToast,
}) => {
  const history = useHistory();
  const { t } = useTranslation('translation', {
    keyPrefix: 'associationForm',
  });
  const [description, setDescription] = useState<string | null>(null);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [cropData, setCropData] = useState<CropData | null>(null);

  const {
    formState: { errors },
    control,
    setValue,
    handleSubmit,
  } = useForm();

  const onEditorStateChange = (state: any) => {
    setEditorState(state);
    const editorJSON = JSON.stringify(
      convertToRaw(editorState.getCurrentContent())
    );
    setDescription(editorJSON);
  };

  useEffect(() => {
    if (!user?.info?.email) {
      const queryParams = new URLSearchParams(location.search);
      queryParams.delete('association');
      queryParams.delete('edit');
      setStateAssociationForm(null);
      history.push(loginLink);
    }
    // eslint-disable-next-line
  }, [user, location]);

  useEffect(() => {
    if (associationForm) {
      logEvent('view_item', {
        content_type: 'edit-association',
        content_id: associationForm?.code || 'undefined',
      });
      return;
    }
    const queryParams = new URLSearchParams(location.search);
    if (
      queryParams.has('association') &&
      queryParams.get('association') === 'NEW' &&
      queryParams.has('edit')
    ) {
      setStateAssociationForm({});
      logEvent('view_item', {
        content_type: 'new-association',
        content_id: 'NEW',
      });
      return;
    }
    // eslint-disable-next-line
  }, [location]);

  useEffect(() => {
    setValue('name', associationForm?.name);
    setValue('code', associationForm?.code);
    setValue('tagline', associationForm?.tagline);
    setValue('website', associationForm?.website);
    setValue('donateUrl', associationForm?.donateUrl);
    setValue(
      'categoryIds',
      (associationForm?.categories || []).map(({ id }) => id)
    );
    setValue(
      'countryCodes',
      (associationForm?.countries || []).map(({ code }) => code)
    );
    setValue(
      'creationDate',
      formatDate(associationForm?.creationDate || '', 'yyyy-MM-dd')
    );
    setValue('address.street', associationForm?.address?.street);
    setValue('address.number', associationForm?.address?.number);
    setValue('address.postcode', associationForm?.address?.postcode);
    setValue('address.city', associationForm?.address?.city);
    setValue('address.country', associationForm?.address?.country);
    const richDescription = isJson(associationForm?.description)
      ? convertFromRaw(JSON.parse(associationForm?.description ?? ''))
      : ContentState.createFromText(associationForm?.description || '');
    setEditorState(EditorState.createWithContent(richDescription));

    setDescription(
      JSON.stringify(
        convertToRaw(
          EditorState.createWithContent(richDescription).getCurrentContent()
        )
      )
    );
  }, [associationForm]);

  const onSubmit = async (formData: any) => {
    try {
      let association;

      if (associationForm?.id) {
        association = (
          await updateAssociation({
            id: associationForm.id,
            association: { ...formData, description },
          })
        ).association;
        setStateToast({
          isOpen: true,
          type: 'success',
          position: 'top',
          message: `Association edited successfully`,
        });
      } else {
        association = (
          await createAssociation({
            association: { ...formData, description },
          })
        ).association;

        setStateToast({
          isOpen: true,
          type: 'success',
          position: 'top',
          message: `Association added successfully`,
        });
      }
      const { associations: list } = await searchAssociations({
        searchValue: '',
        categoryCode: '',
      });
      setStateAssociations({ list, status: '' });
      await setStateAssociation(association);
      history.push(associationLink(association?.code));
      closeModal();
    } catch (e) {
      setStateToast({
        isOpen: true,
        position: 'top',
        message: `Error adding association, please verify and try again`,
      });
    }
  };

  const onFileChange = (
    e: any,
    type: 'banner' | 'poster' | 'logo' | 'illustration'
  ) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setCropData({
        url: reader.result as any,
        type,
        aspectRatio: aspectRatios[type],
      });
    };
    reader.readAsDataURL(files[0]);
  };

  const onCropConfirm = async (base64Image: string, cropData: CropData) => {
    const { file, association } = await editAssociationPictureBase64({
      file: base64Image,
      id: associationForm?.id || 'NEW',
      type: cropData.type,
    });
    setStateAssociationForm({
      ...association,
      [cropData.type]: file.locations,
    });
    setCropData(null);
  };

  const onCropCancel = () => setCropData(null);

  const closeModal = () => {
    if (isPlatform('hybrid')) {
      StatusBar.show();
    }
    const queryParams = new URLSearchParams(location.search);
    queryParams.delete('edit');
    history.replace({
      search: queryParams.toString(),
    });
    setStateAssociationForm(null);
    if (associationForm) {
      history.push(associationLink(associationForm?.code));
    }
  };

  return (
    <IonModal
      isOpen={associationForm !== null}
      onWillDismiss={closeModal}
      onWillPresent={() => {
        isPlatform('hybrid') && StatusBar.hide();
      }}
      backdropDismiss={false}
      className="content-modal"
    >
      <IonGrid className="modal-navigation">
        <IonRow className="ion-justify-content-between">
          <IonCol size="auto">
            <IonButton
              className="type-icon"
              onClick={() => closeModal()}
              color="light"
              shape="round"
              fill="solid"
              size="small"
            >
              <FiX size="1.3em" />
            </IonButton>
          </IonCol>
        </IonRow>
      </IonGrid>
      <IonContent>
        <CropperModal
          cropData={cropData}
          onCropConfirm={onCropConfirm}
          onCropCancel={onCropCancel}
        />
        <IonGrid className="ion-padding ion-margin-top-3x-lg-up">
          <IonRow>
            <IonCol
              sizeXs="12"
              sizeSm="12"
              sizeMd="6"
              className="ion-no-padding"
            >
              <IonCard className="ion-margin-top-3x-sm-up">
                <IonGrid>
                  <IonRow className="ion-justify-content-center ion-align-items-center">
                    <IonCol size="auto">
                      <div className="upload-btn-wrapper">
                        {!associationForm?.banner?.md &&
                        !associationForm?.illustration?.md ? (
                          <IonButton fill="clear" size="small">
                            {t('Banner')}
                          </IonButton>
                        ) : (
                          <IonImg
                            src={`${
                              associationForm?.banner?.lg ||
                              associationForm?.illustration?.lg
                            }?${Date.now()}`}
                          />
                        )}
                        <input
                          type="file"
                          accept="image/png, image/gif, image/jpeg"
                          onChange={(e) => onFileChange(e, 'banner')}
                        />
                      </div>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonCard>
              <IonCard className="ion-margin-top">
                <IonGrid>
                  <IonRow className="ion-justify-content-center ion-align-items-center">
                    <IonCol size="auto">
                      <div className="upload-btn-wrapper pointer">
                        {!associationForm?.poster?.sm ? (
                          <IonButton fill="clear" size="small">
                            {t('Poster')}
                          </IonButton>
                        ) : (
                          <IonImg
                            src={`${associationForm?.poster?.sm}?${Date.now()}`}
                          />
                        )}
                        <input
                          type="file"
                          accept="image/png, image/gif, image/jpeg"
                          onChange={(e) => onFileChange(e, 'poster')}
                        />
                      </div>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonCard>
            </IonCol>
            <IonCol
              sizeXs="12"
              sizeSm="12"
              sizeMd="6"
              className="ion-no-padding full-height-container"
            >
              <IonGrid className="ion-padding-horizontal">
                <IonRow>
                  <IonCol>
                    <IonAvatar className="avatar-lg" color="primary">
                      <div className="upload-btn-wrapper">
                        {!associationForm?.logo?.sm ? (
                          <IonButton fill="clear" size="small">
                            {t('Logo')}
                          </IonButton>
                        ) : (
                          <IonImg
                            src={`${associationForm?.logo?.sm}?${Date.now()}`}
                          />
                        )}
                        <input
                          type="file"
                          accept="image/png, image/gif, image/jpeg"
                          onChange={(e) => onFileChange(e, 'logo')}
                        />
                      </div>
                    </IonAvatar>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <Controller
                      control={control}
                      name="name"
                      defaultValue={associationForm?.name}
                      render={({ field: { onChange, value } }) => (
                        <IonInput
                          type="text"
                          placeholder="Name"
                          value={value}
                          onIonChange={onChange}
                          className="form-item-input h1 ion-no-padding"
                          required
                        />
                      )}
                    />
                    {showError(errors, 'name')}
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <Controller
                      control={control}
                      name="code"
                      defaultValue={associationForm?.name}
                      render={({ field: { onChange, value } }) => (
                        <IonInput
                          type="text"
                          placeholder="Code"
                          value={value}
                          onIonChange={onChange}
                          className="form-item-input"
                          required
                        />
                      )}
                    />
                    {showError(errors, 'code')}
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <Controller
                      control={control}
                      name="tagline"
                      defaultValue={associationForm?.tagline}
                      render={({ field: { onChange, value } }) => (
                        <IonInput
                          type="text"
                          placeholder="Tagline"
                          value={value}
                          onIonChange={onChange}
                          className="form-item-input h6 ion-primary ion-no-padding b"
                          required
                        />
                      )}
                    />
                    {showError(errors, 'tagline')}
                  </IonCol>
                </IonRow>
                <IonRow className="ion-justify-content-between ion-align-items-center">
                  <IonCol>
                    <IonLabel color="medium">{t('Creation date')}</IonLabel>
                  </IonCol>
                  <IonCol>
                    <Controller
                      control={control}
                      name="creationDate"
                      render={({ field: { onChange, value } }) => (
                        <IonInput
                          type="date"
                          placeholder="Founded in"
                          value={value}
                          onIonChange={onChange}
                          required
                        />
                      )}
                    />
                    {showError(errors, 'creationDate')}
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <Controller
                      control={control}
                      name="donateUrl"
                      defaultValue={associationForm?.donateUrl}
                      render={({ field: { onChange, value } }) => (
                        <IonInput
                          type="text"
                          placeholder="Donate Url"
                          value={value}
                          onIonChange={onChange}
                          className="form-item-input ion-primary"
                          required
                        />
                      )}
                    />
                    {showError(errors, 'donateUrl')}
                    <Controller
                      control={control}
                      name="website"
                      defaultValue={associationForm?.website}
                      render={({ field: { onChange, value } }) => (
                        <IonInput
                          type="text"
                          placeholder="Website"
                          value={value}
                          onIonChange={onChange}
                          className="form-item-input ion-primary"
                          required
                        />
                      )}
                    />
                    {showError(errors, 'website')}
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonCol>
          </IonRow>
        </IonGrid>
        <hr />
        <IonGrid className="ion-no-padding ion-padding-vertical">
          <IonRow>
            <IonCol
              sizeXs="12"
              sizeSm="12"
              sizeMd="6"
              className="ion-no-padding"
            >
              <IonGrid className="ion-padding ion-padding-top-0x-md-up">
                <IonRow className="ion-justify-content-between  ion-align-items-center">
                  <IonCol size="auto" className="ion-no-padding">
                    <h4 className="ion-margin-bottom">
                      <IonText color="dark">{t('About')}</IonText>
                    </h4>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <Controller
                      control={control}
                      name="description"
                      defaultValue={associationForm?.description}
                      render={({ field: { onChange, value } }) => (
                        <Editor
                          placeholder="Description"
                          editorState={editorState}
                          onEditorStateChange={onEditorStateChange}
                        />
                      )}
                    />
                    {showError(errors, 'description')}
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonCol>
            <IonCol
              sizeXs="12"
              sizeSm="12"
              sizeMd="6"
              className="ion-no-padding"
            >
              <IonGrid className="ion-padding">
                <IonRow className="ion-justify-content-between  ion-align-items-center">
                  <IonCol size="auto" className="ion-no-padding-vertical">
                    <h4 className="ion-margin-bottom">
                      <IonText color="dark">{t('Categories')}</IonText>
                    </h4>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <Controller
                      control={control}
                      name="categoryIds"
                      defaultValue={(associationForm?.categories || []).map(
                        ({ id }) => id
                      )}
                      render={({ field: { onChange, value } }) => (
                        <IonSelect
                          placeholder={t('Categories')}
                          value={value}
                          multiple
                          onIonChange={onChange}
                        >
                          {(categories?.list || []).map(
                            ({ id: name, name: label }, i) => (
                              <IonSelectOption
                                value={name}
                                key={`${name}-${i}`}
                              >
                                {label}
                              </IonSelectOption>
                            )
                          )}
                        </IonSelect>
                      )}
                    />
                    {showError(errors, 'categoryIds')}
                  </IonCol>
                </IonRow>
                <IonRow className="ion-justify-content-between  ion-align-items-center">
                  <IonCol size="auto" className="ion-no-padding">
                    <h4 className="ion-margin-bottom">
                      <IonText color="dark">{t('Countries')}</IonText>
                    </h4>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <Controller
                      control={control}
                      name="countryCodes"
                      defaultValue={(associationForm?.countries || []).map(
                        ({ code }) => code
                      )}
                      render={({ field: { onChange, value } }) => (
                        <IonSelect
                          value={value}
                          multiple
                          onIonChange={onChange}
                          placeholder={t('Countries')}
                        >
                          {(countries?.list || []).map(
                            ({ code: name, name: label }, i) => (
                              <IonSelectOption
                                value={name}
                                key={`${name}-${i}`}
                              >
                                {label}
                              </IonSelectOption>
                            )
                          )}
                        </IonSelect>
                      )}
                    />
                    {showError(errors, 'countryCodes')}
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonCol>
          </IonRow>
        </IonGrid>

        <IonGrid className="ion-padding">
          <IonRow className="ion-justify-content-between ion-align-items-center">
            <IonCol size="auto" className="ion-no-padding">
              <h4 className="ion-margin-bottom">
                <IonText color="dark">Address</IonText>
              </h4>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonGrid className="ion-no-padding ion-justify-content-center">
                <IonRow className="row-grow ion-justify-content-center ion-align-items-center">
                  <IonCol>
                    <form className="form" onSubmit={handleSubmit(onSubmit)}>
                      <IonCard>
                        <IonList lines="inset">
                          <IonItem detail={false} className="form-item-input">
                            <IonLabel position="fixed">{t('Street')}</IonLabel>
                            <Controller
                              control={control}
                              name="address.street"
                              defaultValue={associationForm?.address?.street}
                              render={({ field: { onChange, value } }) => (
                                <IonInput
                                  type="text"
                                  value={value}
                                  onIonChange={onChange}
                                  className="form-item-input"
                                />
                              )}
                            />
                            {showError(errors, 'address.street')}
                          </IonItem>
                          <IonItem detail={false} className="form-item-input">
                            <IonLabel position="fixed">{t('Number')}</IonLabel>
                            <Controller
                              control={control}
                              name="address.number"
                              defaultValue={associationForm?.address?.number}
                              render={({ field: { onChange, value } }) => (
                                <IonInput
                                  type="text"
                                  value={value}
                                  onIonChange={onChange}
                                  className="form-item-input"
                                />
                              )}
                            />
                            {showError(errors, 'address.number')}
                          </IonItem>
                          <IonItem detail={false} className="form-item-input">
                            <IonLabel position="fixed">
                              {t('Postcode')}
                            </IonLabel>
                            <Controller
                              control={control}
                              name="address.postcode"
                              defaultValue={associationForm?.address?.postcode}
                              render={({ field: { onChange, value } }) => (
                                <IonInput
                                  type="text"
                                  value={value}
                                  onIonChange={onChange}
                                  className="form-item-input"
                                />
                              )}
                            />
                            {showError(errors, 'address.postcode')}
                          </IonItem>
                          <IonItem detail={false} className="form-item-input">
                            <IonLabel position="fixed">{t('City')}</IonLabel>
                            <Controller
                              control={control}
                              name="address.city"
                              defaultValue={associationForm?.address?.city}
                              render={({ field: { onChange, value } }) => (
                                <IonInput
                                  type="text"
                                  value={value}
                                  onIonChange={onChange}
                                  className="form-item-input"
                                />
                              )}
                            />
                            {showError(errors, 'address.city')}
                          </IonItem>

                          <IonItem detail={false} className="form-item-input">
                            <IonLabel position="fixed">{t('Country')}</IonLabel>
                            <Controller
                              control={control}
                              name="address.country"
                              defaultValue={associationForm?.address?.country}
                              render={({ field: { onChange, value } }) => (
                                <IonSelect value={value} onIonChange={onChange}>
                                  {(countries?.list || []).map(
                                    ({ code: name, name: label }, i) => (
                                      <IonSelectOption
                                        value={name}
                                        key={`${name}-${i}`}
                                      >
                                        {label}
                                      </IonSelectOption>
                                    )
                                  )}
                                </IonSelect>
                              )}
                            />
                            {showError(errors, 'address.country')}
                          </IonItem>
                        </IonList>
                      </IonCard>

                      <IonButton
                        type="submit"
                        expand="block"
                        className="ion-margin-vertical"
                      >
                        {t('Confirm')}
                      </IonButton>
                    </form>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonModal>
  );
};

const mapState = (state: RootState) => ({
  user: state.user.user,
  associationForm: state.associations.associationForm,
  associations: state.associations.associations,
  categories: state.categories.categories,
  countries: state.countries.countries,
});

const mapDispatch = (dispatch: Dispatch) => ({
  setStateAssociations: dispatch.associations.setStateAssociations,
  setStateAssociation: dispatch.associations.setStateAssociationAsync,
  setStateAssociationForm: dispatch.associations.setStateAssociationForm,
  setStateToast: dispatch.toast.setStateToast,
});

export default connect(mapState, mapDispatch)(AssociationForm);
