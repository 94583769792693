import { gql } from '@apollo/client';
import addressFragment from './addressFragment';
import coordinatesFragment from './coordinatesFragment';
import associationFragment from '../association/associationFragment';
import categoryFragment from '../category/categoryFragment';
import imageFragment from '../imageFragment';
import connectionFragment from '../community/connectionFragment';
import postFragment from '../post/postFragment';

export default gql`
  fragment userFragment on User {
    id
    token
    admin
    notificationAccepted
    devices {
      deviceToken
    }
    name
    firstname
    lastname
    gender
    birthdate
    email
    tagline
    creationDate
    deleted
    userDeleted
    address {
      ...addressFragment
    }
    categories {
      ...categoryFragment
    }
    favorites {
      ...associationFragment
    }
    associations {
      id
      name
    }
    connections {
      ...connectionFragment
    }
    posts {
      id
      title
    }
    coordinates {
      ...coordinatesFragment
    }
    illustration {
      ...imageFragment
    }
  }

  ${imageFragment}
  ${addressFragment}
  ${coordinatesFragment}
  ${associationFragment}
  ${categoryFragment}
  ${connectionFragment}
  ${postFragment}
`;
