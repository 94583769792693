import { PENDING } from '../constants/connections';

export const getNotificationsLength = (
  user: UserInterface,
  connections: Connection[]
) => {
  return (connections || [])?.filter(
    ({ approved, requesterId }) =>
      requesterId !== user?.info?.id && approved === PENDING
  ).length;
};
