import { gql } from '@apollo/client';
import associationFragment from '../association/associationFragment';
import imageFragment from '../imageFragment';
import connectionFragment from '../community/connectionFragment';
import postFragment from '../post/postFragment';

export const getUsers: any = gql`
  query getUsers {
    getUsers {
      id
      name
      firstname
      lastname
      gender
      birthdate
      email
      tagline
      creationDate
      deleted
      userDeleted
      favorites {
        ...associationFragment
      }
      connections {
        ...connectionFragment
      }
      posts {
        ...postFragment
      }
      illustration {
        ...imageFragment
      }
    }
  }

  ${imageFragment}
  ${associationFragment}
  ${connectionFragment}
  ${postFragment}
`;
