import { initializeApp, FirebaseApp } from 'firebase/app';
import {
  AuthProvider,
  NextOrObserver,
  User as UserFirebase,
  getAuth,
  initializeAuth,
  indexedDBLocalPersistence,
  Auth,
  setPersistence,
  browserSessionPersistence,
  inMemoryPersistence,
} from 'firebase/auth';
import {
  AuthStateChange,
  User as UserCapacitor,
  FirebaseAuthentication,
  SignInResult,
} from '@capacitor-firebase/authentication';
import { Unsubscribe } from '@firebase/util';
import { Subject } from 'rxjs';

import {
  signInWithGoogle,
  signInWithFacebook,
  signInWithApple,
} from './capacitor';
import { isPlatform } from '@ionic/core';

/* const firebaseApp = initializeApp(config);
  if (isPlatform('hybrid')) {
    initializeAuth(firebaseApp, {
      persistence: indexedDBLocalPersistence,
    });
  }
const auth = getAuth(firebaseApp); */

let firebaseApp: FirebaseApp | undefined;
const authStateSubj = new Subject<AuthStateChange>();

// https://github.com/capawesome-team/capacitor-firebase/issues/140
export const onAuthStateChangedCapacitor = async (
  callback: any
): Promise<void> => {
  /* if (!isPlatform('capacitor')) {
    return;
  } */
  await FirebaseAuthentication.removeAllListeners();
  FirebaseAuthentication.addListener('authStateChange', (change) => {
    authStateSubj.next(callback(change.user));
  });
};

export const onAuthStateChanged = async (callback: any): Promise<void> => {
  /* if (isPlatform('capacitor')) {
    return;
  } */
  const auth = getAuth(firebaseApp);
  auth.onAuthStateChanged((user) => {
    callback(user);
  });
};

export const initialize = async (config: any): Promise<void> => {
  firebaseApp = initializeApp(config);
  if (isPlatform('hybrid')) {
    initializeAuth(firebaseApp, {
      persistence: indexedDBLocalPersistence,
    });
  }
};

export const getIdToken = async (): Promise<string> => {
  const { token } = await FirebaseAuthentication.getIdToken();
  return token;
};

export const getCurrentUser = async (): Promise<UserCapacitor | null> => {
  const { user } = await FirebaseAuthentication.getCurrentUser();
  return user;
};

export const createUserWithEmailAndPassword = async (
  email: string,
  password: string
): Promise<SignInResult> => {
  return await FirebaseAuthentication.createUserWithEmailAndPassword({
    email,
    password,
  });
};

export const signInWithEmailAndPassword = async (
  email: string,
  password: string
): Promise<SignInResult> =>
  await FirebaseAuthentication.signInWithEmailAndPassword({
    email,
    password,
  });

export const signInWithProvider = async (
  provider: AuthProvider
): Promise<SignInResult | null | void> => {
  const auth = getAuth(firebaseApp);
  switch (provider.providerId) {
    case 'google.com':
      return await signInWithGoogle(auth);
    case 'facebook.com':
      return await signInWithFacebook(auth);
    case 'apple.com':
      return await signInWithApple(auth);
    default:
      return null;
  }
};

export const signOut = async (): Promise<void> => {
  const auth = getAuth(firebaseApp);
  await auth.signOut();
  await FirebaseAuthentication.signOut();
};

export const deleteAccount = async (): Promise<void> => {
  const auth = getAuth(firebaseApp);
  await auth.signOut();
  await FirebaseAuthentication.signOut();
};

export const sendEmailVerification = async () => {
  const currentUser = getCurrentUser();
  if (!currentUser) {
    return;
  }
  await FirebaseAuthentication.sendEmailVerification();
};

export const sendPasswordResetEmail = async (email: string) =>
  await FirebaseAuthentication.sendPasswordResetEmail({ email });
