import { gql } from '@apollo/client';
import postFragment from './postFragment';

export const getPosts: any = gql`
  query getPosts {
    getPosts {
      ...postFragment
    }
  }

  ${postFragment}
`;
