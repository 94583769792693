import React, { useEffect, useState } from 'react';
import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonRouterLink,
  IonRefresher,
  IonRefresherContent,
  RefresherEventDetail,
} from '@ionic/react';
import { connect } from 'react-redux';
import { IonContent, IonPage } from '@ionic/react';
import { useTranslation } from 'react-i18next';

import { Dispatch, RootState } from '../../models/store';

import AvatarList from '../../components/association/lists/AvatarList';
import SearchBar from '../../components/SearchBar';
import SearchResults from '../../components/SearchResults';
import { FiPlusCircle } from 'react-icons/fi';
import { associationNewFormLink } from '../../constants/routes';
import GoBack from '../../components/GoBack';
import { searchAssociations } from '../../services/associationService';

const AllAssociations: React.FC<AllAssociationsComponent> = ({
  searchValue,
  associations,
  setStateAssociationForm,
  setStateAssociationsList,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'associations',
  });
  const [data, setData] = useState<Association[]>([]);
  const [isInfiniteDisabled, setInfiniteDisabled] = useState<boolean>(false);

  useEffect(() => {
    if (!associations.list?.length) {
      return;
    }
    pushData();
    // eslint-disable-next-line
  }, [associations.list]);

  const pushData = (ev?: any) => {
    const max = data.length + 10;
    const min = max - 10;
    const newData = [];
    for (let i = min; i < max; i++) {
      if (associations.list?.[i]) {
        newData.push(associations.list[i]);
      }
    }

    setData([...data, ...newData]);
    ev?.target?.complete();
    if (data.length === associations.list?.length) {
      setInfiniteDisabled(true);
    }
  };

  const doRefresh = async (event: CustomEvent<RefresherEventDetail>) => {
    const { associations: list } = await searchAssociations({
      searchValue: '',
      categoryCode: '',
    });
    await setStateAssociationsList(list);
    event.detail.complete();
  };

  return !associations ? null : (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <GoBack />
          <IonTitle>{t('Associations')}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense" translucent>
          <IonToolbar>
            <IonTitle size="large">{t('Associations')}</IonTitle>
            <IonButtons slot="end" onClick={() => setStateAssociationForm({})}>
              <IonRouterLink routerLink={associationNewFormLink}>
                <FiPlusCircle size="1.5em" />
              </IonRouterLink>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonRefresher
          slot="fixed"
          onIonRefresh={doRefresh}
          snapbackDuration="2000ms"
        >
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
        <div className="page-content">
          <AvatarList list={data} title={t('All')} />
          <IonInfiniteScroll
            onIonInfinite={pushData}
            disabled={isInfiniteDisabled}
          >
            <IonInfiniteScrollContent></IonInfiniteScrollContent>
          </IonInfiniteScroll>
        </div>
      </IonContent>
    </IonPage>
  );
};

const mapState = (state: RootState) => ({
  associations: state.associations.associations,
  searchValue: state.search.value,
});

const mapDispatch = (dispatch: Dispatch) => ({
  setStateAssociationsList: dispatch.associations.setStateAssociationsList,
  setStateAssociationForm: dispatch.associations.setStateAssociationForm,
});

export default connect(mapState, mapDispatch)(AllAssociations);
