import React from 'react';
import {
  IonContent,
  IonButton,
  IonGrid,
  IonRow,
  IonCol,
  IonCard,
  IonAvatar,
  IonHeader,
} from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { useSwiper } from 'swiper/react';
import { useHistory } from 'react-router';
import { loginLink } from '../../../constants/routes';

const WelcomeSlide: React.FC<WelcomeSlideComponent> = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'introduction.welcome',
  });
  const swiper = useSwiper();
  const history = useHistory();

  return (
    <>
      <IonGrid className="full-height">
        <IonRow className="header-row ion-align-items-center">
          <IonCol size="auto">
            <img src="/charitable.png" alt="" className="logo" />
          </IonCol>
          <IonCol size="auto">
            <h1 className="main-title">
              <small>Charitable</small>
            </h1>
          </IonCol>
        </IonRow>
        <IonRow className="main-row ion-justify-content-center ion-align-items-end ion-padding ion-text-center">
          <IonCol sizeXs="12" sizeSm="6" sizeLg="4" className="ion-no-padding">
            <IonGrid>
              <IonRow className="ion-align-items-center">
                <IonCol className="ion-text-left">
                  <h1 className="b">{t('presentation')}</h1>
                </IonCol>
              </IonRow>
              <IonRow className="ion-padding ion-justify-content-center ion-align-items-center">
                <IonCol size="auto">
                  <IonAvatar className="avatar-xxl">
                    <img
                      src={`${process.env.REACT_APP_API}/app/location.png`}
                      alt=""
                      className="illustration"
                    />
                  </IonAvatar>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonCol>
        </IonRow>
        <IonRow className="actions-row ion-justify-content-center">
          <IonCol sizeXs="12" sizeSm="6" sizeLg="4" className="ion-no-padding">
            <IonGrid>
              <IonRow className="ion-justify-content-center stick-bottom">
                <IonCol>
                  <IonButton
                    shape="round"
                    expand="block"
                    size="large"
                    onClick={() => {
                      swiper.slideNext();
                    }}
                  >
                    {t('Get started')}
                  </IonButton>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonButton
                    shape="round"
                    expand="block"
                    fill="outline"
                    size="large"
                    onClick={() => {
                      history.push(loginLink);
                    }}
                  >
                    {t('I already have an account')}
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonCol>
        </IonRow>
      </IonGrid>
    </>
  );
};

export default WelcomeSlide;
