import React, { useEffect } from 'react';
import {
  IonButton,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonIcon,
  IonList,
  IonItem,
} from '@ionic/react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { heartOutline } from 'ionicons/icons';
import { useHistory } from 'react-router';
import {
  useAuth,
  SignInWithAppleButton,
  SignInWithEmailButton,
  SignInWithGoogleButton,
  SignInWithFacebookButton,
} from 'authentication';

import { logEvent } from '../../../services/firebaseApp';
import { Dispatch } from '../../../models/store';

import {
  createUser,
  editUserFavoriteCategories,
  addUserLocation,
} from '../../../services/userService';
import {
  homeLink,
  loginLink,
  resetPasswordLink,
} from '../../../constants/routes';

const ThanksSlide: React.FC<ThanksSlideComponent> = ({
  setFirstTime,
  setStateUser,
  setStateUserInfo,
  setStateToast,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'introduction.thanks',
  });
  const history = useHistory();
  const { user: userFirebase } = useAuth()!;

  const gotoLogin = () => {
    setFirstTime(false);
    history.replace(loginLink);
  };

  const onLoggedIn = async (firebase: any) => {
    setFirstTime(false);
  };

  const createUserLater = () => {
    setFirstTime(false);
    history.push(homeLink);
  };

  const onError = (message: string) => {
    setStateToast({
      isOpen: true,
      duration: 0,
      type: 'warning',
      message,
    });
  };

  const beforeLogin = async () => null;

  const gotoResetPassword = () => history.replace(resetPasswordLink);

  return (
    <>
      <IonGrid className="full-height ion-padding-bottom-2x-sm-up">
        <IonRow className="header-row" />
        <IonRow className="main-row ion-padding ion-align-items-end ion-justify-content-center">
          <IonCol sizeSm="8" sizeLg="6">
            <IonIcon icon={heartOutline} color="primary" />
            <h1 className="b">{t('Sign up for free')}</h1>
            <p className="ion-text-left">
              {t(
                'With Charitable in your pocket, give in a single click and help more charities in the humanitarian, child protection, education, economic development, environmental protection and many other sectors…'
              )}
            </p>
          </IonCol>
        </IonRow>
        <IonRow className="actions-row ion-justify-content-center">
          <IonCol sizeSm="auto" sizeXs="12">
            <IonList lines="none" className="no-background">
              <IonItem type="button">
                <SignInWithAppleButton
                  expand="block"
                  fill="outline"
                  buttonText={t('Continue with Apple')}
                  isLogin={true}
                  beforeLogin={beforeLogin}
                  onLoggedIn={onLoggedIn}
                  onError={onError}
                  className="ion-margin-bottom-half"
                />
              </IonItem>
              <IonItem>
                <SignInWithGoogleButton
                  expand="block"
                  fill="outline"
                  buttonText={t('Continue with Google')}
                  isLogin={true}
                  beforeLogin={beforeLogin}
                  onLoggedIn={onLoggedIn}
                  onError={onError}
                  className="ion-margin-bottom-half"
                />
              </IonItem>
              {/* <IonItem>
                <SignInWithFacebookButton
                  expand="block"
                  fill="outline"
                  buttonText={t('Continue with Facebook')}
                  isLogin={true}
                  beforeLogin={beforeLogin}
                  onLoggedIn={onLoggedIn}
                  onError={onError}
                  className="ion-margin-bottom-half"
                />
              </IonItem> */}
              <IonItem>
                <SignInWithEmailButton
                  expand="block"
                  fill="outline"
                  buttonText={t('Continue with your email')}
                  isLogin={true}
                  beforeLogin={beforeLogin}
                  onLoggedIn={onLoggedIn}
                  onError={onError}
                  gotoResetPassword={gotoResetPassword}
                />
              </IonItem>
            </IonList>
          </IonCol>
        </IonRow>
      </IonGrid>
    </>
  );
};

const mapDispatch = (dispatch: Dispatch) => ({
  setStateUser: dispatch.user.setStateUserAsync,
  setStateUserInfo: dispatch.user.setStateUserInfo,
  setStateToast: dispatch.toast.setStateToast,
});

export default connect(null, mapDispatch)(ThanksSlide);
