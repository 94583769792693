import { gql } from '@apollo/client';

export const addUserPicture: any = gql`
  mutation addUserPicture($file: Upload!) {
    addUserPicture(file: $file) {
      success
      message
      locations {
        lg
        md
        sm
      }
    }
  }
`;
