import * as Sentry from '@sentry/react';

import { removeTypenames } from '../utils/graphql';
import client from '../apollo/client';

import { addConnection as addConnectionQuery } from '../graphql/community/addConnection';
import { acceptConnection as acceptConnectionQuery } from '../graphql/community/acceptConnection';
import { rejectConnection as rejectConnectionQuery } from '../graphql/community/rejectConnection';
import { removeConnection as removeConnectionQuery } from '../graphql/community/removeConnection';

export async function addConnection(variables: {
  userId: string;
}): Promise<Connection> {
  try {
    const {
      data: { addConnection: connection },
    } = await client.mutate({
      mutation: addConnectionQuery,
      variables,
      fetchPolicy: 'no-cache',
    });
    return removeTypenames(connection);
  } catch (e: any) {
    Sentry.captureException(e);
    throw new Error();
  }
}

export async function acceptConnection(variables: {
  userId: string;
}): Promise<Connection> {
  try {
    const {
      data: { acceptConnection: connection },
    } = await client.mutate({
      mutation: acceptConnectionQuery,
      variables,
      fetchPolicy: 'no-cache',
    });
    return removeTypenames(connection);
  } catch (e: any) {
    Sentry.captureException(e);
    throw new Error();
  }
}

export async function rejectConnection(variables: {
  userId: string;
}): Promise<Connection> {
  try {
    const {
      data: { rejectConnection: connection },
    } = await client.mutate({
      mutation: rejectConnectionQuery,
      variables,
      fetchPolicy: 'no-cache',
    });
    return removeTypenames(connection);
  } catch (e: any) {
    Sentry.captureException(e);
    throw new Error();
  }
}

export async function removeConnection(variables: {
  userId: string;
}): Promise<Connection> {
  try {
    const {
      data: { removeConnection: connection },
    } = await client.mutate({
      mutation: removeConnectionQuery,
      variables,
      fetchPolicy: 'no-cache',
    });
    return removeTypenames(connection);
  } catch (e: any) {
    Sentry.captureException(e);
    throw new Error();
  }
}
