import { Storage as IonicStorage } from '@ionic/storage';

const storageKey = '@Charitable';

class Storage {
  private storage: any;

  constructor() {
    this.storage = new IonicStorage();
    this.storage.create();
  }

  async init() {
    this.storage = new IonicStorage();
    await this.storage.create();
    return this;
  };

  async get(key: string) {
    const value = await this.storage.get(`${storageKey}:${key}`);
    return value && JSON.parse(value);
  };

  async set(key: string, value: any) {
    return this.storage.set(`${storageKey}:${key}`, JSON.stringify(value));
  };

  async remove(key: string) {
    return this.storage.remove(`${storageKey}:${key}`);
  };
}

export default Storage;