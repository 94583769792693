import { removeTypenames } from '../utils/graphql';
import { Stripe as StripInterface, StripeElements } from '@stripe/stripe-js';
import { Stripe } from '@capacitor-community/stripe';
import * as Sentry from '@sentry/react';

import client from '../apollo/client';

import { createPaymentSheet } from '../graphql/payment/createPaymentSheet';
import { createCheckoutSession } from '../graphql/payment/createCheckoutSession';
import { getPrices as getPricesQuery } from '../graphql/payment/getPrices';

Stripe.initialize({ publishableKey: process.env.REACT_APP_STRIPE_PK || '' });

export async function createCardToken(
  stripe: StripInterface | null,
  elements: StripeElements | null
) {
  if (!stripe || !elements) {
    return;
  }

  /* try {
    const option: CreatePaymentSheetOption = {
      customerId: 'customer',
      paymentIntentClientSecret: 'paymentIntent',
      customerEphemeralKeySecret: 'ephemeralKey'
    };
    const paymentSheet = await Stripe.createPaymentSheet(option);
    const paymentResult = await Stripe.presentPaymentSheet();
  } catch (e:any) {
      Sentry.captureException(e);
    return null;
  } */
}

export async function getPrices(variables: { associationId: string }) {
  try {
    const {
      data: { getPrices: prices },
    } = await client.mutate({
      mutation: getPricesQuery,
      variables,
    });

    return { prices: removeTypenames(prices) };
  } catch (e: any) {
    Sentry.captureException(e);
    return { prices: [], message: e.message };
  }
}

export async function createCheckout(variables: {
  associationId: string;
  priceId: string;
}) {
  try {
    const {
      data: { createCheckoutSession: sessionUrl },
    } = await client.mutate({
      mutation: createCheckoutSession,
      variables,
    });

    return { ...removeTypenames(sessionUrl) };
  } catch (e: any) {
    Sentry.captureException(e);
    return { categories: [], message: e.message };
  }
}

export async function createPayment() {
  try {
    const {
      data: { createPaymentSheet: paymentSheet },
    } = await client.mutate({
      mutation: createPaymentSheet,
      variables: { type: 'Type' },
    });

    return { ...removeTypenames(paymentSheet) };
  } catch (e: any) {
    Sentry.captureException(e);
    return { categories: [], message: e.message };
  }
}
