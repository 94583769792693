import React, { useState } from 'react';
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonSegment,
  IonSegmentButton,
  IonLabel,
  IonRefresher,
  IonRefresherContent,
  RefresherEventDetail,
} from '@ionic/react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
/* import { Contacts as ContactsCapacitor } from '@capacitor-community/contacts'; */

import { Dispatch, RootState } from '../../models/store';
import ConnectionsAvatarList from './lists/ConnectionsAvatarList';
import { ACCEPTED, PENDING } from '../../constants/connections';
import UsersAvatarList from './lists/UsersAvatarList';
import GoBack from '../../components/GoBack';
import { getUsers } from '../../services/userService';

const Community: React.FC<CommunityComponent> = ({
  user,
  users,
  setStateUsers,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'community',
  });
  const [segment, setSegment] = useState<string>('connections');

  /* useEffect(() => {
    if (!isPlatform('hybrid')) {
      return;
    }
    (async () => {
      try {
        const persmissionStatus = await ContactsCapacitor.getPermissions();
        if (!persmissionStatus.granted) {
          await getContacts();
        }
      } catch (e: any) {
        console.log(e);
      }
    })();
  }, []);

  const getContacts = async () => {
    try {
      const { contacts } = await ContactsCapacitor.getContacts();
      console.log(contacts);
    } catch (e: any) {
      console.log(e);
    }
  }; */

  const userConnections = (user?.connections || [])
    .filter(({ approved }) => [PENDING, ACCEPTED].includes(approved))
    .map((connection) => {
      const user: any | undefined = (users?.list || [])?.find(
        ({ id }) => id === connection.id
      );
      return { ...connection, ...user };
    })
    .sort((a) => (a.approved === PENDING ? -1 : 1));

  const doRefresh = async (event: CustomEvent<RefresherEventDetail>) => {
    const { users: list } = await getUsers();
    await setStateUsers({ list });
    event.detail.complete();
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <GoBack />
          <IonTitle>{t('title')}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense" translucent>
          <IonToolbar>
            <IonTitle size="large">{t('title')}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonRefresher
          slot="fixed"
          onIonRefresh={doRefresh}
          snapbackDuration="2000ms"
        >
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
        <div className="page-content">
          <IonSegment value={segment}>
            <IonSegmentButton
              value="connections"
              onClick={() => setSegment('connections')}
            >
              <IonLabel>{t('Your connections')}</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="users" onClick={() => setSegment('users')}>
              <IonLabel>{t('All accounts')}</IonLabel>
            </IonSegmentButton>
          </IonSegment>
          {users.status === '' && !(users.list || []).length ? (
            <div className="ion-text-center ion-padding">
              <p>{t('No results found')}</p>
            </div>
          ) : segment === 'connections' ? (
            <ConnectionsAvatarList
              loading={!user?.connections}
              type="connections"
              title={t('Your connections')}
              list={userConnections}
              showStatus={false}
              showDetails={true}
              showButtons={true}
            />
          ) : (
            <UsersAvatarList
              loading={!users.list}
              type="users"
              title={t('All accounts')}
              helper={t('helper')}
              list={users?.list}
              showStatus={false}
              showDetails={true}
              showButtons={true}
            />
          )}
        </div>
      </IonContent>
    </IonPage>
  );
};

const mapState = (state: RootState) => ({
  user: state.user.user,
  users: state.user.users,
});

const mapDispatch = (dispatch: Dispatch) => ({
  setStateUsers: dispatch.user.setStateUsers,
});

export default connect(mapState, mapDispatch)(Community);
