import React, { useEffect, useState } from 'react';
import { IonButton } from '@ionic/react';
import { Share as ShareCapacitor } from '@capacitor/share';
import { FiShare2 } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/react';

import { logEvent } from '../services/firebaseApp';

const Share: React.FC<ShareComponent> = ({
  title,
  text,
  buttonText,
  url,
  type,
  color,
  size,
  fill,
  shape,
  expand,
  className,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'share',
  });
  const [canShare, setCanShare] = useState(false);

  useEffect(() => {
    (async () => {
      setCanShare(
        !!((await ShareCapacitor.canShare()).value || navigator?.share)
      );
    })();
    // eslint-disable-next-line
  }, []);

  const share = async () => {
    try {
      if ((await ShareCapacitor.canShare()).value) {
        await ShareCapacitor.share({
          title,
          text: `${title}${text ? ` - ${text}` : ''}`,
          url,
          dialogTitle: t('I want to share this with you'),
        });
      } else {
        if (navigator?.share) {
          await navigator.share({
            title,
            text: `${title}${text ? ` - ${text}` : ''}`,
            url,
          });
        }
      }
      logEvent('share', {
        content_type: 'url',
        content_id: url,
      });
    } catch (e: any) {
      Sentry.captureMessage(e);
    }
  };

  return !canShare ? null : type === 'icon' ? (
    <IonButton
      className={`type-icon ${className}`}
      color={color || 'light'}
      size={size || 'default'}
      fill={fill || 'default'}
      shape={shape || undefined}
      onClick={share}
    >
      <FiShare2 className="stroke" />
    </IonButton>
  ) : (
    <IonButton
      className={`type-button ion-margin-vertical ${className}`}
      expand={expand || 'block'}
      size={size || 'default'}
      fill={fill || 'default'}
      color={color || 'primary'}
      shape={shape || undefined}
      onClick={share}
    >
      <FiShare2 className="ion-margin-end" />
      {buttonText ?? t('Share')}
    </IonButton>
  );
};

export default Share;
