import React, { useEffect, useState } from 'react';
import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonList,
  IonButtons,
  IonButton,
  IonBadge,
  IonGrid,
  IonRow,
  IonCol,
} from '@ionic/react';
import { connect } from 'react-redux';
import { IonContent, IonPage } from '@ionic/react';
import { useTranslation } from 'react-i18next';

import { RootState } from '../../models/store';
import Donation from '../../components/Donation';
import { FiArrowLeft, FiX } from 'react-icons/fi';
import { donationsLink, associationsLink } from '../../constants/routes';
import { useHistory, useLocation } from 'react-router';
import GoBack from '../../components/GoBack';

const Donations: React.FC<DonationsComponent> = ({ user, associations }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'donations',
  });
  const history = useHistory();
  const location = useLocation();
  const [association, setAssociation] = useState<
    Association | null | undefined
  >();
  const [donations, setDonations] = useState<Payment[]>([]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.has('filter')) {
      const association = (associations?.list || []).find(
        ({ code }) => code === queryParams.get('filter')
      );
      setAssociation(association);
      const donations = (user?.payments || []).filter(
        ({ associationId }) => associationId === association?.id
      );
      setDonations(donations);
    } else {
      setAssociation(null);
      setDonations(user?.payments || []);
    }
    // eslint-disable-next-line
  }, [user, location, associations]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <GoBack />
          <IonTitle>{t('Donations')}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense" translucent>
          <IonToolbar>
            <IonTitle size="large">{t('Donations')}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <div className="page-content">
          {!association ? null : (
            <IonBadge color="medium" className="filter-badge ion-margin-top">
              <IonGrid className="ion-no-padding">
                <IonRow className="ion-align-items-center">
                  <IonCol className="ion-padding-end">
                    {association?.name}
                  </IonCol>
                  <IonCol>
                    <IonButton
                      shape="round"
                      size="small"
                      color="dark"
                      routerLink={donationsLink}
                      className="type-icon"
                    >
                      <FiX />
                    </IonButton>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonBadge>
          )}
          {!donations?.length ? (
            <div className="ion-text-center ion-padding">
              <p>{t("You haven't made donation yet")}</p>
              <IonButton shape="round" routerLink={associationsLink}>
                {t('Find charities')}
              </IonButton>
            </div>
          ) : (
            <IonList lines="inset">
              {(donations || []).map((payment: Payment) => {
                const association = (associations?.list || []).find(
                  ({ id }: Association) => id === payment?.associationId
                );
                return !association ? null : (
                  <Donation payment={payment} key={`payment-${payment.id}}`} />
                );
              })}
            </IonList>
          )}
        </div>
      </IonContent>
    </IonPage>
  );
};

const mapState = (state: RootState) => ({
  user: state.user.user,
  associations: state.associations.associations,
});

export default connect(mapState)(Donations);
