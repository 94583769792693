import React, { useEffect, useRef, useState } from 'react';
import {
  IonButton,
  IonCard,
  IonCol,
  IonGrid,
  IonModal,
  IonRow,
  IonSpinner,
  isPlatform,
} from '@ionic/react';
import { useTranslation } from 'react-i18next';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import { connect } from 'react-redux';
import { Dispatch } from '../models/store';

const CropperModal: React.FC<CropperModalComponent> = ({
  cropData,
  onCropConfirm,
  onCropCancel,
  setStateToast,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'common',
  });
  const cropperRef = useRef<HTMLImageElement>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onCrop = async () => {
    if (!cropData) {
      return;
    }
    setIsLoading(true);
    try {
      const imageElement: any = cropperRef?.current;
      const cropper: any = imageElement?.cropper;
      const base64Image = cropper.getCroppedCanvas().toDataURL();
      await onCropConfirm(base64Image, cropData);
      setIsLoading(false);
    } catch (e: any) {
      setIsLoading(false);
      onCropCancel();
      setStateToast({
        isOpen: true,
        message: 'Cannot upload picture at the moment, please try again later',
      });
    }
  };

  return (
    <IonModal isOpen={!!cropData} className="content-modal">
      <IonGrid className="full-width ion-no-padding">
        <IonRow className="ion-justify-content-between">
          <IonCol size="auto">
            <IonButton
              fill="clear"
              color="medium"
              onClick={() => onCropCancel()}
            >
              {t('Cancel')}
            </IonButton>
          </IonCol>
          <IonCol size="auto">
            <IonButton fill="clear" onClick={onCrop}>
              {t('Crop')}
            </IonButton>
          </IonCol>
        </IonRow>
      </IonGrid>
      {isLoading ? (
        <IonGrid className="full-height full-width absolute">
          <IonRow className="full-height ion-justify-content-center ion-align-items-center">
            <IonCol size="auto">
              <IonCard className="ion-padding-2x-sm-up">
                <IonSpinner color="primary" />
              </IonCard>
            </IonCol>
          </IonRow>
        </IonGrid>
      ) : null}
      <Cropper
        src={cropData?.url}
        style={{
          height: '100%',
          width: '100%',
        }}
        // Cropper.js options
        initialAspectRatio={cropData?.aspectRatio}
        checkCrossOrigin={false}
        cropBoxResizable={false}
        guides={true}
        ref={cropperRef}
        onKeyPress={(e) => {
          if (e.key === 'Escape') onCropCancel();
        }}
      />
    </IonModal>
  );
};

const mapDispatch = (dispatch: Dispatch) => ({
  setStateToast: dispatch.toast.setStateToast,
});

export default connect(null, mapDispatch)(CropperModal);
