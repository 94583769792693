import { ConnectionStatus, Network } from '@capacitor/network';

export const addListener = async (setStateToast: Function) => {
  const setToast = (status: ConnectionStatus) => {
    if (!status?.connected) {
      setStateToast({
        isOpen: true,
        duration: 0,
        type: 'dark',
        message: 'Connection Lost. Please check your Internet connection',
      });
    } else {
      setStateToast({
        isOpen: false,
      });
    }
  };

  const logCurrentNetworkStatus = async () => {
    const status = await Network.getStatus();
    setToast(status);
  };

  const initStatus = await Network.getStatus();
  setToast(initStatus);

  Network.addListener('networkStatusChange', logCurrentNetworkStatus);
};

export const removeAllListeners = Network.removeAllListeners;
