import { gql } from '@apollo/client';

export const getCountries: any = gql`
  query getCountries($all: Boolean) {
    getCountries(all: $all) {
      id
      code
      name
    }
  }
`;
