import React from 'react';
import { IonItem, IonAvatar, IonImg, IonLabel, IonNote } from '@ionic/react';

import { associationLink } from '../constants/routes';
import { formatDate } from '../utils/date';
import { connect } from 'react-redux';
import { RootState, Dispatch } from '../models/store';

const Donation: React.FC<DonationComponent> = ({ payment, associations }) => {
  const association = (associations?.list || []).find(
    ({ id }: Association) => id === payment?.associationId
  );

  return !payment || !association ? null : (
    <IonItem
      className="ion-align-items-center"
      routerLink={associationLink(association.code)}
      detail={false}
    >
      <IonAvatar
        slot="start"
        className="ion-no-margin ion-margin-end avatar-sm"
      >
        <IonImg src={association.logo?.sm} alt="" />
      </IonAvatar>
      <IonLabel>
        <h5 className="ion-no-margin">{association.name}</h5>
        <IonNote>
          {payment.amount / 100}
          {payment.currency === 'eur' ? '€' : '$'}
        </IonNote>
      </IonLabel>
      <IonLabel slot="end" className="ion-text-right">
        <h5 className="ion-no-margin">
          {formatDate(payment.creationDate + '000', 'dd/MM/yyy')}
        </h5>
        <IonNote>
          <br />
        </IonNote>
      </IonLabel>
    </IonItem>
  );
};

const mapState = (state: RootState) => ({
  associations: state.associations.associations,
});

const mapDispatch = (dispatch: Dispatch) => ({});

export default connect(mapState, mapDispatch)(Donation);
