import React from 'react';
import {
  IonButton,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonIcon,
} from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { useSwiper } from 'swiper/react';
import { compassOutline } from 'ionicons/icons';

const LocationSlide: React.FC<LocationSlideComponent> = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'introduction.location',
  });
  const swiper = useSwiper();

  return (
    <>
      <IonGrid className="full-height">
        <IonRow className="header-row" />
        <IonRow className="main-row ion-padding ion-align-items-center ion-justify-content-center">
          <IonCol sizeSm="8" sizeLg="6">
            <IonIcon icon={compassOutline} color="primary" />
            <h1 className="b">{t('Close to you')}</h1>
            <p>
              {t(
                'We need your location to inform you about charities close to you'
              )}
            </p>
          </IonCol>
        </IonRow>
        <IonRow className="actions-row">
          <IonCol>
            <IonButton
              shape="round"
              size="large"
              className="ion-margin"
              onClick={() => {
                swiper.slideNext();
              }}
            >
              {t('Next')}
            </IonButton>
          </IonCol>
        </IonRow>
      </IonGrid>
    </>
  );
};

export default LocationSlide;
