import { createModel } from '@rematch/core';

import { RootState, RootModel } from './store';

const initialState: ConnectionsState = {
  connection: null,
  connectionModalList: null,
};

export const connections = createModel<RootModel>()({
  state: initialState,
  reducers: {
    setStateConnection: (state, payload: Connection) => ({
      ...state,
      connection: payload,
    }),
    setStateConnectionModalList: (state, payload: Connection[]) => ({
      ...state,
      connectionModalList: payload,
    }),
  },
});
