import {
  SignInResult,
  FirebaseAuthentication,
} from '@capacitor-firebase/authentication';
import {
  Auth,
  FacebookAuthProvider,
  GoogleAuthProvider,
  OAuthProvider,
  EmailAuthProvider,
  signInWithRedirect,
  getRedirectResult,
  linkWithCredential,
  fetchSignInMethodsForEmail,
  signInWithEmailAndPassword,
} from 'firebase/auth';

const handleAccountExistsError = async (
  auth: Auth,
  Provider: OAuthProvider | FacebookAuthProvider | GoogleAuthProvider,
  e: any
) => {
  if (e?.code === 'auth/account-exists-with-different-credential') {
    const { user } = await handleAccountExistsWithDifferentCredential(
      auth,
      e,
      Provider
    );
    return { user, error: null };
  }
  return { user: null, error: e?.code ?? 'auth/undefined' };
};

export const signInWithApple = async (
  auth: Auth
): Promise<SignInResult | void> => {
  try {
    return await FirebaseAuthentication.signInWithApple();
  } catch (e: any) {
    await handleAccountExistsError(auth, e, OAuthProvider);
  }
};

export const signInWithFacebook = async (
  auth: Auth
): Promise<SignInResult | void> => {
  try {
    return await FirebaseAuthentication.signInWithFacebook();
  } catch (e: any) {
    await handleAccountExistsError(auth, e, FacebookAuthProvider);
  }
};

export const signInWithGoogle = async (
  auth: Auth
): Promise<SignInResult | void> => {
  try {
    return await FirebaseAuthentication.signInWithGoogle();
  } catch (e: any) {
    await handleAccountExistsError(auth, e, GoogleAuthProvider);
  }
};

const handleAccountExistsWithDifferentCredential = async (
  auth: Auth,
  error: any,
  authProvider: any
) => {
  try {
    OAuthProvider.credentialFromError(error);
    const credential = error.customData._tokenResponse;
    const pendingCred = authProvider.credential(credential.oauthAccessToken);
    const { email } = credential;
    const methods = await fetchSignInMethodsForEmail(auth, email);
    if (methods[0] === 'password') {
      const password = promptUserForPassword(); // TODO: implement promptUserForPassword.
      const result = await signInWithEmailAndPassword(auth, email, password);
      const usercred = await linkWithCredential(result.user, pendingCred);
      return { user: usercred.user, credential: usercred, error: null };
    }
    const provider = getProviderForProviderId(methods[0]);
    await signInWithRedirect(auth, provider);
    const result = await getRedirectResult(auth);
    if (!result) {
      return { user: null, credential: null, error: 'No User' };
    }
    const usercred = await linkWithCredential(result.user, pendingCred);
    return { user: usercred.user, credential: usercred, error: null };
  } catch (e: any) {
    console.log('Account linking error', e);
    return { user: null, error: e.code };
  }
};

const promptUserForPassword = () => {
  return 'test1234';
};

const getProviderForProviderId = (providerId: string) => {
  switch (providerId) {
    case 'google.com':
      return new GoogleAuthProvider();
    case 'facebook.com':
      return new FacebookAuthProvider();
    case 'apple.com':
      return new OAuthProvider('apple.com');
    default:
      return new EmailAuthProvider();
  }
};
