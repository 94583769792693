import React from 'react';
import {
  IonAvatar,
  IonContent,
  IonCard,
  IonGrid,
  IonRow,
  IonCol,
  IonList,
  IonItem,
  IonLabel,
  IonNote,
  IonImg,
  IonTitle,
  IonText,
} from '@ionic/react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { RootState } from '../../models/store';
import { formatDate, formatDistance } from '../../utils/date';
import { generateHSL } from '../../utils/generateRandomColor';
import AddConnection from '../../components/AddConnection';
import { FiActivity, FiGift, FiGlobe, FiHeart } from 'react-icons/fi';
import { usersAreConnected } from '../../utils/connection';
import { PENDING, ACCEPTED, REJECTED, NEW } from '../../constants/connections';
import {
  associationsLink,
  associationsUserLink,
  communityLink,
  postsLink,
  postsUserLink,
} from '../../constants/routes';
import Separator from '../../components/Separator';

const ConnectionContent: React.FC<ConnectionContentComponent> = ({
  user,
  connection,
  className,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation('translation', {
    keyPrefix: 'connection',
  });
  const currentLanguage = language.split('-')[0];

  if (!connection) {
    return null;
  }

  const donations: any[] = [];

  return (
    <IonContent className={`${className}`}>
      <IonGrid className="ion-margin-top-2x-sm-up">
        <IonRow className="ion-padding-horizontal">
          <IonCol>
            <AddConnection
              type="button"
              size="small"
              connection={connection}
              color={!connection.dateAdded ? 'primary' : 'light'}
              fill="solid"
              showStatus={true}
              showDetails={true}
              short={true}
              only={[PENDING]}
              align="center"
            />
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol className="ion-padding">
            <IonItem className="header-lg transparent" detail={false}>
              <IonAvatar
                slot="start"
                className="avatar-lg ion-margin-end"
                style={{
                  background: generateHSL(
                    (connection?.name || connection?.email) ?? 'Toto'
                  ),
                }}
              >
                {connection?.illustration ? (
                  <IonImg
                    src={connection.illustration.sm}
                    alt={connection?.name}
                  />
                ) : (
                  <b>
                    {((connection?.name || connection?.email) ?? 'Toto')
                      .slice(0, 1)
                      .toUpperCase()}
                  </b>
                )}
              </IonAvatar>
              <IonTitle size="large">
                {connection?.name}
                <br />
                <IonNote color="medium">{connection.tagline || ''}</IonNote>
                <br />
                <IonNote color="primary">
                  <small>
                    {connection?.creationDate &&
                      t('Joined Charitable DATE', {
                        date: formatDistance(
                          connection?.creationDate,
                          currentLanguage
                        ),
                      })}
                  </small>
                  {/* {connection.approved !== ACCEPTED ? null : (
                    <>
                      <br />
                      <small>
                        {connection?.dateAccepted &&
                          t('You are connected since DATE', {
                            date: formatDistance(
                              connection?.dateAccepted,
                              currentLanguage
                            ),
                          })}
                      </small>
                    </>
                  )} */}
                </IonNote>
              </IonTitle>
            </IonItem>
            {!user?.info?.admin ? null : (
              <IonGrid className="ion-no-padding-horizontal">
                <IonRow className="ion-justify-content-between">
                  <IonCol size="auto" className="ion-no-padding">
                    <IonList className="no-background">
                      <IonItem>
                        <p>Email: {connection?.email}</p>
                      </IonItem>
                    </IonList>
                  </IonCol>
                </IonRow>
              </IonGrid>
            )}
            <IonGrid>
              <IonRow className="ion-no-padding-horizontal ion-align-items-center ion-justify-content-end">
                <IonCol size="auto">
                  <AddConnection
                    type="button"
                    size="small"
                    connection={connection}
                    color={!connection.dateAdded ? 'primary' : 'light'}
                    fill="solid"
                    showStatus={false}
                    showDetails={false}
                    only={[NEW, ACCEPTED, REJECTED]}
                  />
                </IonCol>
              </IonRow>
              <IonRow className="ion-wrap-reverse-lg-up">
                <IonCol
                  size="12"
                  sizeMd="6"
                  className="ion-padding-end-1x-lg-up"
                >
                  <IonGrid>
                    <IonRow className="ion-align-items-stretch">
                      <IonCol sizeXs="6" sizeSm="4" sizeMd="6" sizeLg="4">
                        <IonCard
                          className="ion-padding"
                          routerLink={associationsUserLink(connection.id)}
                        >
                          <FiHeart size="2em" />
                          <IonText color="primary">
                            <h4>{connection?.associations?.length ?? 0}</h4>
                          </IonText>
                          <small>{t('Associations')}</small>
                        </IonCard>
                      </IonCol>
                      <IonCol sizeXs="6" sizeSm="4" sizeMd="6" sizeLg="4">
                        <IonCard
                          className="ion-padding"
                          routerLink={postsUserLink(connection?.id)}
                        >
                          <FiActivity size="2em" />
                          <IonText color="primary">
                            <h4>{connection?.posts?.length ?? 0}</h4>
                          </IonText>
                          <small>{t('Posts')}</small>
                        </IonCard>
                      </IonCol>
                      <IonCol sizeXs="6" sizeSm="4" sizeMd="6" sizeLg="4">
                        <IonCard
                          className="ion-padding"
                          routerLink={communityLink}
                        >
                          <FiGlobe size="2em" />
                          <IonText color="primary">
                            <h4>{connection?.connections?.length ?? 0}</h4>
                          </IonText>
                          <small>{t('Connections')}</small>
                        </IonCard>
                      </IonCol>
                      <IonCol sizeXs="6" sizeSm="4" sizeMd="6" sizeLg="4">
                        <IonCard className="ion-padding">
                          <FiGift size="2em" />
                          <IonText color="primary">
                            <h4>{connection?.payments?.length ?? 0}</h4>
                          </IonText>
                          <small>{t('Donations')}</small>
                        </IonCard>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </IonCol>
                <IonCol
                  size="12"
                  sizeMd="6"
                  className="ion-padding-top-1x-md-up"
                >
                  <Separator title={t('donations.Donations')} />
                  <IonCard>
                    {!usersAreConnected(user, connection) ? (
                      <p className="ion-text-center ion-padding">
                        {t(
                          'donations.You cannot see USER donations as you are not connected yet',
                          { user: connection?.name }
                        )}
                      </p>
                    ) : !donations?.length ? (
                      <p className="ion-text-center ion-padding">
                        {t('donations.No donation yet')}
                      </p>
                    ) : (
                      <IonList lines="inset">
                        {(donations || []).map((payment: Payment) => (
                          <IonItem
                            key={`payment-${payment.id}}`}
                            className="ion-align-items-center"
                          >
                            <IonLabel>
                              <IonNote className="ion-no-margin">
                                {formatDate(
                                  payment.creationDate + '000',
                                  'dd/MM/yyy'
                                )}
                              </IonNote>
                            </IonLabel>
                            <IonLabel slot="end" className="ion-text-right">
                              {payment.amount / 100}
                              {payment.currency === 'eur' ? '€' : '$'}
                            </IonLabel>
                          </IonItem>
                        ))}
                      </IonList>
                    )}
                  </IonCard>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonContent>
  );
};

const mapState = (state: RootState) => ({
  users: state.user.users,
  user: state.user.user,
});

export default connect(mapState)(ConnectionContent);
