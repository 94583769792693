import gql from 'graphql-tag';

import associationFragment from './associationFragment';

export const addAssociationPicture: any = gql`
  mutation addAssociationPicture($file: Upload!, $id: ID!, $type: FileType!) {
    addAssociationPicture(file: $file, id: $id, type: $type) {
      file {
        success
        message
        locations {
          lg
          md
          sm
        }
      }
      association {
        ...associationFragment
      }
    }
  }

  ${associationFragment}
`;
