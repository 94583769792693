import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { logEvent } from '../services/firebaseApp';
import HashTags from './HashTags';

const ReadMore: React.FC<ReadMoreComponent> = ({
  type,
  item,
  maxLength = 100,
  className,
  children,
}) => {
  const text = children;
  const [isReadMore, setIsReadMore] = useState(true);
  const { t } = useTranslation('translation', {
    keyPrefix: 'readMore',
  });

  const toggleReadMore = () => {
    if (isReadMore) {
      logEvent('readmore', {
        content_type: 'less',
        content_id: type,
        items: [{ name: item }],
      });
    } else {
      logEvent('readmore', {
        content_type: 'more',
        content_id: type,
        items: [{ name: item }],
      });
    }
    setIsReadMore(!isReadMore);
  };

  return (
    <div className={`ion-text-break ${className}`}>
      <HashTags>
        {isReadMore ? `${text?.slice(0, maxLength)}... ` : text}
      </HashTags>
      {text?.length <= maxLength ? null : (
        <p>
          <small>
            <a onClick={toggleReadMore}>
              {isReadMore ? t('read more') : t('show less')}
            </a>
          </small>
        </p>
      )}
    </div>
  );
};

export default ReadMore;
