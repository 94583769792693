import React from 'react';
import {
  IonContent,
  IonPage,
  IonGrid,
  IonRow,
  IonCol,
  IonList,
  IonItem,
} from '@ionic/react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import {
  useAuth,
  SignInWithAppleButton,
  SignInWithEmailButton,
  SignInWithGoogleButton,
  SignInWithFacebookButton,
} from 'authentication';

import { logEvent } from '../../services/firebaseApp';
import { Dispatch } from '../../models/store';
import { resetPasswordLink } from '../../constants/routes';

const Login: React.FC<LoginComponent> = ({ setStateToast }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'login',
  });
  const history = useHistory();
  const { user: userFirebase } = useAuth()!;

  const gotoResetPassword = () => history.replace(resetPasswordLink);

  const onLoggedIn = async (user: any) => {
    logEvent('login', {
      content_type: 'user',
      content_id: user?.info?.id,
    });
  };

  const onError = (message: string) => {
    setStateToast({
      isOpen: true,
      duration: 0,
      type: 'warning',
      message,
    });
  };

  const beforeLogin = async () => null;

  return userFirebase ? null : (
    <IonPage className="login">
      <IonContent fullscreen>
        <IonGrid className="full-height ion-justify-content-center ion-text-center ion-padding-bottom-2x-sm-up">
          <IonRow className="row-grow ion-justify-content-center ion-align-items-end">
            <IonCol size="auto" className="ion-no-padding">
              <img src="/charitable.png" alt="" style={{ width: '100px' }} />
              <h1 className="main-title">Charitable</h1>
            </IonCol>
          </IonRow>
          <IonRow className="ion-justify-content-center ion-align-items-end">
            <IonCol size="auto" className="ion-no-padding">
              <h1 className="ion-margin-vertical b">
                {t('Let your generosity shine through')}
              </h1>
            </IonCol>
          </IonRow>
          <IonRow className="ion-justify-content-center">
            <IonCol sizeSm="auto" sizeXs="12">
              <IonList lines="none" className="no-background">
                <IonItem type="button">
                  <SignInWithAppleButton
                    expand="full"
                    fill="outline"
                    buttonText={t('Continue with Apple')}
                    isLogin={true}
                    beforeLogin={beforeLogin}
                    onLoggedIn={onLoggedIn}
                    onError={onError}
                    className="ion-margin-bottom-half"
                  />
                </IonItem>
                <IonItem>
                  <SignInWithGoogleButton
                    expand="full"
                    fill="outline"
                    buttonText={t('Continue with Google')}
                    isLogin={true}
                    beforeLogin={beforeLogin}
                    onLoggedIn={onLoggedIn}
                    onError={onError}
                    className="ion-margin-bottom-half"
                  />
                </IonItem>
                {/* <IonItem>
                  <SignInWithFacebookButton
                    expand="full"
                    fill="outline"
                    buttonText={t('Continue with Facebook')}
                    isLogin={true}
                    beforeLogin={beforeLogin}
                    onLoggedIn={onLoggedIn}
                    onError={onError}
                    className="ion-margin-bottom-half"
                  />
                </IonItem> */}
                <IonItem>
                  <SignInWithEmailButton
                    expand="full"
                    fill="outline"
                    buttonText={t('Continue with your email')}
                    isLogin={true}
                    beforeLogin={beforeLogin}
                    onLoggedIn={onLoggedIn}
                    onError={onError}
                    gotoResetPassword={gotoResetPassword}
                  />
                </IonItem>
              </IonList>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

const mapDispatch = (dispatch: Dispatch) => ({
  setStateToast: dispatch.toast.setStateToast,
});

export default connect(null, mapDispatch)(Login);
