import { createModel } from '@rematch/core';

import { RootState, RootModel } from './store';

const initialState: ToastState = {
  toast: {
    isOpen: false,
    isNotification: false,
    header: '',
    message: '',
    duration: 4000,
    type: 'warning',
    position: 'top',
  },
};

export const toast = createModel<RootModel>()({
  state: initialState,
  reducers: {
    setStateToast: (state, payload: ToastInterface) => ({
      ...state,
      toast: { ...state.toast, ...payload },
    }),
  },
});
