import { gql } from '@apollo/client';
import coordinatesFragment from './coordinatesFragment';

export const addUserLocation: any = gql`
  mutation addUserLocation($coordinates: GeolocationPositionInput!) {
    addUserLocation(coordinates: $coordinates) {
      ...coordinatesFragment
    }
  }

  ${coordinatesFragment}
`;
