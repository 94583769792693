import { gql } from '@apollo/client';
import notificationFragment from './notificationFragment';

export const readNotification: any = gql`
  mutation readNotification($id: ID!) {
    readNotification(id: $id) {
      ...notificationFragment
    }
  }

  ${notificationFragment}
`;
