import React, { useState } from 'react';
import { IonPage, IonicSwiper, IonContent } from '@ionic/react';
import * as Sentry from '@sentry/react';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { logEvent, setUserProperty } from '../../services/firebaseApp';

import CategoriesSlide from './slides/CategoriesSlide';

import './Help.scss';

import 'swiper/css';
import 'swiper/css/pagination';
import '@ionic/react/css/ionic-swiper.css';
import CountriesSlide from './slides/CountriesSlide';

SwiperCore.use([IonicSwiper, Navigation, Pagination]);

const Help: React.FC<HelpComponent> = () => {
  const [selectedCategories, setSelectedCategories] = useState<Category[]>([]);
  const [selectedCountries, setSelectedCountries] = useState<Country[]>([]);

  const getCategoryIds = () => selectedCategories.map(({ id }) => id);
  const getCategoryNamesAsString = () =>
    selectedCategories
      .map(({ name }) =>
        name
          .toLowerCase()
          .replaceAll(/[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~ ]/g, '_')
      )
      .join(',');
  const getCategoryNamesAsObject = () =>
    selectedCategories.map(({ name }) => ({
      name: name
        .toLowerCase()
        .replaceAll(/[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~ ]/g, '_'),
    }));

  const detectChange = async (swiper: any) => {
    try {
      switch (swiper.activeIndex) {
        case 1:
          if (!selectedCategories.length) {
            return;
          }
          localStorage.setItem(
            'favorites_categories',
            JSON.stringify(getCategoryIds())
          );
          setUserProperty('favorites_categories', getCategoryNamesAsString());
          logEvent('favorites_categories', {
            content_type: 'onboarding',
            content_id: 'undefined',
            items: getCategoryNamesAsObject(),
          });
          break;
      }
    } catch (e: any) {
      Sentry.captureException(e);
    }
  };

  return (
    <IonPage className="help">
      <IonContent>
        <Swiper onSlideChange={detectChange} pagination>
          <SwiperSlide className="categories-slide">
            <CategoriesSlide
              selectedCategories={selectedCategories}
              setSelectedCategories={setSelectedCategories}
            />
          </SwiperSlide>
          <SwiperSlide className="countries-slide">
            <CountriesSlide
              selectedCountries={selectedCountries}
              setSelectedCountries={setSelectedCountries}
            />
          </SwiperSlide>
        </Swiper>
      </IonContent>
    </IonPage>
  );
};

export default Help;
