import { gql } from '@apollo/client';

export const newPostReceived: any = gql`
  subscription newPostReceived {
    newPostReceived {
      id
      status
    }
  }
`;
