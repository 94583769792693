import { gql } from '@apollo/client';

export const getPrices: any = gql`
  query getPrices($associationId: ID!) {
    getPrices(associationId: $associationId) {
      id
      currency
      unit_amount
      nickname
    }
  }
`;
