import React, { useEffect, useState } from 'react';

import { User as UserFirebase } from 'firebase/auth';
import { User as UserCapacitor } from '@capacitor-firebase/authentication';

import {
  initialize,
  getCurrentUser,
  getIdToken,
  createUserWithEmailAndPassword,
  signInWithProvider,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  onAuthStateChangedCapacitor,
  sendEmailVerification,
  sendPasswordResetEmail,
  deleteAccount as deleteAccountInAuth,
} from '../services';

import { AuthContextInterface, AuthProviderProps } from '../types';

export const AuthContext = React.createContext<AuthContextInterface | null>(
  null
);

export const AuthProvider: React.FC<AuthProviderProps> = ({
  apiKey,
  authDomain,
  projectId,
  appId,
  measurementId,
  children,
  ...props
}) => {
  const config = {
    apiKey,
    authDomain,
    projectId,
    appId,
    measurementId,
  };

  initialize(config);

  const [loading, setLoading] = useState<boolean>(true);
  const [loadingTimeout, setLoadingTimeout] = useState<any | null>(null);
  const [user, setUser] = useState<
    UserCapacitor | UserFirebase | null | undefined
  >(null);

  // https://github.com/capawesome-team/capacitor-firebase/issues/159#issuecomment-1194667877
  onAuthStateChangedCapacitor(async () => {
    const currentUser = await getCurrentUser();
    if (currentUser?.uid === user?.uid) {
      return;
    }
    clearTimeout(loadingTimeout);
    await authChanged(currentUser);
  });

  useEffect(() => {
    onAuthStateChanged(async (userFirebase: UserFirebase) => {
      let newUser = null;
      const currentUser = await getCurrentUser();
      newUser = currentUser || userFirebase;
      if (newUser?.uid === user?.uid) {
        return;
      }
      clearTimeout(loadingTimeout);
      await authChanged(newUser);
    });
  }, []);

  useEffect(() => {
    setLoadingTimeout(
      setTimeout(function () {
        setLoading(false);
      }, 5000)
    );
  }, []);

  const authChanged = async (
    userChanged?: UserCapacitor | UserFirebase | null | undefined
  ) => {
    setLoading(true);
    setUser(userChanged);
    if (userChanged) {
      const token = await getIdToken();
      localStorage.setItem('token', token);
    } else {
      localStorage.removeItem('token');
    }
    setLoading(false);
  };

  const signUp = async (
    email: string,
    password: string,
    beforeLogin?: Function,
    onLoggedIn?: Function,
    onError?: Function
  ) => {
    try {
      beforeLogin && (await beforeLogin());
      const result = await createUserWithEmailAndPassword(email, password);
      sendEmailVerification();
      onLoggedIn && onLoggedIn(result?.user);
    } catch (e: any) {
      onError && onError(e);
    }
  };

  const signIn = async (
    email: string,
    password: string,
    beforeLogin?: Function,
    onLoggedIn?: Function,
    onError?: Function
  ) => {
    try {
      beforeLogin && (await beforeLogin());
      const result = await signInWithEmailAndPassword(email, password);
      onLoggedIn && onLoggedIn(result?.user);
    } catch (e: any) {
      onError && onError(e);
    }
  };

  const logOut = async (
    beforeLogout?: Function,
    onLoggedOut?: Function
  ): Promise<void> => {
    setLoading(true);
    beforeLogout && (await beforeLogout(null));
    await signOut();
    onLoggedOut && (await onLoggedOut(null));
    setUser(null);
    setLoading(false);
  };

  const logInWithProvider = async ({
    provider,
    isLogin,
    beforeLogin,
    onLoggedIn,
    onError,
  }: {
    provider: any;
    isLogin: boolean;
    beforeLogin: Function | undefined;
    onLoggedIn: Function | undefined;
    onError: Function | undefined;
  }): Promise<void> => {
    try {
      beforeLogin && (await beforeLogin());
      const result = await signInWithProvider(provider);
      onLoggedIn && onLoggedIn(result?.user);
    } catch (e: any) {
      onError && onError(e);
    }
  };

  const deleteAccount = async ({
    beforeDeleteAccount,
    onDeletedAccount,
  }: {
    beforeDeleteAccount?: Function;
    onDeletedAccount?: Function;
  }): Promise<void> => {
    try {
      setLoading(true);
      beforeDeleteAccount && (await beforeDeleteAccount());
      await deleteAccountInAuth();
      await authChanged(null);
      onDeletedAccount && (await onDeletedAccount());
      setLoading(false);
    } catch (e: any) {}
  };

  const resetPassword = async (
    email: string,
    onSuccess: Function | undefined,
    onError: Function | undefined
  ): Promise<void> => {
    try {
      await sendPasswordResetEmail(email);
      onSuccess && onSuccess();
    } catch (e: any) {
      onError && onError(e);
    }
  };

  const v = {
    loading,
    signUp,
    signIn,
    logInWithProvider,
    logOut,
    deleteAccount,
    resetPassword,
    user,
  };

  return (
    <AuthContext.Provider value={v} {...props}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth: () => AuthContextInterface | null = () =>
  React.useContext(AuthContext);

export const AuthConsumer: React.FC = (children, ...props: any) => {
  return (
    <AuthContext.Consumer {...props}>
      {(value) => <div>{`user is: ${value?.user}`}</div>}
    </AuthContext.Consumer>
  );
};
