import * as Sentry from '@sentry/react';

import Storage from '../storage';
import { removeTypenames } from '../utils/graphql';

import client from '../apollo/client';

import { getFavoritesByTag } from '../graphql/favorite/getFavoritesByTag';
import { countFavoritesByCategories } from '../graphql/favorite/countFavoritesByCategories';
import { addFavorite as addFavoriteQuery } from '../graphql/favorite/addFavorite';
import { addFavoritesInBunk as addFavoritesInBunkQuery } from '../graphql/favorite/addFavoritesInBunk';
import { removeFavorite as removeFavoriteQuery } from '../graphql/favorite/removeFavorite';

const storage = new Storage();

export async function searchFavoritesByTag(variables: {
  tag: string;
}): Promise<{ associations: Association[] }> {
  const user = await storage.get('user');
  if (!user?.loggedIn) {
    const favoritesList = await storage.get('favoritesList');
    return {
      associations: (favoritesList || []).filter((a: Association) =>
        a.name.includes(variables.tag)
      ),
    };
  }

  try {
    const {
      data: { getFavoritesByTag: associations },
    } = await client.query({
      query: getFavoritesByTag,
      variables,
      fetchPolicy: 'no-cache',
    });

    return { associations: removeTypenames(associations) };
  } catch (e: any) {
    Sentry.captureException(e);
    throw new Error();
  }
}

export async function countFavorites(): Promise<
  {
    categoryName_en: string;
    categoryName_fr: string;
    count: number;
  }[]
> {
  try {
    const {
      data: { countFavoritesByCategories: count },
    } = await client.query({
      query: countFavoritesByCategories,
      fetchPolicy: 'no-cache',
    });

    return removeTypenames(count);
  } catch (e: any) {
    Sentry.captureException(e);
    throw new Error();
  }
}

export async function addFavoritesInBunk(variables: {
  associationIds: string[];
}): Promise<Association[]> {
  try {
    const {
      data: { addFavoritesInBunk: associations },
    } = await client.mutate({
      mutation: addFavoritesInBunkQuery,
      variables,
      fetchPolicy: 'no-cache',
    });
    return removeTypenames(associations);
  } catch (e: any) {
    Sentry.captureException(e);
    throw new Error();
  }
}

export async function addFavorite(variables: {
  associationId: string;
}): Promise<Association[]> {
  try {
    const {
      data: { addFavorite: associations },
    } = await client.mutate({
      mutation: addFavoriteQuery,
      variables,
      fetchPolicy: 'no-cache',
    });
    return removeTypenames(associations);
  } catch (e: any) {
    Sentry.captureException(e);
    throw new Error();
  }
}

export async function removeFavorite(variables: {
  associationId: string;
}): Promise<Association[]> {
  try {
    const {
      data: { removeFavorite: associations },
    } = await client.mutate({
      mutation: removeFavoriteQuery,
      variables,
      fetchPolicy: 'no-cache',
    });
    return removeTypenames(associations);
  } catch (e: any) {
    Sentry.captureException(e);
    throw new Error();
  }
}
