import { gql } from '@apollo/client';
import addressFragment from './addressFragment';

export const editUserAddress: any = gql`
  mutation editUserAddress($input: AddressInput!) {
    editUserAddress(input: $input) {
      ...addressFragment
    }
  }

  ${addressFragment}
`;
