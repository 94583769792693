import React, { useEffect, useState } from 'react';
import {
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonModal,
  IonAvatar,
} from '@ionic/react';
import { useHistory, useLocation } from 'react-router';
import { connect } from 'react-redux';
import { FiX } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';

import { logEvent } from '../services/firebaseApp';
import { RootState } from '../models/store';

import './ConfirmPaymentModal.scss';
import Donate from './Donate';

const ConfirmPaymentModal: React.FC<any> = ({ association }) => {
  const location = useLocation<any>();
  const history = useHistory<any>();
  const { t } = useTranslation('translation', {
    keyPrefix: 'confirmDonation',
  });
  const [status, setStatus] = useState<string>('');

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const statusResponse =
      queryParams.get('paymentStatus') === 'cancel'
        ? 'warning'
        : queryParams.get('paymentStatus') === 'success'
        ? 'success'
        : '';

    if (statusResponse === 'success') {
      logEvent('donate', {
        content_type: 'success',
        content_id: association.code,
      });
    }

    setStatus(statusResponse);
    // eslint-disable-next-line
  }, [association]);

  const closeModal = () => {
    const queryParams = new URLSearchParams(location.search);
    queryParams.delete('paymentStatus');
    history.replace({
      search: queryParams.toString(),
    });
  };

  return !association ? null : (
    <IonModal
      isOpen={association && status !== ''}
      breakpoints={[0.1, 0.5, 0.7, 0.9]}
      initialBreakpoint={0.7}
      className="content-modal confirm-payment"
      onWillDismiss={closeModal}
    >
      <div>
        <IonButton
          className="type-icon"
          onClick={() => {
            setStatus('');
          }}
          color="primary"
          shape="round"
          fill="solid"
        >
          <FiX size="1.3em" />
        </IonButton>
      </div>
      <IonGrid className="ion-padding">
        <IonRow className="ion-justify-content-center ion-margin-top-1x-md-down">
          <IonCol size="auto">
            <IonAvatar className="avatar-lg">
              <img src={association?.logo?.sm} alt="" />
            </IonAvatar>
          </IonCol>
        </IonRow>
        <IonRow className="ion-justify-content-center ion-text-center">
          <IonCol size="auto">
            {status === 'success' ? (
              <>
                <h1>{t('Thank you for your generosity')}</h1>
                <p>
                  {t(
                    'Your donation has been successfully made, and we are truly grateful for it.'
                  )}
                </p>
              </>
            ) : status === 'warning' ? (
              <>
                <h1>{t('A problem has occured')}</h1>
                <p>
                  {t(
                    "The operation was canceled, and your donation didn't reach the charity."
                  )}
                </p>
                <Donate association={association} onClick={closeModal} />
              </>
            ) : null}
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonModal>
  );
};

const mapState = (state: RootState) => ({
  association: state.associations.association,
});

export default connect(mapState)(ConfirmPaymentModal);
