import React, { useState } from 'react';
import {
  IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonItem,
  IonRow,
  IonSpinner,
} from '@ionic/react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { logEvent } from '../services/firebaseApp';
import { RootState, Dispatch } from '../models/store';
import {
  findConnection,
  connectionHasReceivedRequest,
  connectionHasRejectedRequest,
  userHasCancelledRequest,
  userHasRejectedRequest,
  userHasRequestedConnection,
  usersAreConnected,
} from '../utils/connection';
import {
  checkmarkCircleOutline,
  personAddOutline,
  personRemoveOutline,
  removeCircleOutline,
} from 'ionicons/icons';
import {
  acceptConnection,
  addConnection,
  rejectConnection,
  removeConnection,
} from '../services/connectionService';
import Confirm from './Confirm';
import { ACCEPTED, NEW, PENDING, REJECTED } from '../constants/connections';
import { getUserByEmail } from '../services/userService';

const AddConnection: React.FC<AddConnectionComponent> = ({
  connection,
  type,
  showStatus,
  showDetails,
  color,
  size,
  fill,
  shape,
  short,
  className,
  user,
  addConnectionInState,
  removeConnectionInState,
  setStateUser,
  only,
  align = 'end',
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { t } = useTranslation('translation', {
    keyPrefix: 'connection',
  });

  const add = async (e: any) => {
    setIsLoading(true);
    if (e?.preventDefault) {
      e.preventDefault();
      e.stopPropagation();
      e.nativeEvent.stopImmediatePropagation();
    }
    await addConnection({ userId: connection.id });

    const { user: currentUser } = await getUserByEmail({
      email: user?.info?.email || '',
    });
    if (!currentUser) {
      return;
    }
    const { connections } = currentUser;
    await setStateUser({
      connections,
    });
    setIsLoading(false);

    logEvent('connection', {
      content_type: 'add',
      content_id: connection.id,
    });
  };

  const remove = async (approved: 'pending' | 'accepted', e: any) => {
    setIsLoading(true);
    if (e?.preventDefault) {
      e.preventDefault();
      e.stopPropagation();
      e.nativeEvent.stopImmediatePropagation();
    }
    await removeConnection({ userId: connection.id });

    const { user: currentUser } = await getUserByEmail({
      email: user?.info?.email || '',
    });
    if (!currentUser) {
      return;
    }
    const { connections } = currentUser;
    await setStateUser({
      connections,
    });
    setIsLoading(false);

    logEvent('connection', {
      content_type: 'remove',
      content_id: connection.id,
    });
  };

  const accept = async (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    await acceptConnection({
      userId: connection?.id,
    });

    const { user: currentUser } = await getUserByEmail({
      email: user?.info?.email || '',
    });
    if (!currentUser) {
      return;
    }
    const { connections } = currentUser;
    await setStateUser({
      connections,
    });
  };

  const reject = async (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    await rejectConnection({
      userId: connection?.id,
    });
  };

  return !connection ? null : (
    <IonGrid className="ion-no-padding">
      <IonRow className={`ion-align-items- ion-justify-content-${align}`}>
        {(!only || only?.includes(PENDING)) &&
        userHasRequestedConnection(user, connection) ? (
          <>
            {!showStatus ? null : (
              <IonItem
                className={`transparent ion-text-${align} ion-padding-end`}
              >
                <small>
                  {!showDetails
                    ? t(connection.approved)
                    : t('You have requested a connection with USER', {
                        user: (connection?.name || connection?.email) ?? 'Toto',
                      })}
                </small>
              </IonItem>
            )}
            <IonItem className="transparent">
              <Confirm
                confirm={remove}
                headerText={t('Cancel')}
                confirmText={t('Cancel')}
                size={size || 'default'}
              >
                <IonButton
                  className={`type-${type} ${className || ''}`}
                  color="light"
                  size={size || 'default'}
                  fill={fill || 'default'}
                  shape={shape || undefined}
                >
                  {type === 'icon' ? (
                    <IonIcon
                      icon={removeCircleOutline}
                      size={size || 'default'}
                    />
                  ) : (
                    t(`Cancel${short ? '-short' : ''}`)
                  )}
                </IonButton>
              </Confirm>
            </IonItem>
          </>
        ) : (!only || only?.includes(PENDING)) &&
          connectionHasReceivedRequest(user, connection) ? (
          <>
            {!showStatus ? null : (
              <IonItem
                className={`transparent ion-text-${align} ion-padding-end`}
              >
                <small>
                  {!showDetails
                    ? t(connection.approved)
                    : t('You have received a connection request from USER', {
                        user: (connection?.name || connection?.email) ?? 'Toto',
                      })}
                </small>
              </IonItem>
            )}
            <IonItem className="transparent">
              <IonButton
                className={`type-${type} ${className || ''}`}
                onClick={accept}
                color="primary"
                size={size || 'default'}
                fill={fill || 'solid'}
                shape={shape || undefined}
              >
                {type === 'icon' ? (
                  <IonIcon
                    icon={checkmarkCircleOutline}
                    size={size || 'default'}
                  />
                ) : (
                  t(`Accept${short ? '-short' : ''}`)
                )}
              </IonButton>
              <Confirm
                confirm={reject}
                headerText={t('Reject')}
                confirmText={t('Reject')}
                size={size || 'default'}
              >
                <IonButton
                  className={`type-${type} ${className || ''}`}
                  color="light"
                  size={size || 'default'}
                  fill={fill || 'default'}
                  shape={shape || undefined}
                >
                  {type === 'icon' ? (
                    <IonIcon
                      icon={removeCircleOutline}
                      size={size || 'default'}
                    />
                  ) : (
                    t(`Reject${short ? '-short' : ''}`)
                  )}
                </IonButton>
              </Confirm>
            </IonItem>
          </>
        ) : (!only || only?.includes('rejected')) &&
          userHasRejectedRequest(user, connection) ? (
          <IonCol>
            <small>
              {t('USER has rejected your connection request', {
                user: (connection?.name || connection?.email) ?? 'User',
              })}
            </small>
          </IonCol>
        ) : (!only || only?.includes('rejected')) &&
          connectionHasRejectedRequest(user, connection) ? (
          <IonCol>
            <small>
              {t('You have rejected connection request from USER', {
                user: (connection?.name || connection?.email) ?? 'User',
              })}
            </small>
          </IonCol>
        ) : (!only || only?.includes(NEW)) &&
          (!findConnection(user, connection) ||
            userHasCancelledRequest(user, connection)) ? (
          <IonCol>
            <IonButton
              className={`type-${type} ${className || ''}`}
              onClick={add}
              color="primary"
              size={size || 'default'}
              fill={fill || 'default'}
              shape={shape || undefined}
            >
              {isLoading ? (
                <IonSpinner className="small" />
              ) : type === 'icon' ? (
                <IonIcon icon={personAddOutline} size={size || 'default'} />
              ) : null}
              {t(`Add${short ? '-short' : ''}`)}
            </IonButton>
          </IonCol>
        ) : (!only || only?.includes(ACCEPTED)) &&
          usersAreConnected(user, connection) ? (
          <>
            {!showStatus ? null : (
              <IonCol>
                <small>{type === 'icon' ? null : t('You are connected')}</small>
              </IonCol>
            )}
            <IonCol>
              <Confirm
                confirm={remove}
                headerText={t('Remove')}
                confirmText={t('Remove')}
                size={size || 'default'}
              >
                <IonButton
                  className={`type-${type} ${className || ''}`}
                  color="light"
                  size={size || 'default'}
                  fill={fill || 'default'}
                  shape={shape || undefined}
                >
                  {type === 'icon' ? (
                    <IonIcon
                      icon={personRemoveOutline}
                      size={size || 'default'}
                    />
                  ) : (
                    t(`Remove${short ? '-short' : ''}`)
                  )}
                </IonButton>
              </Confirm>
            </IonCol>
          </>
        ) : null}
      </IonRow>
    </IonGrid>
  );
};

const mapState = (state: RootState) => ({
  users: state.user.users,
  user: state.user.user,
});

const mapDispatch = (dispatch: Dispatch) => ({
  setStateUser: dispatch.user.setStateUser,
  addConnectionInState: dispatch.user.addConnectionInState,
  removeConnectionInState: dispatch.user.removeConnectionInState,
});

export default connect(mapState, mapDispatch)(AddConnection);
