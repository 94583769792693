import * as Sentry from '@sentry/react';

import { removeTypenames } from '../utils/graphql';

import client from '../apollo/client';

import { getCategories } from '../graphql/category/getCategories';

export async function getAllCategories(variables: {
  tag?: string;
}): Promise<{ categories: Category[] }> {
  try {
    const {
      data: { getCategories: categories },
    } = await client.query({
      query: getCategories,
      variables,
      fetchPolicy: 'no-cache',
    });

    return { categories: removeTypenames(categories) };
  } catch (e: any) {
    Sentry.captureException(e);
    throw new Error();
  }
}
