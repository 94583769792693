import React, { useEffect, useState } from 'react';
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonTitle,
  IonContent,
  IonButton,
  IonInput,
  IonLabel,
  IonList,
  IonItem,
  IonSelect,
  IonSelectOption,
  IonCard,
  IonRow,
  IonCol,
  IonGrid,
} from '@ionic/react';
import { TextFieldTypes } from '@ionic/core';
import { connect } from 'react-redux';
import { path } from 'ramda';
import { FiArrowLeft } from 'react-icons/fi';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/react';
import { useHistory } from 'react-router';
import { LogoutButton, DeleteAccountButton } from 'authentication';

import Helper from '../../../components/Helper';
import { RootState, Dispatch } from '../../../models/store';
import { showError } from '../../../utils/form';
import { unflattenObject } from '../../../utils/unflattenObject';

import {
  editUser,
  editUserAddress,
  deleteCurrentUser,
} from '../../../services/userService';
import { setUserId, setUserProperty } from '../../../services/firebaseApp';
import { homeLink } from '../../../constants/routes';
import Separator from '../../../components/Separator';
import GoBack from '../../../components/GoBack';

interface FormOption {
  label: string;
  name: string;
}

interface FormInterface {
  label: string;
  name: string;
  placeholder?: string;
  type: TextFieldTypes;
  options?: FormOption[];
  translateOptions?: boolean;
  condition?: Function;
  readOnly?: boolean;
  onFieldBlur?: Function;
}

const Account: React.FC<AccountComponent> = ({
  user,
  countries,
  setStateUserInfo,
  setStateToast,
  deleteStateUser,
}) => {
  const history = useHistory();
  const { t } = useTranslation('translation', {
    keyPrefix: 'settings.account',
  });
  const {
    formState: { errors },
    control,
    setValue,
  } = useForm<any>({
    mode: 'onBlur',
  });
  const [formParts, setFormParts] = useState<any[] | null>(null);

  useEffect(() => {
    if (formParts) {
      return;
    }
    const form = [
      {
        label: 'Tagline',
        helper: 'tagline',
        fields: [
          {
            name: 'tagline',
            type: 'text',
            placeholder: t('Every little bit helps'),
          },
        ],
      },
      {
        label: 'Identity',
        helper: 'tax',
        fields: [
          { label: 'Full name', name: 'name', type: 'text' },
          { label: 'Firstname', name: 'firstname', type: 'text' },
          { label: 'Lastname', name: 'lastname', type: 'text' },
          {
            label: 'Gender',
            name: 'gender',
            type: 'select',
            options: [
              { label: 'Ms.', name: 'female' },
              { label: 'Mr.', name: 'male' },
            ],
            translateOptions: true,
          },
          { label: 'Date of birth', name: 'birthdate', type: 'date' },
          {
            label: 'Email',
            name: 'email',
            type: 'email',
            readOnly: true,
            onFieldBlur: (currentValue: string, history: any) => {
              if (!currentValue) {
                return;
              }
            },
          },
        ],
      },
      {
        label: 'Address',
        helper: 'tax',
        fields: [
          { label: 'Street', name: 'address.street', type: 'text' },
          { label: 'Number', name: 'address.number', type: 'text' },
          { label: 'Postcode', name: 'address.postcode', type: 'text' },
          { label: 'City', name: 'address.city', type: 'text' },
          {
            label: 'Country',
            name: 'address.country',
            type: 'select',
            options: (countries?.list || []).map(({ code, name }: any) => ({
              label: name,
              name: code,
            })),
            translateOptions: false,
          },
        ],
      },
    ];
    setFormParts(form);
  }, [countries]);

  const sendData = async (data: any) => {
    const formData: any = unflattenObject(data);
    try {
      if (Object.keys(formData)[0].includes('address')) {
        const { address } = await editUserAddress(formData.address);
        setStateUserInfo({ address });
      } else {
        const { user: editedUser } = await editUser(formData);
        setStateUserInfo(editedUser);
      }
    } catch (e: any) {
      Sentry.captureException(e);
      setStateToast({
        type: 'warning',
        isOpen: true,
        message: 'An error has occured',
      });
    }
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <GoBack />
          <IonTitle>{t('title')}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense" translucent>
          <IonToolbar>
            <IonTitle size="large">{t('title')}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonGrid className="ion-justify-content-between">
          <IonRow>
            <IonCol>
              <form>
                {(formParts || []).map(
                  (
                    {
                      label,
                      helper,
                      fields,
                    }: { label: string; helper: string; fields: any[] },
                    i: number
                  ) => (
                    <div key={i}>
                      <Separator title={t(label)} helper={helper} />
                      <IonCard>
                        <IonList lines="inset">
                          {fields.map(
                            (
                              {
                                label,
                                name,
                                placeholder,
                                type,
                                options,
                                translateOptions,
                                condition,
                                readOnly,
                                onFieldBlur,
                              }: FormInterface,
                              index
                            ) => {
                              const fieldValue = path(
                                name.split('.'),
                                user?.info
                              );
                              setValue(name, fieldValue);

                              return condition &&
                                !condition(user?.info?.email) ? null : (
                                <IonItem
                                  key={`${name}-${index}`}
                                  detail={false}
                                  className="form-item-input"
                                >
                                  {!label ? null : (
                                    <IonLabel position="fixed">
                                      {t(label)}
                                    </IonLabel>
                                  )}
                                  <Controller
                                    defaultValue={fieldValue}
                                    render={({
                                      field: { onChange, value },
                                    }) => {
                                      return ['select'].includes(type) ? (
                                        <IonSelect
                                          value={value}
                                          onIonChange={({
                                            detail: { value },
                                          }) =>
                                            fieldValue !== value &&
                                            sendData({
                                              [name]: value,
                                            })
                                          }
                                        >
                                          {(options || []).map(
                                            ({ label, name }, i) => (
                                              <IonSelectOption
                                                value={name}
                                                key={`${name}-${i}`}
                                              >
                                                {translateOptions
                                                  ? t(label)
                                                  : label}
                                              </IonSelectOption>
                                            )
                                          )}
                                        </IonSelect>
                                      ) : (
                                        <IonInput
                                          type={type}
                                          autocapitalize="words"
                                          value={value}
                                          placeholder={placeholder}
                                          onIonChange={onChange}
                                          readonly={readOnly}
                                          onIonBlur={() => {
                                            if (fieldValue !== value) {
                                              if (
                                                onFieldBlur &&
                                                !onFieldBlur(
                                                  fieldValue,
                                                  history
                                                )
                                              ) {
                                                return;
                                              }
                                              sendData({ [name]: value });
                                            }
                                          }}
                                          className="form-item-input"
                                        />
                                      );
                                    }}
                                    control={control}
                                    name={name}
                                  />
                                  {showError(errors, name)}
                                </IonItem>
                              );
                            }
                          )}
                        </IonList>
                      </IonCard>
                    </div>
                  )
                )}
              </form>
            </IonCol>
          </IonRow>
          <IonRow className="ion-align-items-end">
            <IonCol>
              <IonGrid className="ion-text-center ion-no-padding">
                <IonRow>
                  <IonCol>
                    <DeleteAccountButton
                      color="medium"
                      fill="clear"
                      beforeDeleteAccount={deleteCurrentUser}
                      onDeletedAccount={async () => {
                        await deleteStateUser();
                        setUserId('');
                        setUserProperty('email', '');
                        setStateToast({
                          isOpen: true,
                          type: 'success',
                          position: 'top',
                          message: `Account deleted successfully`,
                        });
                        history.push(homeLink);
                      }}
                    />
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

const mapState = (state: RootState) => ({
  user: state.user.user,
  countries: state.countries.countries,
});

const mapDispatch = (dispatch: Dispatch) => ({
  deleteStateUser: dispatch.user.deleteStateUserAsync,
  setStateUserInfo: dispatch.user.setStateUserInfo,
  setStateToast: dispatch.toast.setStateToast,
});

export default connect(mapState, mapDispatch)(Account);
