import React from 'react';
import { IonGrid, IonRow, IonCol } from '@ionic/react';
import Helper from './Helper';

const Separator: React.FC<SeparatorComponent> = ({
  title,
  count = null,
  helper = null,
  buttons,
  showButtons = true,
}) => {
  return !title ? null : (
    <IonGrid className="ion-no-padding-horizontal ion-margin-top">
      <IonRow className="ion-justify-content-between ion-align-items-center">
        <IonCol size="auto" className="ion-no-padding">
          <IonGrid className="ion-no-padding">
            <IonRow>
              <IonCol size="auto">
                <h4>{title}</h4>
              </IonCol>
              <IonCol size="auto">
                {count === null ? null : <small>({count})</small>}
              </IonCol>
              {!helper ? null : (
                <IonCol size="auto">
                  <Helper helper={helper} />
                </IonCol>
              )}
            </IonRow>
          </IonGrid>
        </IonCol>
        {!showButtons || !buttons ? null : (
          <IonCol size="auto" className="ion-no-padding">
            {buttons}
          </IonCol>
        )}
      </IonRow>
    </IonGrid>
  );
};

export default Separator;
