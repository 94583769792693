import { createModel } from '@rematch/core';

import { RootState, RootModel } from './store';

const initialState: CountryState = {
  countries: {
    status: 'initializing',
    list: null,
  },
};

export const countries = createModel<RootModel>()({
  state: initialState,
  reducers: {
    setStateCountries: (state, payload: Partial<CountryInterface>) => ({
      ...state,
      countries: { ...state.countries, ...payload },
    }),
  },
});
