export const findByCode = (code: string, list: any[]) => {
  let found: any;

  list?.find((item: Category) => {
    if (item.code === code) {
      found = item;
    }
    item?.children?.find((child: Category) => {
      if (child.code === code) {
        found = child;
      }
      return found;
    });

    return found;
  });

  return found;
}