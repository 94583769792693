import React from 'react';

import {
  IonList,
  IonLabel,
  IonItem,
  IonButtons,
  IonModal,
  IonContent,
  IonItemGroup,
} from '@ionic/react';
import { FiBell, FiInfo, FiMessageCircle } from 'react-icons/fi';
import { MdOutlineOpenInNew } from 'react-icons/md';
import { FaRegHandPeace } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

import { settingsNotificationsLink, aboutLink } from '../../constants/routes';
import { Dispatch, RootState } from '../../models/store';
import { connect } from 'react-redux';
import { Browser } from '@capacitor/browser';

const SettingsNavigationModal: React.FC<any> = ({
  isSettingsNavOpen,
  setIsSettingsNavOpen,
  deleteStateUser,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'settings.navigation',
  });

  const visitWebsite = () =>
    Browser.open({
      url: 'https://charitable.be/',
    });

  const visitTerms = () =>
    Browser.open({
      url: 'https://charitable.be/politique-de-confidentialite/',
    });

  return (
    <IonModal
      isOpen={isSettingsNavOpen}
      breakpoints={[0.6, 0.9]}
      initialBreakpoint={0.6}
      canDismiss={true}
      onWillDismiss={() => {
        setIsSettingsNavOpen(false);
      }}
      className="navigation-modal"
    >
      <IonContent fullscreen>
        <IonList lines="none" className="full-height">
          <IonItemGroup>
            <IonItem
              routerLink={settingsNotificationsLink}
              onClick={() => {
                setIsSettingsNavOpen(false);
              }}
              className="ion-align-items-center ion-margin-top"
            >
              <IonButtons slot="start" className="ion-padding-vertical">
                <FiBell size="1.2em" />
              </IonButtons>
              <IonLabel>{t('Notifications')}</IonLabel>
            </IonItem>
            <IonItem
              routerLink={aboutLink}
              onClick={() => {
                setIsSettingsNavOpen(false);
              }}
              className="ion-align-items-center"
            >
              <IonButtons slot="start" className="ion-padding-vertical">
                <FaRegHandPeace size="1.2em" />
              </IonButtons>
              <IonLabel>{t('About')}</IonLabel>
            </IonItem>
            <IonItem onClick={visitWebsite} className="ion-align-items-center">
              <IonButtons slot="start" className="ion-padding-vertical">
                <FiMessageCircle size="1.2em" />
              </IonButtons>
              <IonLabel>{t('Contact us')}</IonLabel>
              <IonButtons
                slot="end"
                className="ion-padding-vertical ion-color-medium"
              >
                <MdOutlineOpenInNew size="1.2em" color="medium" />
              </IonButtons>
            </IonItem>
            <IonItem onClick={visitTerms} className="ion-align-items-center">
              <IonButtons slot="start" className="ion-padding-vertical">
                <FiInfo size="1.2em" />
              </IonButtons>
              <IonLabel>{t('Terms and Conditions')}</IonLabel>
              <IonButtons
                slot="end"
                className="ion-padding-vertical ion-color-medium"
              >
                <MdOutlineOpenInNew size="1.2em" />
              </IonButtons>
            </IonItem>
          </IonItemGroup>
        </IonList>
      </IonContent>
    </IonModal>
  );
};

const mapState = (state: RootState) => ({
  isSettingsNavOpen: state.user.isSettingsNavOpen,
});

const mapDispatch = (dispatch: Dispatch) => ({
  deleteStateUser: dispatch.user.deleteStateUserAsync,
  setIsSettingsNavOpen: dispatch.user.setIsSettingsNavOpen,
});

export default connect(mapState, mapDispatch)(SettingsNavigationModal);
