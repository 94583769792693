import { Models } from '@rematch/core';
import { associations } from './associations';
import { categories } from './categories';
import { posts } from './posts';
import { search } from './search';
import { user } from './user';
import { connections } from './connections';
import { countries } from './countries';
import { notifications } from './notifications';
import { toast } from './toast';
import { historyStack } from './historyStack';

export interface RootModel extends Models<RootModel> {
  categories: typeof categories;
  associations: typeof associations;
  posts: typeof posts;
  search: typeof search;
  user: typeof user;
  countries: typeof countries;
  notifications: typeof notifications;
  toast: typeof toast;
  historyStack: typeof historyStack;
}

export const models: RootModel = {
  categories,
  associations,
  posts,
  search,
  user,
  connections,
  countries,
  notifications,
  toast,
  historyStack,
};
