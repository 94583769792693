import {
  ACCEPTED,
  CANCELLED,
  PENDING,
  REJECTED,
} from '../constants/connections';

export const findConnection = (user: UserInterface, connection: Connection) =>
  (user?.connections || []).find(({ id }) => id === connection.id);

export const usersAreConnected = (
  user: UserInterface,
  connection: Connection
) => findConnection(user, connection)?.approved === ACCEPTED;

export const userHasRequestedConnection = (
  user: UserInterface,
  connection: Connection
) =>
  findConnection(user, connection)?.requesterId === user?.info?.id &&
  findConnection(user, connection)?.approved === PENDING;

export const userHasRejectedRequest = (
  user: UserInterface,
  connection: Connection
) =>
  findConnection(user, connection)?.requesterId === user?.info?.id &&
  findConnection(user, connection)?.approved === REJECTED;

export const userHasCancelledRequest = (
  user: UserInterface,
  connection: Connection
) =>
  findConnection(user, connection)?.requesterId === user?.info?.id &&
  findConnection(user, connection)?.approved === CANCELLED;

export const connectionHasReceivedRequest = (
  user: UserInterface,
  connection: Connection
) =>
  findConnection(user, connection)?.requesterId !== user?.info?.id &&
  findConnection(user, connection)?.approved === PENDING;

export const connectionHasRejectedRequest = (
  user: UserInterface,
  connection: Connection
) =>
  findConnection(user, connection)?.requesterId !== user?.info?.id &&
  findConnection(user, connection)?.approved === REJECTED;
