import { gql } from '@apollo/client';

export default gql`
  fragment coordinatesFragment on GeolocationCoordinates {
    timestamp
    coords {
      accuracy
      altitude
      altitudeAccuracy
      heading
      latitude
      longitude
      speed
    }
  }
`;
