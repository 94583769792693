import React from 'react';
import { IonButton } from '@ionic/react';
import { connect } from 'react-redux';
import { FiGift } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';

import { Dispatch } from '../models/store';
import { donateLink } from '../constants/routes';

const Donate: React.FC<DonateComponent> = ({
  association,
  type,
  color,
  size,
  fill,
  shape,
  className,
  onClick,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'donate',
  });
  const location = useLocation();

  return !association ? null : type === 'icon' ? (
    <IonButton
      className={`type-icon ${className}`}
      color={color || 'primary'}
      size={size || 'default'}
      fill={fill || 'default'}
      shape={shape || undefined}
      routerLink={donateLink(association.code, location.search)}
      onClick={() => {
        onClick && onClick();
      }}
    >
      <FiGift className="stroke" />
    </IonButton>
  ) : (
    <IonButton
      className={`type-button ion-margin-vertical ${className}`}
      expand="block"
      shape={shape || undefined}
      color={color || 'primary'}
      size={size || 'default'}
      fill={fill || 'solid'}
      routerLink={donateLink(association.code, location.search)}
      onClick={() => {
        onClick && onClick();
      }}
    >
      <FiGift className="ion-margin-end" />
      {t('Make a donation')}
    </IonButton>
  );
};

const mapDispatch = (dispatch: Dispatch) => ({});

export default connect(null, mapDispatch)(Donate);
