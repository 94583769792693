import React, { useEffect, useState } from 'react';
import {
  IonAvatar,
  IonContent,
  IonCard,
  IonGrid,
  IonRow,
  IonCol,
  IonList,
  IonText,
  IonRouterLink,
  IonSpinner,
  IonButton,
  IonChip,
  IonImg,
} from '@ionic/react';
import { connect } from 'react-redux';
import { Browser } from '@capacitor/browser';
import { useTranslation } from 'react-i18next';
import { useSwiper } from 'swiper/react';

import { Dispatch, RootState } from '../../models/store';

import ReadMore from '../../components/ReadMore';
import ReadMoreHTML from '../../components/ReadMoreHTML';
import Donate from '../../components/Donate';
import Follow from '../../components/Follow';
import Share from '../../components/Share';
import BadgeList from '../../components/category/lists/BadgeList';
import CountryBadgeList from '../../components/country/lists/BadgeList';

import {
  associationsLink,
  associationLink,
  dynamicLink,
  associationDonationsLink,
  associationFormLink,
} from '../../constants/routes';
import Donation from '../../components/Donation';
import GridList from '../../components/association/lists/GridList';
import { formatDate, formatDistance } from '../../utils/date';
import {
  publishAssociation,
  starAssociation,
} from '../../services/associationService';
import { FaEdit, FaEye, FaEyeSlash, FaRegStar, FaStar } from 'react-icons/fa';
import { FiActivity, FiGift, FiHeart } from 'react-icons/fi';

function isJson(str: string) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

const AssociationContent: React.FC<AssociationContentComponent> = ({
  user,
  association,
  associations,
  list,
  setStateAssociationForm,
  className,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation('translation', {
    keyPrefix: 'association',
  });
  const currentLanguage = language.split('-')[0];

  const swiper = useSwiper();
  const [similar, setSimilar] = useState<Association[]>([]);

  if (!association) {
    return null;
  }

  useEffect(() => {
    setSimilar(
      (list || [])
        .filter(({ id }) => id !== association.id)
        .filter(({ categories }) =>
          (categories || [])
            .map((category) => category?.id)
            .includes(association?.categories[0]?.id)
        )
        .sort(() => Math.random() - 0.5)
        .slice(0, 6)
    );
    // eslint-disable-next-line
  }, [associations.list]);

  const visitWebsite = () =>
    Browser.open({
      url: `${association?.website}?utm_source=charitable.be&utm_medium=app`,
    });

  const star = async (id: string) => {
    const { isStared } = await starAssociation({ id });
  };

  const publish = async (id: string) => {
    const { published } = await publishAssociation({ id });
  };

  const donations = (user?.payments || []).filter(
    ({ associationId }) => associationId === association.id
  );

  const date = formatDistance(association.dateAdded, currentLanguage);
  const userName = association?.user?.name;

  return (
    <IonContent className={`${className} ion-text-left`}>
      <IonGrid className="ion-no-padding ion-padding-bottom">
        <IonRow>
          <IonCol sizeXs="12" sizeSm="12" sizeMd="6">
            <IonGrid className="ion-no-padding">
              <IonRow>
                <IonCol>
                  {!association?.banner?.lg &&
                  !association?.illustration?.lg ? null : (
                    <IonCard className="ion-margin main-image">
                      <IonImg
                        src={
                          association?.banner?.lg ||
                          association?.illustration?.lg
                        }
                        alt=""
                      />
                    </IonCard>
                  )}
                </IonCol>
              </IonRow>
              {!user?.info?.admin ? null : (
                <IonRow className="ion-justify-content-end ion-padding">
                  <IonCol size="auto">
                    <IonButton
                      shape="round"
                      color="light"
                      onClick={() => publish(association.id)}
                      className="type-icon ion-margin-end"
                    >
                      {association?.published ? <FaEye /> : <FaEyeSlash />}
                    </IonButton>
                    <IonButton
                      shape="round"
                      className="type-icon"
                      color="light"
                    >
                      <IonRouterLink
                        routerLink={associationFormLink(association?.code)}
                        onClick={() => setStateAssociationForm(association)}
                      >
                        <FaEdit />
                      </IonRouterLink>
                    </IonButton>
                    <IonButton
                      shape="round"
                      color="light"
                      onClick={() => star(association.id)}
                      className="type-icon ion-margin-start"
                    >
                      {association?.isStared ? <FaStar /> : <FaRegStar />}
                    </IonButton>
                  </IonCol>
                </IonRow>
              )}
            </IonGrid>
          </IonCol>
          <IonCol
            sizeXs="12"
            sizeSm="12"
            sizeMd="6"
            className="ion-no-padding full-height-container"
          >
            <IonGrid className="full-height ion-padding">
              <IonRow>
                <IonCol>
                  <IonAvatar className="avatar-lg">
                    <img src={association?.logo?.sm} alt="" />
                  </IonAvatar>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <h1>{association?.name}</h1>
                  <h6 className="ion-margin-bottom b">
                    {association?.tagline}
                  </h6>
                  <small>
                    {t('Founded in DATE', {
                      date: formatDate(association?.creationDate),
                    })}
                  </small>
                  <p className="ion-no-margin">
                    <small>
                      <IonText
                        color="primary"
                        className="pointer ion-no-margin"
                        onClick={visitWebsite}
                      >
                        {association?.website?.replace('https://', '')}
                      </IonText>
                    </small>
                  </p>
                </IonCol>
              </IonRow>
              <IonRow className="ion-margin-vertical">
                <IonCol>
                  <IonChip disabled>
                    <FiGift className="ion-margin-end" />
                    {association?.donationsCount ?? 0}
                  </IonChip>
                  <IonChip disabled>
                    <FiHeart className="ion-margin-end" />
                    {association?.likesCount ?? 0}
                  </IonChip>
                  <IonChip disabled>
                    <FiActivity className="ion-margin-end" />
                    {association?.postsCount ?? 0}
                  </IonChip>
                </IonCol>
              </IonRow>
              {/*  <IonRow className="stick-bottom">
                <IonCol>
                  <small>
                    <IonText color="medium">
                      <Trans i18nKey="addedByUser">
                        Added {{ date }} by{' '}
                        <IonRouterLink
                          routerLink={userLink(association?.user?.id)}
                          className="transparent button-link"
                        >
                          {{ userName }}
                        </IonRouterLink>
                      </Trans>
                    </IonText>
                  </small>
                </IonCol>
              </IonRow> */}
              <IonRow className="ion-margin-top">
                <IonCol size="auto" className="ion-margin-end">
                  <Donate
                    type="button"
                    shape="round"
                    association={association}
                    className="ion-no-margin-vertical"
                  />
                </IonCol>
                <IonCol size="auto" className="ion-margin-end">
                  <Follow
                    type="icon"
                    fill="solid"
                    shape="round"
                    color="light"
                    association={association}
                    className="ion-no-margin-vertical"
                  />
                </IonCol>
                <IonCol size="auto" className="ion-margin-end">
                  <Share
                    type="icon"
                    fill="solid"
                    shape="round"
                    title={t('ASSOCIATION is on Charitable', {
                      association: association.name,
                    })}
                    url={`${dynamicLink}${associationsLink}${associationLink(
                      association.code
                    )}`}
                    className="ion-no-margin-bottom"
                  />
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonCol>
        </IonRow>
      </IonGrid>
      <hr />
      <IonGrid className="ion-no-padding ion-padding-vertical">
        <IonRow>
          <IonCol sizeXs="12" sizeSm="12" sizeMd="6" className="ion-no-padding">
            <IonGrid className="ion-padding-horizontal ion-text-left">
              <IonRow>
                <IonCol>
                  <h4 className="ion-margin-bottom">
                    <IonText color="dark">{t('About')}</IonText>
                  </h4>
                  {isJson(association?.description) ? (
                    <ReadMoreHTML type="association" item={association?.code}>
                      {association?.description}
                    </ReadMoreHTML>
                  ) : (
                    <ReadMore
                      type="association"
                      item={association?.code}
                      maxLength={350}
                    >
                      {association?.description}
                    </ReadMore>
                  )}
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonCol>
          <IonCol
            sizeXs="12"
            sizeSm="12"
            sizeMd="6"
            className="ion-no-padding ion-text-left"
          >
            <IonGrid className="ion-padding-horizontal">
              <IonRow className="ion-justify-content-between ion-margin-bottom">
                <IonCol size="auto" className="ion-no-padding">
                  <h4>
                    <IonText color="dark">
                      {t('donations.Your contribution')}
                    </IonText>
                  </h4>
                </IonCol>
                <IonCol size="auto" className="ion-no-padding">
                  <small>
                    <IonRouterLink
                      routerLink={associationDonationsLink(association.code)}
                      className="transparent button-link"
                    >
                      {t('See all')}
                    </IonRouterLink>
                  </small>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonCard>
                    {!donations ? (
                      <IonGrid>
                        <IonRow className="ion-justify-content-center">
                          <IonCol size="auto">
                            <IonSpinner color="primary" />
                          </IonCol>
                        </IonRow>
                      </IonGrid>
                    ) : !donations?.length ? (
                      <p className="ion-text-center ion-padding">
                        {t("donations.You haven't made donation yet")}
                      </p>
                    ) : (
                      <IonList lines="inset">
                        {(donations || [])
                          .slice(0, 3)
                          .map((payment: Payment) => (
                            <Donation
                              payment={payment}
                              key={`payment-${payment.id}}`}
                            />
                          ))}
                      </IonList>
                    )}
                  </IonCard>
                </IonCol>
              </IonRow>
              <IonRow className="ion-margin-top">
                <IonCol>
                  <BadgeList categories={association.categories} />
                </IonCol>
              </IonRow>
              <IonRow className="ion-margin-top">
                <IonCol>
                  <CountryBadgeList countries={association.countries} />
                </IonCol>
              </IonRow>
              <IonRow className="ion-margin-top">
                <IonCol>
                  <Donate
                    type="button"
                    fill="clear"
                    association={association}
                    className="ion-no-margin-vertical"
                  />
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <Share
                    type="button"
                    fill="clear"
                    title={t('ASSOCIATION is on Charitable', {
                      association: association.name,
                    })}
                    buttonText={t('Share this charity')}
                    url={`${dynamicLink}${associationsLink}${associationLink(
                      association.code
                    )}`}
                    className="ion-no-margin-vertical"
                  />
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <Follow
                    type="button"
                    fill="clear"
                    expand="block"
                    association={association}
                    className="ion-no-margin-vertical"
                  />
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonCol>
        </IonRow>
      </IonGrid>
      {!similar?.length ? null : (
        <IonGrid className="ion-margin-bottom-3x-sm-up">
          <IonRow>
            <IonCol className="ion-padding">
              <hr />
              <GridList
                list={similar}
                title={t('In the same categories')}
                onClick={(associationId: string) => {
                  swiper.slideTo(
                    (list || []).findIndex(({ id }) => id === associationId)
                  );
                }}
              />
            </IonCol>
          </IonRow>
        </IonGrid>
      )}
    </IonContent>
  );
};

const mapState = (state: RootState) => ({
  associations: state.associations.associations,
  user: state.user.user,
});

const mapDispatch = (dispatch: Dispatch) => ({
  setStateAssociationForm: dispatch.associations.setStateAssociationForm,
});

export default connect(mapState, mapDispatch)(AssociationContent);
