import React from 'react';
import { IonButton, IonIcon } from '@ionic/react';
import { GoogleAuthProvider } from 'firebase/auth';
import logoGoogle from '../assets/google.svg';

import { SignInWithProviderProps, useAuth } from '../types';

type Props = SignInWithProviderProps & {
  useAuth: typeof useAuth;
};

const googleProvider = new GoogleAuthProvider();

const SignInWithProvider: React.FC<Props> = ({
  useAuth,
  buttonText,
  isLogin,
  beforeLogin,
  onLoggedIn,
  onError,
  expand,
  color,
  size,
  fill,
  shape,
  className,
}) => {
  const { user, logInWithProvider } = useAuth()!;

  const signInWithProvider = async (): Promise<void> => {
    await logInWithProvider({
      provider: googleProvider,
      isLogin,
      beforeLogin,
      onLoggedIn,
      onError,
    });
  };

  return user ? null : (
    <IonButton
      expand={expand || undefined}
      color={color || 'default'}
      size={size || 'default'}
      fill={fill || 'default'}
      shape={shape || 'round'}
      className={`sign-in-with-google-btn ${className}`}
      onClick={signInWithProvider}
      data-testid="button"
    >
      <IonIcon src={logoGoogle} slot="start" />
      {buttonText ? (
        <span className="ion-margin-start-half">{buttonText}</span>
      ) : (
        ''
      )}
    </IonButton>
  );
};

export default SignInWithProvider;
