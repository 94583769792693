import React, { useEffect, useState } from 'react';
import {
  IonList,
  IonItem,
  IonLabel,
  IonButtons,
  IonNote,
  IonAvatar,
  IonImg,
  IonCard,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonGrid,
  IonRow,
  IonCol,
} from '@ionic/react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Dispatch } from '../../../models/store';
import { formatDistance, sortByCreationDate } from '../../../utils/date';
import { generateHSL } from '../../../utils/generateRandomColor';
import { postLink, userLink } from '../../../constants/routes';
import { isThisMonth, isToday } from 'date-fns';
import Separator from '../../../components/Separator';

interface FormatedList {
  today: NotificationLocal[];
  thisMonth: NotificationLocal[];
  earlier: NotificationLocal[];
}

const NotificationItem = ({
  notification,
}: {
  notification: NotificationLocal;
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation('translation', {
    keyPrefix: 'notifications',
  });
  const currentLanguage = language.split('-')[0];

  const link = !notification?.targetId
    ? undefined
    : notification?.type === 'connection'
    ? userLink(notification?.targetId)
    : postLink(notification?.targetId);

  return (
    <IonItem
      routerLink={link}
      color={notification.read ? 'light' : 'default'}
      detail={false}
    >
      <IonAvatar
        slot="start"
        className="ion-margin ion-no-margin-start avatar-md"
        style={{
          background: generateHSL(notification?.body ?? 'Toto'),
        }}
      >
        {notification?.illustration ? (
          <IonImg src={notification.illustration.sm} alt={notification?.type} />
        ) : (
          <b>{(notification?.body ?? 'Toto').slice(0, 1).toUpperCase()}</b>
        )}
      </IonAvatar>
      <IonLabel>
        <h5 className="ion-no-margin">{notification.body}</h5>
        <IonNote>
          {t('Added DATE', {
            date: formatDistance(notification.creationDate, currentLanguage),
          })}
        </IonNote>
      </IonLabel>
      <IonButtons slot="end" className="ion-no-margin"></IonButtons>
    </IonItem>
  );
};

const NotificationsList: React.FC<NotificationsListComponent> = ({
  list,
  title,
  helper,
  seeAllLink,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'notifications',
  });
  const [formatedList, setFormatedList] = useState<FormatedList | {}>({});
  const [data, setData] = useState<NotificationLocal[]>([]);
  const [isInfiniteDisabled, setInfiniteDisabled] = useState<boolean>(false);

  useEffect(() => {
    if (!list?.length) {
      return;
    }
    pushData();
    // eslint-disable-next-line
  }, [list]);

  const pushData = (ev?: any) => {
    const max = data.length + 10;
    const min = max - 10;
    const newData = [];
    for (let i = min; i < max; i++) {
      if (list?.[i]) {
        newData.push(list[i]);
      }
    }

    setData([...data, ...newData]);
    ev?.target?.complete();
    if (data.length === list?.length) {
      setInfiniteDisabled(true);
    }
  };

  useEffect(() => {
    const formatedList = (data || []).reduce(
      (acc: FormatedList, curr) =>
        !isThisMonth(new Date(JSON.parse(curr.creationDate)))
          ? { ...acc, earlier: [curr, ...acc.earlier] }
          : !isToday(new Date(JSON.parse(curr.creationDate))) &&
            isThisMonth(new Date(JSON.parse(curr.creationDate)))
          ? { ...acc, thisMonth: [curr, ...acc.thisMonth] }
          : { ...acc, today: [curr, ...acc.today] },
      { today: [], thisMonth: [], earlier: [] }
    );
    setFormatedList(formatedList);
  }, [data]);

  return !(data || []).length ? null : (
    <>
      {Object.entries(formatedList).map(
        (
          [key, notifications]: [
            key: string,
            notifications: NotificationLocal[]
          ],
          index: number
        ) => {
          return !notifications.length ? null : (
            <div key={`notification-group-${key}-${index}`}>
              <Separator title={t(key)} />
              <IonCard className="ion-margin-bottom">
                <IonList lines="inset">
                  {notifications
                    .sort(sortByCreationDate)
                    .map((notification) => (
                      <NotificationItem
                        notification={notification}
                        key={`notification-${notification.id}}`}
                      />
                    ))}
                </IonList>
              </IonCard>
            </div>
          );
        }
      )}
      <IonInfiniteScroll onIonInfinite={pushData} disabled={isInfiniteDisabled}>
        <IonInfiniteScrollContent></IonInfiniteScrollContent>
      </IonInfiniteScroll>
    </>
  );
};

const mapDispatch = (dispatch: Dispatch) => ({
  setStateAssociationModalList:
    dispatch.associations.setStateAssociationModalList,
});

export default connect(null, mapDispatch)(NotificationsList);
