import { gql } from '@apollo/client';
import categoryFragment from '../category/categoryFragment';

export const editUserFavoriteCategories: any = gql`
  mutation editUserFavoriteCategories($categoryIds: [ID]!) {
    editUserFavoriteCategories(categoryIds: $categoryIds) {
      ...categoryFragment
    }
  }

  ${categoryFragment}
`;
