import React from 'react';
import { IonChip, IonText } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { categoryLink } from '../../../constants/routes';

const BadgeList: React.FC<CategoryBadgeListComponent> = ({
  categories,
  className,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation('translation', {
    keyPrefix: 'categories',
  });
  const currentLanguage = language.split('-')[0];
  const history = useHistory();

  return !categories.length ? null : (
    <>
      <h4 className="ion-margin-bottom">
        <IonText color="dark">{t('Categories')}</IonText>
      </h4>
      <div className={`${className}`}>
        {(categories || []).map((category: Category) => {
          return !category ? null : (
            <IonChip
              key={`category-tag-${category?.id}`}
              onClick={() => {
                history.push(categoryLink(category?.code));
              }}
            >
              {category?.[`name_${currentLanguage}`] ?? category?.name}
            </IonChip>
          );
        })}
      </div>
    </>
  );
};

export default BadgeList;
